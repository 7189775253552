import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface props {
  doors: number | null;
}

const TotalDoorsCard = ({ doors }: props) => {
  const { t } = useTranslation();
  return (
    <Box minWidth={130} id="total-doors-card">
      <Typography align="center" component="h4" variant="h4" id="total-doors-typography">
        <strong>{t('doors.total_active_doors')}</strong>
      </Typography>
      <Typography align="center" component="h1" variant="h1" fontSize={62} id="total-doors-amount-typography">
        {doors}
      </Typography>
    </Box>
  );
};

export default TotalDoorsCard;
