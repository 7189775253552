import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// MUI
import { Box, FormControlLabel, Grid, Stack } from '@mui/material';
// interfaces
import user from '../../../../interfaces/user.interface';
// Imports
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import MaskedInput from '../../../../components/MaskedInput/MaskedInput';
import FormField from '../../../../components/FormField/FormField';
import Select from '../../../../components/Select/Select';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { useSelector } from '../../../../store';
import { postPayment } from '../../../../api/api';
import useAlert from '../../../../utils/hooks/useAlert';

interface props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const AddPaymentModal = ({ open, onClose, onSuccess }: props) => {
  const { t } = useTranslation();
  const user: user = useSelector((store) => store.user.user);
  const { errorAlert, successAlert } = useAlert();

  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [card, setCard] = useState({
    dealer: '',
    name: '',
    number: '',
    exp_month: undefined,
    exp_year: undefined,
    cvc: '',
    is_default: false
  });


  useEffect(() => {
    if (
      !isNaN(+card.number.replaceAll(' ', '')) &&
      card.name.length > 5 &&
      card.exp_month &&
      card.exp_year &&
      card.cvc.length === 3
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [card]);

  const months = [
    { title: t('general.month.january'), number: 0 },
    { title: t('general.month.february'), number: 1 },
    { title: t('general.month.march'), number: 2 },
    { title: t('general.month.april'), number: 3 },
    { title: t('general.month.may'), number: 4 },
    { title: t('general.month.june'), number: 5 },
    { title: t('general.month.july'), number: 6 },
    { title: t('general.month.august'), number: 7 },
    { title: t('general.month.september'), number: 8 },
    { title: t('general.month.october'), number: 9 },
    { title: t('general.month.november'), number: 10 },
    { title: t('general.month.december'), number: 11 }
  ];

  const years = [
    { title: 2022, number: 2022 },
    { title: 2023, number: 2023 },
    { title: 2024, number: 2024 },
    { title: 2025, number: 2025 },
    { title: 2026, number: 2026 },
    { title: 2027, number: 2027 },
    { title: 2028, number: 2028 },
    { title: 2029, number: 2029 }
  ];

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const body = { ...card };
      body.dealer = user.selectedDealer?.dealer || user.currentProfile;
      const { status, message } = await postPayment(body);
      if (status === 200) {
        successAlert(message)
        onClose();
        onSuccess();
      }
    } catch (error: any) {
      errorAlert(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="md"
      title={t('general.btn.add_card')}
    >
      <Box paddingTop={1}>
        <Stack direction={'column'}>
          <FormField
            value={card.name}
            required
            info={t('organization.name_on_card')}
            onChange={(e: any) => setCard({ ...card, name: e.target.value })}
            label={t('organization.name_on_card')}
          />
          <MaskedInput
            mask="9999 9999 9999 9999"
            required
            value={card.number}
            onChange={(e: any) => setCard({ ...card, number: e.target.value })}
            label={t('organization.card_number')}
          />
        </Stack>

        <Grid container direction="row" gap={1}>
          <Grid item xs>
            <Select
              label={t('organization.expiration_date')}
              required
              value={card.exp_month || ''}
              onChange={(event: any) =>
                setCard({ ...card, exp_month: event.target.value })
              }
              options={months}
              optionName="title"
              optionValue="number"
              marginBottom="2em"
            ></Select>
          </Grid>
          <Grid item xs>
            <Select
              label=" "
              value={card.exp_year || ''}
              onChange={(event: any) =>
                setCard({ ...card, exp_year: event.target.value })
              }
              options={years}
              optionName="title"
              optionValue="number"
              marginBottom="2em"
            ></Select>
          </Grid>
          <Grid item xs>
            <MaskedInput
              label="CVC"
              required
              mask="999"
              info="CVC"
              value={card.cvc}
              onChange={(e: any) => setCard({ ...card, cvc: e.target.value })}
            />
          </Grid>
        </Grid>

        {/* isDefault */}
        <FormControlLabel
          label={t('organization.is_default')}
          control={
            <Checkbox
              checked={card.is_default}
              onChange={(e: any) => {
                setCard({ ...card, is_default: e.target.checked });
              }}
            />
          }
        ></FormControlLabel>

        <Stack marginTop={5} direction={'row'} justifyContent={'right'} gap={2}>
          <Button color="gray" onClick={onClose}>
            {t('general.btn.cancel')}
          </Button>
          <Button
            disabled={disableBtn || loading}
            color="primary"
            onClick={handleConfirm}
          >
            {t('general.btn.save')}
          </Button>
        </Stack>
      </Box>

    </Modal>
  );
};

export default AddPaymentModal;
