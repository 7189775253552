import React, { useMemo } from 'react';
import {
  Box,
  ListSubheader,
  Select as MaterialUISelect,
  MenuItem,
  PaperProps,
  Typography
} from '@mui/material';
import InfoIconLabel from '../InfoIconLabel/InfoIconLabel';
// Components
import RequiredFieldIndicator from '../RequiredFieldIndicator/RequiredFieldIndicator';
import { useTheme } from '@mui/system';
import { idFormat } from '../../utils/idFormat';

interface SelectProps {
  name?: string;
  placeholder?: string;
  value: string | number | boolean | any;
  onChange: any;
  options: Array<any>;
  optionValue?: string | any;
  optionName?: string;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  info?: string;
  marginBottom?: string;
  height?: string;
  padding?: string;
  paddingRight?: string;
  paddingLeft?: string;
  sx?: any;
  keepLabelEnabled?: boolean;
  lckdwnActivated?: boolean;
  id?: string;
  setShowCustomModal?: (open: boolean) => void;
  customValue?: string | null;
  PaperProps?: Partial<PaperProps>;
  groupBy?: string;
  renderGroup?: (group: string) => React.ReactNode;
  hideFirstGroupHeader?: boolean;
}

const Select: React.FC<SelectProps> = ({
  name,
  placeholder,
  value = '',
  onChange,
  options,
  optionValue,
  optionName,
  disabled,
  label,
  required,
  info,
  marginBottom,
  height,
  padding,
  paddingRight,
  paddingLeft,
  sx,
  keepLabelEnabled,
  lckdwnActivated,
  id,
  customValue,
  setShowCustomModal,
  PaperProps,
  groupBy,
  renderGroup,
  hideFirstGroupHeader
}) => {
  const theme = useTheme();

  /**
   * Creates a new options object based on the provided options array and groupBy property.
   * If groupBy is not specified, the original options array is returned.
   * If groupBy is specified, the options array is grouped by the specified property.
   */
  const newOptions = useMemo(() => {
    if (!groupBy) return { '': options };

    return options.reduce((acc: { [key: string]: any[] }, option: any) => {
      const groupKey = option[groupBy] || 'Other';
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(option);
      return acc;
    }, {});
  }, [options, groupBy]);

  const renderMenuItem = (option: any, index: number) => (
    <MenuItem
      id={`select-option-${index}`}
      key={`option-${index}`}
      sx={{
        borderRadius: '0.5em',
        margin: '0.25em',
        minHeight: '2em !important'
      }}
      value={optionValue ? option[optionValue] : option}
    >
      {optionName ? option[optionName] : option}
    </MenuItem>
  );

  const renderGroupHeader = (group: string, index: number) => {
    if (hideFirstGroupHeader && index === 0) return null;
    return renderGroup ? (
      renderGroup(group)
    ) : (
      <ListSubheader key={`group-${group}`}>{group}</ListSubheader>
    );
  };

  /**
   * Returns an array of JSX elements that represent the options in the select dropdown.
   * If a group is present in the options, a group header is rendered before the group's options.
   */
  const renderOptions = () =>
    Object.entries(newOptions).map(([group, options], groupIndex) => [
      group ? renderGroupHeader(group, groupIndex) : null,
      options.map(renderMenuItem)
    ]);

  const selectStyles = {
    borderRadius: '0.5em',
    width: '100%',
    maxHeight: '2.5em',
    overflow: 'hidden',
    marginBottom: marginBottom ? marginBottom : 2,
    backgroundColor: disabled
      ? theme.palette.input.disabled
      : theme.palette.input.background,
    '& .MuiSvgIcon-root': {
      color: theme.palette.button.icon
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px',
      backgroundColor: disabled ? '#00000005' : '#00000008'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px',
      backgroundColor: disabled ? '#00000005' : '#00000008'
    },
    '.css-5c13vx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
      {
        padding: { padding },
        paddingLeft: { paddingLeft },
        paddingRight: { paddingRight }
      },
    ...sx
  };

  return (
    <Box width="100%" sx={{ flex: 1, borderRadius: 2 }}>
      {label && (
        <Box minHeight={20} marginTop={0.5}>
          <Typography
            fontWeight="bold"
            sx={{
              marginLeft: '0.25em',
              color: disabled && !keepLabelEnabled ? 'lightgray' : null,
              fontSize: '0.75em'
            }}
          >
            {label}
            {required && <RequiredFieldIndicator disabled={disabled} />}
            {info && <InfoIconLabel info={info} />}
          </Typography>
        </Box>
      )}
      <MaterialUISelect
        name={name}
        variant="outlined"
        disabled={disabled}
        value={value}
        id={id ? id : `select-${label ? idFormat(label) : 'default'}`}
        sx={selectStyles}
        className={lckdwnActivated ? 'blinkcard-yellow' : ''}
        onChange={onChange}
        displayEmpty
        MenuProps={{
          style: { borderRadius: '0.5em' },
          PaperProps: PaperProps ? PaperProps : { sx: { maxHeight: 220 } }
        }}
      >
        {placeholder && (
          <MenuItem
            sx={{ borderRadius: '0.5em', margin: '0.25em' }}
            disabled
            autoFocus
            value=""
          >
            <span style={{ color: 'gray' }}>{placeholder}</span>
          </MenuItem>
        )}
        {renderOptions()}
        {setShowCustomModal && customValue && (
          <MenuItem
            onClick={() => setShowCustomModal && setShowCustomModal(true)}
            value={customValue}
          >
            {customValue === 'Custom' ? 'Custom' : `Custom - ${customValue}`}
          </MenuItem>
        )}
      </MaterialUISelect>
    </Box>
  );
};

export default Select;
