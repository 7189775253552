const dealers = {
  header: 'Lista de distribuidores',
  dealers: 'Distribuidores',
  employee: 'Empleado',
  edit_dealers: 'Editar distribuidor',
  dealers_details: 'Detalles del distribuidor',
  add_dealers: 'Agregar distribuidores',
  add_emp: 'Agregar empleado',
  edit_emp: 'Editar empleado',
  search_dealers: 'Buscar distribuidores',
  search_employee: 'Search empleados',
  search_controllers: 'Controladores de búsqueda',
  search_organizations: 'Buscar Cuentas',
  delete_dealers_message: 'Estas seguro que quieres borrarlo',
  selected_dealerss: 'los distribuidores seleccionados',
  confirm_modal_cancel: 'No, cancelar',
  confirm_modal_confirm: 'Sí, eliminar',
  create_dealer: 'Crear nuevo distribuidor',
  update_dealer: 'Actualizar distribuidor',
  dealer_info: 'Información del distribuidor',
  your_dealer_info: 'Información de su distribuidor',
  review_dealer_account: 'Revisar cuenta de distribuidor',
  all: 'Todo',
  active: 'Activo',
  inactive: 'Inactivo',
  submit: 'Entregar',
  cancel: 'Cancelar',
  update: 'Actualizar',
  independet: 'Distribuidor independiente',
  national: 'Distribuidor de cuentas nacionales',
  na: 'Distribuidor de NA asociado',
  back_to_list: 'Volver a la lista',
  edit: 'Editar',
  profile: 'Perfil',
  organizations: 'Cuentas',
  controllers: 'Controladores',
  doors: 'Puertas',
  users: 'Usuarios',
  invoices: 'Facturas',
  myplan: 'Mi Plan',
  dealer_updated: 'Distribuidor actualizado',
  dealer_role: 'Rol del distribuidor',
  emp_role: 'Rol del empleado',
  set_dealer: 'Establecer distribuidor',
  change_dealer_msg: '¿Quieres cambiar de dealer?',
  select_dealer_msg: 'Por favor vaya a Distribuidores y seleccione uno',
  confirm_action: '¿Está seguro de que desea cambiar el estado a',
  manage_plan: 'Administrar mi plan',
  add_employee: 'Agregar empleado',
  your_employees_info: 'Información de su empleado',
  calculate_for_account: 'Calcula para tus cuentas',
  how_many_doors: 'cuantas puertas',
  for_doors: 'Para una o más puertas',
  doors_to_add: '¿Cuántas licencias de puertas le gustaría agregar?',
  door_licenses_available: 'Licencia de puerta disponible',
  billing_cycle: 'Ciclo de facturación',
  card_enrollment: 'Inscripción de credencial',
  card_information: 'Información de la credencial',
  access_all_accounts: 'Acceso a todas las cuentas',
  remove_access_all: 'Eliminar acceso a todas las cuentas',
  how_you_discover: '¿Cómo descubriste CDVI Cloud?',
  no_alerts_reported: 'No se reportaron alertas',
  remove_access_msg:
    '¿Estás seguro de que deseas eliminar el acceso a {{name}}?',
  grant_all_access: '¿Quieres otorgar acceso a todas las cuentas?',
  remove_all_access: '¿Quieres eliminar el acceso a todas las cuentas?',
  set_permissions: '¿Quieres configurar los permisos?',
  dealer_registration: 'Registro de distribuidor',
  assign_site: 'Asignar Sitio',
  validate: {
    type_email: 'Por favor, escriba su correo electrónico',
    approved_email: 'Dirección de correo electrónico aprobada',
    verification_code: 'Código de verificación',
    validation_label:
      'Se ha enviado una contraseña única a su correo electrónico, revise su bandeja de entrada',
    done: 'Hecho',
    success:
      'Haga clic a continuación para iniciar sesión en su cuenta CDVI Cloud',
    continue: 'Continuar',
    finish: 'Finalizar',
    password_must_contain: 'Su contraseña debe contener',
    number_of_charachters: '8 a 24 caracteres',
    lowercase: 'Una letra minúscula',
    uppercase: 'Una letra mayúscula',
    number: 'Un número',
    special_character: 'Un personaje especial',
    password_match: 'Contraseñas coinciden'
  },
  table: {
    company: 'COMPAÑÍA',
    full_name: 'NOMBRE COMPLETO',
    email: 'CORREO ELECTRÓNICO',
    country: 'PAÍS',
    last_login: 'ÚLTIMO ACCESO',
    active: 'ACTIVO'
  },
  form: {
    type: 'Tipo de distribuidor',
    company: 'Compañía',
    full_name: 'Nombre completo',
    first_name: 'Nombre de pila',
    last_name: 'Apellido',
    email: 'Correo electrónico',
    confirm_email: 'Confirmar correo',
    country: 'País',
    address: 'DIRECCIÓN',
    city: 'Ciudad',
    state: 'Estado',
    province_territory: 'Provincia o Territorio',
    zip_code: 'Código postal',
    postal_code: 'Código postal',
    phone: 'Teléfono',
    mobile: 'Móvil',
    language: 'Idioma para contacto',
    other: 'Otro'
  },
  error: {
    invalid_email: 'Formato de correo inválido',
    invalid_zip: 'Código postal inválido'
  },
  add_edit: {
    add_modal: 'Agregar usuario',
    edit_modal: 'Editar usuario',
    success: 'Usuario distribuidor añadido con éxito',
    update: 'Usuario distribuidor actualizado con éxito',
    error: 'Error al agregar el usuario distribuidor'
  },
  preregistration_success:
    'Le hemos enviado a su correo electrónico un enlace para completar el registro',
  done: 'OK',
  preregistration: {
    submit: 'Enviar'
  },
  user_activation: 'Activar Usuario'
};

export default dealers;
