import { Box, BoxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
  sxContainer?: BoxProps['sx'];
  withBorder?: boolean;
}

const SectionPanel = ({ title, children, sxContainer, withBorder }: Props) => {
  const theme = useTheme();
  return (
    <Box
      borderRadius={2}
      overflow="hidden"
      sx={{
        border: withBorder ? `1px solid ${theme.palette.grey[200]}` : 'none'
      }}
    >
      <Box
        sx={{
          color: 'white',
          background: theme.palette.primary.dark,
          px: 2,
          py: 1
        }}
      >
        <Typography>{title}</Typography>
      </Box>
      <Box
        sx={{
          background: theme.palette.background.paper,
          p: 3,
          ...sxContainer
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SectionPanel;
