/*
 * Description: palette for lightMode theme
 * Remarkable changes: refactoring for cleaner code
*/

/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

const lightPalette = (theme: any) => {
  return {
    mode: 'light',
    common: {
      black: theme.colors?.darkPaper
    },
    background: {
      paper: theme.paper,
      default: theme.backgroundDefault
    },
    primary: {
      light: theme.colors?.primaryLight,
      main: theme.colors?.primaryMain,
      dark: theme.colors?.primaryDark
    },
    secondary: {
      light: '#ffffff',
      main: theme.colors?.secondaryMain,
      dark: theme.colors?.secondaryDark
    },
    grey: {
      50: theme.colors?.grey50,
      100: theme.colors?.grey100,
      200: theme.colors?.grey200,
      300: theme.colors?.grey300,
      400: theme.colors?.grey400,
      500: theme.colors?.grey500,
      600: theme.colors?.grey600,
      700: theme.colors?.grey700,
      800: theme.colors?.grey800,
      900: theme.colors?.grey900
    },
    info: {
      main: theme.colors?.primaryMain
    },
    text: {
      primary: theme.colors?.grey700,
      secondary: theme.colors?.grey500
    },
    input: {
      background: theme.colors?.grey150,
      disabled: theme.colors?.grey100
    },
    button: {
      background: theme.colors?.primaryMain,
      disabled: theme.colors?.grey400
    }
  };
};

export default lightPalette;
