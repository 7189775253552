
// others
import unknow from '../../assets/images/doors/unknow.png';
import warning from '../../assets/images/doors/warning.png';
// Material
import { Box } from '@mui/material';

interface props {
    state: any;
    width?: number;
    isImage?: boolean;
    id?:string;
}

const getImg = (key: string): string | any => {
  switch (key) {

  case 'normal': 
    return (
      <Box
        sx={{
          width: '1.58em',
          height: '1.58em',
          borderRadius: '100%',
          border: '1px solid gray'
        }}
      >
      </Box>
    )

  case 'active':
    return (
      <Box
        sx={{
          width: '1.58em',
          height: '1.58em',
          borderRadius: '100%',
          backgroundColor: '#72bb53'
        }}
      >
      </Box>
    )

  case 'unknow':
    return <img width="100%" src={unknow} alt="Unknown" />;

  case 'warning':
    return <img width="100%" src={warning} alt="Warning" />
  }
};

const InputStateIcon = ({ state, width, id }: props) => {
  return (
    <Box maxWidth={width || 30} id={id}>
      {getImg(String(state))}
    </Box>
  );
};

export default InputStateIcon;
