const testingTool = {
  header: 'Herramienta de prueba del controlador',
  serial_number: 'Número de serie',
  site_id: 'Identificación del sitio',
  site_name: 'Nombre del sitio',
  validation_code: 'Código de validación',
  assign_to_site: 'Asignar al sitio',
  xml_command: 'Comando XML',
  send_command: 'Enviar comando',
  remove_site: 'Eliminar sitio',
  panel_alive: 'Panel vivo',
  last_signal: 'Última señal',
  received: 'Recibido',
  sent: 'Enviado',
  msg_in_memory: 'Mensajes en la Memoria',
  log: 'Registro',
  clear: 'Limpiar',
  copy_clip_message: 'El texto ha sido copiado al portapapeles con éxito',
  details: 'Detalles',
  copy_clip: 'Copiar al portapapeles',
  reset_msg: 'Presione el botón Confirmar si desea restablecer el controlador de fábrica. \n Presione Cancelar si solo desea desasignar el controlador del sitio.',
  btn_factory_reset: 'Restablecer valores de fábrica y remover',
  btn_unassign: 'Solo remover'
};

export default testingTool;
