// MUI
import { Box, Typography, TextField } from '@mui/material';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// Components
import RequiredFieldIndicator from '../RequiredFieldIndicator/RequiredFieldIndicator';
import InfoIconLabel from '../InfoIconLabel/InfoIconLabel';
import {useTheme} from '@mui/system';

interface DateTimePickerProps {
  label?: string;
  value?: any;
  width?: string;
  marginTop?: number;
  marginBottom?: number;
  onChange?: any;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  required?: boolean;
  info?: string;
  pattern?: string;
  sx?: any;
  ampm?: boolean;
  error?: boolean;
  helperText?: string;
  id?: string;
}

const DateTimePicker = ({
  label,
  value,
  width,
  marginTop,
  marginBottom,
  onChange,
  disabled,
  disableFuture,
  disablePast,
  required,
  info,
  sx,
  ampm = true,
  error,
  helperText,
  id
}: DateTimePickerProps) => {
  const theme = useTheme();
  return (
    <Box
      flex={1}
      width={width ? width : '100%'}
      marginTop={marginTop ? marginTop : 0}
      marginBottom={marginBottom ? marginBottom : 2}
    >
      {label && (
        <Box minHeight={20}>
          <Typography
            fontWeight="bold"
            sx={{
              marginLeft: '0.25em',
              color: disabled ? 'lightgray' : null,
              fontSize: '0.75em',
              ...sx
            }}
          >
            {label}
            {required && <RequiredFieldIndicator disabled={disabled} />}
            {info && <InfoIconLabel info={info} />}
          </Typography>
        </Box>
      )}
      <MUIDateTimePicker
        renderInput={(props) => (
          <TextField
            {...props}
            error={error}
            helperText={helperText}
            sx={{
              height: '2.5em',
              '& fieldset': { border: 'none' },
              '.MuiInputBase-root': {
                backgroundColor: disabled ? theme.palette.input.disabled : theme.palette.input.background,
                borderRadius: '0.5em'
              },
              '.MuiInputBase-input': {
                borderRadius: '0.5em',
                height: '0.2em'
              }
            }}
            id={id ?? 'date-time-picker'}
          />
        )}
        value={value}
        onChange={onChange}
        disabled={disabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        ampm={ampm}
      />
    </Box>
  );
};

export default DateTimePicker;
