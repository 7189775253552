import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import SectionPanel from '../../../components/SectionPanel/SectionPanel';
import routes from '../../../routes/routes';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dealerRequest } from '../../DealerRequest/interfaces/dealerRequests.interface';

interface props {
  requests: dealerRequest[]
}

const DealerRequestPanel = ({ requests }: props) => {
  const { t } = useTranslation()

  return (
    <SectionPanel
      title={t('dealerRequest.dealer_requests')}>
      <Stack
        direction="row"
        flexWrap="wrap"
        rowGap={1}
        columnGap={1}
      >
        {requests.length === 0 ? (
          <Typography>0 Pending</Typography>
        ) : (
          requests?.map((request: dealerRequest) => {
            return (
              <Typography key={request.dealer_request}>
                <Link
                  id={`request-link-${request.dealer_request}`}
                  to={routes.dealer_request_view.split(':')[0] + `${request.dealer_request}`}
                >
                  {request.company_name}
                </Link>
                {` - Dealer Request ${moment(request.created_at).format('MM/DD/YYYY hh:mm:ss a')}`}
              </Typography>
            );
          })
        )}
      </Stack>
    </SectionPanel>
  )
}

export default DealerRequestPanel;