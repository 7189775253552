/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
// Store
import { useDispatch, useSelector } from '../store';
import { removeUser, setUser } from '../store/actions/user';
// Interfaces
import user from '../interfaces/user.interface';
// Routes
import SplashScreen from '../components/SplashScreen/SplashScreen';
import { PublicRoutes } from './PublicRoutes';
// import KProg from "../views/KProg/KProg";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
  const location = useLocation();
  const user: user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const session = useSelector((store) => store.supportSession.session);

  const [loading, setLoading] = useState(true);

  // useEffect for setting user's LocalStorage data in Redux store
  useEffect(() => {
    location.pathname.includes('activate_account') && dispatch(removeUser());

    if (!session && user?.is_support) {
      dispatch(setUser({ ...user, selectedOrg: null, selectedDealer: null }));
    }
    setLoading(false);
  }, []);

  return (
    <>
      {/* <KProg /> */}
      {loading ? (
        <SplashScreen />
      ) : (
        <Routes>
          {user ? (
            <Route path="/*" element={<PrivateRoutes />} />
          ) : (
            <>
              <Route path="/*" element={<PublicRoutes />} />
            </>
          )}
          {/* HealthCheck */}
          <Route path="/status" element={<div>Status: OK</div>} />
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
