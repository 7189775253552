import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import SectionPanel from '../../../components/SectionPanel/SectionPanel';
import { useTranslation } from 'react-i18next';
import DashboardCard from '../../../components/DashboardCard/DashboardCard';
import { green, red } from '@mui/material/colors';
//Images
import controllerImg from '../../../assets/images/dashboard_icons/controllers.png';
import onlineImg from '../../../assets/images/dashboard_icons/controllers30_1_verde.png';
import moment from 'moment';
import { OfflineController, Summary } from '../../../interfaces/summary.interface';

interface props {
  summary: Summary;
  numberSize: number;
}

const ControllerPanel = ({ summary, numberSize }: props) => {
  const { t } = useTranslation()

  return (
    <SectionPanel
      title={t('organization.controllers')}
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
      >
        <DashboardCard
          width={180}
          height={105}
          padding={1.8}
          src={onlineImg}
          title={t('general.online')}
          number={summary.online_controllers || 0}
          numberSize={numberSize}
          fontSize={14}
          color={green[400]}
          action={() => { }}
        />
        <DashboardCard
          width={180}
          height={105}
          padding={1.8}
          src={controllerImg}
          title={t('general.offline')}
          number={summary.offline_controllers || 0}
          numberSize={numberSize}
          fontSize={14}
          color={red[400]}
          action={() => { }}
        />
      </Stack>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="end"
        paddingTop={2}
      >
        {summary.offline_controllers_list?.map((c: OfflineController) => {
          return (
            <Typography key={c.controller} textAlign="right">
              {`${c.serial} - ${moment(c.offline_at).format('MM/DD/YYYY hh:mm:ss a')}`}
            </Typography>
          );
        })}
      </Box>
    </SectionPanel>
  )
}

export default ControllerPanel;