import { Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from '../themes';
// Project imports
import ClearLayout from '../layout/ClearLayout/ClearLayout';
import LoginScreen from '../views/LoginScreen/LoginScreen';
import ForgotPassword from '../views/ForgotPassword/ForgotPassword';
import Activate from '../views/Dealers/Activate/Activate';
import AddDealerRequest from '../views/LoginScreen/AddDealerRequest';
import PreRegister from '../views/LoginScreen/PreRegister';

const PublicRoutes = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <Routes>
        <Route element={<ClearLayout />}>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/preregistration" element={<PreRegister />} />
          <Route path="/dealer_request" element={<AddDealerRequest />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path={'/activate_account'} element={<Activate />} />
          {/* Page Not Found */}
          <Route path="*" element={<Navigate to={'/login'} />} />
          {/* </Route> */}
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export { PublicRoutes };
