const schedules = {
  title: 'Calendarios',
  edit: 'Editar',
  delete: 'Eliminar',
  activate: 'Activar/Desactivar',
  add_schedule: 'Agregar Calendario',
  edit_schedule: 'Editar Calendario',
  add_schedules: 'Agregar Calendarios',
  search_schedules: 'Buscar Calendarios',
  cancel: 'Cancelar',
  save: 'Guardar',
  update: 'Actualizar',
  schedule_basic: 'Básico',
  schedule_advanced: 'Avanzado',
  cycle_length: 'Longitud del Ciclo ({{days}}días)',
  days_label: 'Días de la semana',
  dow: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  name: 'Nombre',
  cycle_date: 'Fecha Inicio del Ciclo',
  default_start_time: 'Hora de Inicio por Defecto',
  default_end_time: 'Hora de Fin por Defecto',
  date: 'Fecha',
  startTime: 'Hora de inicio',
  endTime: 'Hora de fin',
  today: 'Hoy',
  month: 'Mes',
  week: 'Semana',
  goToDate: 'Ir a Fecha de Inicio',
  custom: 'Personalizado',
  cycle_length_text: 'Duración del Ciclo',
  custom_modal_msg: 'Selecciona la cantidad de días del ciclo (max 28)',
  confirmation_msg: '¿Está seguro de que desea cambiar la duración del ciclo? Los cambios actuales se perderán',
  holidays: {
    include: 'Incluir',
    exclude: 'Excluir',
    drag_and_drop: 'Arrastra y suelta un día festivo para incluirlo o excluirlo',
    holidays: 'Vacaciones',
    add_new: 'Agregar Nuevo',
    INCLUDE: 'INCLUIR',
    EXCLUDE: 'EXCLUIR',
    include_description: 'La duración de las vacaciones se AGREGARÁ al cronograma',
    exlude_description: 'La duración de las vacaciones será ELIMINADA del cronograma'
  },
  table: {
    schedule_id: 'ID',
    schedule_label: 'CALENDARIOS',
    cycle_length: 'LONGITUD DEL CICLO',
    startdate_label: 'FECHA DE INICIO',
    active_label: 'ESTADO'
  }
};

export default schedules;
