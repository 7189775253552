const interlocks = {
  interlocks: 'Verrouillages',
  search: 'Recherche de verrouillages',
  add_interlocks: 'Ajouter des verrouillages',
  label: 'Étiquette',
  schedule: 'Horaire',
  name: 'Nom',
  edit: 'Modifier',
  delete: 'Effacer',
  add_group: 'Ajouter un groupe de verrouillage',
  doors: 'Portes',
  cancel: 'Annuler',
  save: 'Sauvegarder',
  add_door: 'Ajouter des portes',
  controller: 'Contrôleur',
  side: 'côté',
  group: 'Groupe de verrouillage',
  actions: 'Remove',
  door_name: 'Nom de la porte',
  search_door_by_name: 'Recherche de porte par nom',
  side_a: 'Côté A',
  side_b: 'Côté B',
  add: 'Ajouter',
  add_selected: 'Ajouter Sélectionné',
  selected: 'Sélectionné',
  remove: 'Retirer',
  edit_group: 'Modifier le groupe de verrouillage',
  delete_interlock_confirmation: 'Êtes-vous sûr de vouloir supprimer ce groupe de verrouillage?'
}

export default interlocks;
