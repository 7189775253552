import HeadCell from '../../../../../components/MaterialTable/interfaces/HeadCell.interface';

const sitesHeadCell: HeadCell<any>[] = [
  {
    id: 'name',
    type: 'any',
    label: 'sites.sites'
  }
];

export default sitesHeadCell;
