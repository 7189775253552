const testingTool = {
  header: 'Controller Testing Tool',
  serial_number: 'Serial Number',
  site_id: 'Site ID',
  site_name: 'Site Name',
  validation_code: 'Validation Code',
  assign_to_site: 'Assign to Site',
  xml_command: 'XML Command',
  send_command: 'Send Command',
  remove_site: 'Remove Site',
  panel_alive: 'Panel alive',
  last_signal: 'Last signal ',
  received: 'Received',
  sent: 'Sent',
  msg_in_memory: 'Messages in Memory',
  log: 'Log',
  clear: 'Clear',
  copy_clip_message: 'The text has been copied to the clipboard successfully',
  details: 'Details',
  copy_clip: 'Copy to Clipboard',
  reset_msg: 'Press Confirm button if you want to factory reset the controller. \n Press Cancel if you only want unassign the controller from site.',
  btn_factory_reset: 'Factory reset and remove',
  btn_unassign: 'Only remove'
};

export default testingTool;
