import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { putUserpasswrd } from '../../../api/api';
import Button from '../../../components/Button/Button';
import FormField from '../../../components/FormField/FormField';
import Modal from '../../../components/Modal/Modal';
import PsswdRequirementsList from '../../../components/PsswdRequirementsList/PsswdRequirementsList';

type props = {
  open: boolean;
  onClose: () => void;
  onSuccess: (msg: string) => void;
  onError: (msg: string) => void;
};

const initBody = { oldPassword: '', newPassword: '', confirm: '' };

const ChangePassword = ({ open, onClose, onSuccess, onError }: props) => {
  const { t } = useTranslation();

  const [body, setBody] = useState(initBody);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    !open && setBody(initBody);
  }, [open]);

  useEffect(() => {
    setDisable(
      !body.confirm ||
        !body.newPassword ||
        !body.oldPassword ||
        body.newPassword !== body.confirm
    );
  }, [body]);

  const handlePut = async () => {
    setLoading(true);
    try {
      const { message } = await putUserpasswrd(body);
      setLoading(false);
      onSuccess(message);
    } catch (error: any) {
      setLoading(false);
      onError(error.response.data?.message || error.message);
    }
  };

  const returActions = () => {
    return (
      <>
        <Button color="gray" disabled={loading} onClick={onClose}>
          {t('general.btn.cancel')}
        </Button>
        <Button disabled={loading || disable} onClick={handlePut}>
          {t('general.btn.change')}
        </Button>
      </>
    );
  };

  return (
    <Modal
      width="sm"
      open={open}
      onClose={onClose}
      title={t('config.change_password')}
      disableBackdropClose={loading}
      disableEscapeKeyClose={loading}
      disableCloseButton={loading}
      actions={returActions()}
    >
      <>
        <FormField
          required
          label={t('forgotPassword.current_password')}
          type="password"
          value={body.oldPassword}
          onChange={(e: any) =>
            setBody({ ...body, oldPassword: e.target.value })
          }
          maxLength={30}
        />
        <FormField
          required
          label={t('forgotPassword.new_password')}
          type="password"
          value={body.newPassword}
          onChange={(e: any) =>
            setBody({ ...body, newPassword: e.target.value })
          }
          maxLength={30}
        />
        <FormField
          required
          label={t('forgotPassword.confirm_password')}
          type="password"
          value={body.confirm}
          onChange={(e: any) => setBody({ ...body, confirm: e.target.value })}
          maxLength={30}
          error={body.newPassword !== body.confirm}
          errorText={t('forgotPassword.error.password_match')}
        />

        <PsswdRequirementsList
          password={body.newPassword}
          confirmPassword={String(body.confirm)}
          setDisable={setDisable}
        />
      </>
    </Modal>
  );
};

export default ChangePassword;
