import React, { useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  CardElement
} from '@stripe/react-stripe-js';
import FormField from '../FormField/FormField';
import Button from '../Button/Button';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import user from '../../interfaces/user.interface';
import { useSelector } from '../../store';
import { postPayment } from '../../api/api';
import validateEmail from '../../utils/email-validation';

interface props {
  onSuccess: (msg?: string) => void;
  onError: (msg: string) => void;
  onCancel: () => void;
  disabled?: boolean;
  hideBtns?: boolean;
  next?: boolean;
  component?: React.ReactNode;
}

export default function CheckoutForm({
  onSuccess,
  onError,
  onCancel,
  disabled,
  hideBtns,
  next,
  component
}: props) {
  const user: user = useSelector((store) => store.user.user);
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isDefault, setIsDefault] = useState(true);

  useEffect(() => {
    setName(`${user.first_name} ${user.last_name}`);
    setEmail(user.email);
  }, []);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const cardElement: any = elements.getElement(CardElement);
    const { error, paymentMethod }: any = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: name,
        email: email
      }
    });

    if (error) {
      setLoading(false);
      onError(error?.message || error);
    } else {
      handleConfirm(paymentMethod.id);
    }
  };

  const handleConfirm = async (paymentMethod: string) => {
    setLoading(true);
    try {
      const body = {
        dealer: user.selectedDealer?.dealer || user.currentProfile,
        payment_method: paymentMethod,
        is_default: isDefault
      };
      const { status, message } = await postPayment(body);
      if (status === 200) {
        onSuccess(message);
      }
    } catch (error: any) {
      onError(error.response.data?.message || error.message);
    }
    setLoading(false);
  };

  const CARD_ELEMENT_OPTIONS = {
    disabled: disabled,
    style: {
      base: {
        fontSmoothing: 'antialiased',
        fontSize: '12px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        // color: "#fa755a",
        iconColor: '#fa755a'
      }
    }
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <Stack direction="column">
          <FormField
            value={name}
            required
            disabled={disabled}
            onChange={(e: any) => setName(e.target.value)}
            placeholder={t('organization.name_on_card')}
          />
          {/* <FormField
            value={email}
            required
            disabled={disabled}
            onChange={(e: any) => setEmail(e.target.value)}
            placeholder={t("dealers.form.email")}
          /> */}
          <Box
            sx={{
              background: '#0000000D',
              padding: 1.28,
              borderRadius: '0.5em'
            }}
          >
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </Box>
          {/* isDefault */}
          {/* <FormControlLabel
          label={t("organization.is_default")}
          control={
            <Checkbox
              checked={isDefault}
              onChange={(e: any) => setIsDefault(e.target.checked)}
            />
          }
        ></FormControlLabel> */}
          {/* <CardCvcElement />
           */}
          {component && <Box mt={2}>{component}</Box>}
          {!hideBtns && (
            <Stack mt={2} direction="row" justifyContent="end" gap={1}>
              <Button
                color="gray"
                disabled={loading}
                onClick={() => onCancel()}
              >
                {t('general.btn.cancel')}
              </Button>

              {next ? (
                <Button disabled={loading} onClick={() => onSuccess()}>
                  {t('general.btn.next')}
                </Button>
              ) : (
                <Button
                  disabled={
                    loading || name?.length < 6 || !validateEmail(email)
                  }
                  type="submit"
                >
                  {next !== undefined
                    ? t('general.btn.next')
                    : t('general.btn.save')}
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </form>
    </>
  );
}
