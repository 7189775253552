export const SET_STRIPE_PROMISE = 'SET_STRIPE_PROMISE';

interface SetStripePromiseAction {
  type: typeof SET_STRIPE_PROMISE;
  payload: any; // Define the type of stripePromise payload
}

export type StripePromiseActionTypes = SetStripePromiseAction;

export const setStripePromise = (stripePromise: any): SetStripePromiseAction => ({
  type: SET_STRIPE_PROMISE,
  payload: stripePromise
});