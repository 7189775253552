import { useState } from 'react';
// MUI
import {
  Box,
  InputBase,
  Typography,
  InputAdornment,
  IconButton,
  Grow,
  FormControlLabel
} from '@mui/material';
// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Components
import RequiredFieldIndicator from '../RequiredFieldIndicator/RequiredFieldIndicator';
import Checkbox from '../Checkbox/Checkbox';
import { blue } from '@mui/material/colors';
import InfoIconLabel from '../InfoIconLabel/InfoIconLabel';
import { useTheme } from '@mui/system';

interface FormFieldProps {
  ref?: any;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  width?: string;
  marginTop?: number;
  marginBottom?: number | string;
  onChange?: any;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  info?: string;
  error?: boolean;
  errorText?: string;
  maxLength?: number;
  rows?: number;
  pattern?: string;
  bigForm?: boolean;
  fontSize?: number;
  sx?: any;
  max?: number;
  min?: number;
  optionalCheckBox?: boolean;
  checkBoxChecked?: boolean;
  onCheckBoxChange?: any;
  onBlur?: any;
  checkBoxLabel?: string;
  keepLabelEnabled?: boolean;
  boxMarginBottom?: number;
  id?:string;
  defaultVisible?: boolean;
  inputProps?: any;
  onKeyDown?: (e: any) => void;
}

const FormField = ({
  ref,
  name,
  label,
  placeholder,
  value,
  width,
  marginTop,
  marginBottom,
  onChange,
  type = 'text',
  disabled,
  required,
  info,
  error,
  errorText,
  maxLength,
  rows,
  bigForm,
  fontSize,
  sx,
  max,
  min,
  optionalCheckBox,
  checkBoxChecked,
  onCheckBoxChange,
  onBlur,
  checkBoxLabel,
  keepLabelEnabled,
  boxMarginBottom,
  id,
  defaultVisible = false,
  inputProps,
  onKeyDown
}: FormFieldProps) => {
  const [showPassword, setShowPassword] = useState(defaultVisible);
  const theme = useTheme();

  return (
    <Box
      flex={1}
      width={width ? width : '100%'}
      marginTop={marginTop ? marginTop : 0}
      marginBottom={marginBottom ? marginBottom : 2}
      // sx={{ marginBottom: boxMarginBottom ? boxMarginBottom : 2 }}
    >
      {label && (
        <Box
          minHeight={20}
          sx={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}
        >
          <Typography
            fontWeight="bold"
            sx={{
              marginLeft: '0.25em',
              color: disabled && !keepLabelEnabled ? 'lightgray' : null,
              fontSize: '0.75em'
            }}
          >
            {label}
            {required && <RequiredFieldIndicator disabled={disabled} />}
            {info && <InfoIconLabel sx={{ mt: -1 }} info={info} />}
          </Typography>
          {optionalCheckBox && (
            <FormControlLabel
              sx={{ marginLeft: '6px' }}
              control={
                <Checkbox
                  sx={{ padding: '0px' }}
                  checked={checkBoxChecked}
                  onChange={onCheckBoxChange}
                />
              }
              label={
                <span style={{ fontSize: '0.75rem' }}>{checkBoxLabel}</span>
              }
            />
          )}
        </Box>
      )}
      <InputBase
        ref={ref}
        name={name}
        id={id}
        placeholder={placeholder ? placeholder : ''}
        autoComplete="off"
        disabled={disabled}
        value={value}
        onChange={(e: any) => {
          onChange(e);
        }}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        sx={{
          height: rows ? '' : bigForm ? '4.5em' : '2.5em',
          borderRadius: bigForm ? '0.1em' : '0.5em',
          width: '100%',
          backgroundColor: disabled ? theme.palette.input.disabled : theme.palette.input.background,
          padding: '0.6em',
          border: error ? '0.5px solid #f44336' : '0.5px solid transparent',
          borderLeft: bigForm ? `0.6em solid ${blue[900]}` : '',
          fontSize: fontSize ? fontSize : null,
          textAlign: 'center',
          align: 'center',
          ...sx
        }}
        inputProps={{
          maxLength: maxLength || 50,
          textAlign: 'center',
          max: max,
          min: min,
          ...inputProps
        }}
        multiline={rows ? true : false}
        minRows={rows}
        maxRows={rows}
        endAdornment={
          type === 'password' && (
            <InputAdornment position="end" sx={{ marginRight: '0.5em' }}>
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          )
        }
      />
      {errorText && (
        <Grow
          in={error}
          timeout={400}
          unmountOnExit
          style={{
            height: '0.25em',
            marginBottom: '-0.25em',
            transformOrigin: '0 0 0'
          }}
        >
          <Typography color="#f44336" marginLeft={1} fontSize="small">
            {errorText}
          </Typography>
        </Grow>
      )}
    </Box>
  );
};

export default FormField;
