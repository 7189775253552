import * as actions from '../actions/hardware-outputs';
import { AnyAction } from '@reduxjs/toolkit';

let initialState = {
  outputs: []
}

export const hardwareOutputReducer = (state = initialState as { outputs:any | undefined }, action:AnyAction) => {
  switch (action.type) {

  // Set reades list of controller
  case actions.SET_OUTPUTS:
    return {
      ...state,
      outputs: [...action.payload]
    }
        
    // Update reader list"
  case actions.UPDATE_OUTPUT:
    const updateOutputs = state.outputs.map((output:any) => {
      return output.output === action.payload.output ? action.payload : output
    });

    return {
      ...state,
      outputs: updateOutputs
    }

  default: 
    return state;
  }
}