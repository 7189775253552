import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Interfaces
// MUI
import {
  Paper,
  Stack,
  Typography,
  IconButton,
  Box,
  TextField
} from '@mui/material';
// Project imports
import Button from '../../../../components/Button/Button';
import { Plans } from '../../interfaces/plans.interface';
import PaymentList from '../PaymentList/PaymentList';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import CalendarIcon from '../../../../assets/images/dashboard_icons/calendar.png';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// Images
import CheckIcon from '@mui/icons-material/Check';
import DoorIcon from '../../../../assets/images/doors/door_active.png';
import InvoiceIcon from '../../../../assets/images/dashboard_icons/invoice.png';
import DownloadIcon from '../../../../assets/images/icons/Download.png';
import CheckImage from '../../../../assets/images/icons/check.png';
import { numberWithCommas } from '../../../../utils/numberWithCommas';
import {
  getDealerInvoicesPaymentPDF
} from '../../../../api/api';
import user from '../../../../interfaces/user.interface';
import { useSelector } from '../../../../store';

interface props {
  step: number;
  setStep: (n: number) => void;
  selectedPlan: Plans;
  type_payment: 'month' | 'year';
  doors: number;
  setDoors: (n: number) => void;
  payment_method: string;
  setPayment_method: (s: string) => void;
  checkout: (skip?: boolean) => void;
  loading: boolean;
  finish: any;
  paymentId: string;
  isCheckout: boolean;
}

const Doors = ({
  step,
  setStep,
  selectedPlan,
  type_payment,
  doors,
  setDoors,
  payment_method,
  setPayment_method,
  checkout,
  loading,
  finish,
  paymentId,
  isCheckout
}: props) => {
  const { t } = useTranslation();
  const user: user = useSelector((store) => store.user.user);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {}, []);

  // useEffect(() => {
  //   step === 3 && setInnerStep(1);
  // }, [step]);

  // const handleDisable = () => {
  //   switch (step) {
  //     case 1:
  //       setDisable(doors === 0);
  //       break;
  //     case 2:
  //       setDisable(false);
  //       break;
  //     case 3:
  //       setDisable(payment_method === "");
  //       break;
  //     case 4:
  //       break;
  //   }
  // };

  // const fetchNewSubscription = async () => {
  //   try {
  //     const { newSubscriptionValues, status } = await getDealerSubValues(
  //       user.selectedDealer?.dealer || user.currentProfile,
  //       doors,
  //       0
  //     );
  //     if (status == 200) {
  //       setSubscription(newSubscriptionValues);
  //       setInnerStep(1);
  //     }
  //   } catch (error: any) {
  //     console.error(error);
  //   }
  // };

  const handleCounter = (action: 'plus' | 'minus') => {
    const num = action === 'plus' ? doors + 1 : doors > 0 ? doors - 1 : doors;
    setDoors(num);
  };

  const handleChange = (e: any) => {
    if (!isNaN(e.target.value) && +e.target.value >= 0) {
      setDoors(+e.target.value);
    }
  };

  const handlePrint = async (paymentId: string) => {
    getDealerInvoicesPaymentPDF(
      user.selectedDealer?.dealer || user.currentProfile,
      paymentId
    )
      .then((res) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Invoice_${paymentId}.pdf`;
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const returnCard = (
    header: string,
    number: string,
    src: string | undefined,
    onClick?: () => void
  ) => {
    return (
      <span onClick={onClick || undefined}>
        <Box
          sx={{
            paddingX: 1,
            paddingY: 0.5,
            minWidth: '8em',
            height: '4.5em',
            backgroundColor: 'transparent',
            border: '2px solid #D9DBDA',
            borderRadius: 2,
            cursor: onClick ? 'pointer' : 'default'
          }}
        >
          <Typography
            variant="h4"
            component="p"
            color="gray"
            gutterBottom
            noWrap
          >
            {header}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography color={'#327CC4'} variant="h3" component="p" noWrap>
              {number}
            </Typography>
            {src && <img src={src} width={20} alt="" />}
          </Box>
        </Box>
      </span>
    );
  };

  const returnPlan = (plan: any, isPro = false) => {
    return (
      <Box
        key={`${plan.cdvi_plan}_btn`}
        sx={{ borderRadius: 5, border: '1px solid lightgray' }}
        padding={2}
        width={{ xs: '100%', md: '380px' }}
      >
        <Typography component="h3" variant="h3" mb={2}>
          {plan.name}
        </Typography>
        <Box sx={{ filter: isPro ? 'blur(4px)' : '' }} height={100}>
          <Typography component="div">{plan.description}</Typography>
        </Box>
        <Typography
          component="h4"
          variant="h4"
          gutterBottom
          sx={{ filter: isPro ? 'blur(4px)' : '' }}
        >
          $
          {`${numberWithCommas(
            Number(
              (type_payment === 'year' ? +plan.year_price : +plan.month_price) *
                (doors || 1)
            ).toFixed(2)
          )}`}
        </Typography>
        <Typography
          mb={2}
          component="div"
          sx={{ filter: isPro ? 'blur(4px)' : '' }}
        >
          {`/${
            type_payment === 'year'
              ? t('organization.yearly').toLocaleLowerCase()
              : t('organization.monthly').toLocaleLowerCase()
          } ${t('dealers.for_doors').toLocaleLowerCase()}`}
        </Typography>
        {/* Btn */}

        <Button
          width="100%"
          disabled={isPro}
          color={
            selectedPlan?.cdvi_plan === plan.cdvi_plan ? 'primary' : 'gray'
          }
        >
          {isPro
            ? t('general.coming_soon')
            : selectedPlan?.cdvi_plan === plan.cdvi_plan
              ? t('general.btn.selected')
              : t('general.btn.select')}
        </Button>

        <Typography
          mt={2}
          mb={2}
          component="h4"
          variant="h4"
          sx={{ filter: isPro ? 'blur(4px)' : '' }}
        >
          {t('general.features')}:
        </Typography>

        {plan.feature.map(
          (ft: any) =>
            ft.included && (
              <Stack
                gap={2}
                direction="row"
                sx={{ filter: isPro ? 'blur(4px)' : '' }}
              >
                <CheckIcon color="success" />
                <Typography component="span">{ft.description}</Typography>
              </Stack>
            )
        )}
      </Box>
    );
  };

  const changeTab = () => {
    setStep(3);
  };

  return (
    <Paper>
      {step === 2 ? (
        // inner step one
        <Box>
          <Stack direction="row" gap={2}>
            {/* Plan */}
            {returnPlan(selectedPlan)}

            <Box flex={1}>
              <Typography
                textAlign="center"
                marginBottom={2}
                fontSize={18}
                color={'#327CC4'}
              >
                {t('organization.modal.description')}
              </Typography>
              <Stack direction={'row'} justifyContent={'center'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography marginBottom={1} textAlign={'center'}>
                    {t('organization.doors')}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton
                      disableRipple
                      onClick={() => handleCounter('minus')}
                    >
                      <RemoveCircleOutlineIcon sx={{ fontSize: 32 }} />
                    </IconButton>
                    <TextField
                      value={doors}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          color: 'gray',
                          fontSize: 32,
                          padding: 1,
                          width: '3em',
                          height: '1.5em'
                        }
                      }}
                      sx={{ width: '100%', textAlign: 'center' }}
                      type="text"
                      onChange={handleChange}
                    ></TextField>
                    <IconButton
                      disableRipple
                      onClick={() => handleCounter('plus')}
                    >
                      <AddCircleOutlineIcon
                        sx={{ color: '#00457D', fontSize: 32 }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  {/* <Typography marginBottom={1} textAlign={"center"}>
                  {t("organization.mobile_passes")}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <IconButton disableRipple onClick={decreaseMobilePass}>
                    <RemoveCircleOutlineIcon sx={{ fontSize: 32 }} />
                  </IconButton>
                  <TextField
                    value={mobilepassCounter}
                    inputProps={{
                      maxLength: 4,
                      style: {
                        textAlign: "center",
                        color: "gray",
                        fontSize: 32,
                        padding: 1,
                        width: "3em",
                        height: "1.5em",
                      },
                    }}
                    sx={{ width: "100%" }}
                    type="text"
                    onChange={(e) => {
                      if (e.target.value === "" || regex.test(e.target.value)) {
                        setMobilepass(Number(e.currentTarget.value));
                      }
                    }}
                  ></TextField>
                  <IconButton disableRipple onClick={increaseMobilepass}>
                    <AddCircleOutlineIcon
                      sx={{ color: "#00457D", fontSize: 32 }}
                    />
                  </IconButton>
                </Box> */}
                </Box>
              </Stack>
              {/* <Stack
                direction="column"
                alignContent="center"
                justifyContent="center"
              >
                <Typography
                  sx={{ color: blue[500] }}
                  variant="h2"
                  textAlign="center"
                  marginBottom={2}
                >
                  {t("dealers.doors_to_add")}
                </Typography>
                <Typography variant="h4" textAlign="center" gutterBottom>
                  {t("organization.doors")}
                </Typography>
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <IconButton
                    color="primary"
                    onClick={() => handleCounter("minus")}
                    // disabled={doors === 0 || step !== 1}
                  >
                    <RemoveCircleOutlineOutlinedIcon fontSize="large" />
                  </IconButton>

                  <Box>
                    <FormField
                      maxLength={3}
                      marginBottom={0.1}
                      width={"10em"}
                      value={doors}
                      fontSize={35}
                      onChange={handleChange}
                    />
                  </Box>
                  <IconButton
                    color="primary"
                    onClick={() => handleCounter("plus")}
                    // disabled={step !== 1}
                  >
                    <AddCircleOutlineOutlinedIcon fontSize="large" />
                  </IconButton>
                </Stack>
              </Stack> */}
            </Box>
          </Stack>
          <Stack mt={2} direction="row" gap={1} justifyContent="end">
            <Button
              color="gray"
              disabled={loading}
              onClick={() => setStep(step - 1)}
            >
              {t('general.btn.previous')}
            </Button>
            <Button
              disabled={loading}
              onClick={() =>
                doors === 0 ? setShowConfirmModal(true) : changeTab()
              }
            >
              {doors === 0 ? t('general.btn.skip') : t('general.btn.next')}
            </Button>
          </Stack>
        </Box>
      ) : (
        <>
          <Stack direction="row" gap={1} justifyContent="space-between">
            <Stack direction="row" mb={2} gap={1}>
              {returnCard(
                t('dealers.door_licenses_available'),
                `${doors}`,
                DoorIcon
              )}
              {returnCard(
                `${selectedPlan.name} - ${
                  type_payment === 'year'
                    ? t('organization.yearly')
                    : t('organization.monthly')
                }`,
                '',
                CalendarIcon
              )}
            </Stack>
            <Box>
              <Stack direction="row" gap={1}>
                <img src={InvoiceIcon} width={20} alt="Invoice" />
                <Typography variant="h4" component="p">
                  {' '}
                  {t('organization.invoice')}
                </Typography>
              </Stack>

              {/* Alo */}
              <Box sx={{ paddingX: 3 }}>
                <Stack direction={'row'} justifyContent="end">
                  <Typography
                    variant="h4"
                    component="p"
                    fontWeight="light"
                    sx={{
                      minWidth: 55,
                      textAlign: 'right',
                      paddingBottom: 0.5,
                      borderBottom: '1px solid gray'
                    }}
                  >
                    {type_payment === 'year'
                      ? t('organization.yearly')
                      : t('organization.monthly')}
                  </Typography>
                </Stack>
                <Stack direction={'row'} gap={2} justifyContent={'end'}>
                  <Box>
                    {doors !== 0 && (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingY: 0.5,
                          borderBottom: '1px solid #B9B9B9'
                        }}
                      >
                        <Typography
                          variant="h4"
                          component="p"
                          fontWeight="light"
                        >
                          + {doors} {t('organization.doors')}:
                        </Typography>
                        <Typography
                          variant="h4"
                          component="p"
                          fontWeight="light"
                          marginLeft={1}
                        >
                          {`$ ${numberWithCommas(
                            Number(
                              type_payment === 'year'
                                ? +selectedPlan?.year_price
                                : +selectedPlan?.month_price
                            ).toFixed(2)
                          )}`}
                        </Typography>
                      </Box>
                    )}
                    {/* {mobilepassCount !== 0 && (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingY: 0.5,
                          borderBottom: "1px solid #B9B9B9",
                        }}
                      >
                        <Typography>
                          + {mobilepassCount} {t("organization.mobile_passes")}:
                        </Typography>
                        <Typography marginLeft={1}>
                          $
                          {numberWithCommas(
                            Number(
                              newSubscription?.due_value_by_mobilepass
                            ).toFixed(2)
                          )}
                        </Typography>
                      </Box>
                    )} */}
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingY: 0.5,
                        borderBottom: '1px solid #B9B9B9'
                      }}
                    >
                      <Typography variant="h4" component="p" fontWeight="light">
                        {t('organization.modal.due_today')}:
                      </Typography>
                      <Typography marginLeft={1}></Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'end'
                    }}
                  >
                    {doors !== 0 && (
                      <Typography
                        variant="h4"
                        component="p"
                        fontWeight="light"
                        sx={{
                          minWidth: 55,
                          textAlign: 'right',
                          paddingY: 0.5,
                          borderBottom: '1px solid #B9B9B9'
                        }}
                      >
                        {`$ ${numberWithCommas(
                          Number(
                            (type_payment === 'year'
                              ? +selectedPlan?.year_price
                              : +selectedPlan?.month_price) * (doors || 1)
                          ).toFixed(2)
                        )}`}
                      </Typography>
                    )}
                    {/* {mobilepassCount !== 0 && (
                      <Typography
                        color={"#B9B9B9"}
                        sx={{
                          minWidth: 55,
                          textAlign: "right",
                          paddingY: 0.5,
                          borderBottom: "1px solid #B9B9B9",
                        }}
                      >
                        ${numberWithCommas(mobilePassCycleDif.toFixed(2))}
                      </Typography>
                    )} */}
                    <Typography
                      variant="h4"
                      component="p"
                      fontWeight="light"
                      sx={{
                        minWidth: 55,
                        textAlign: 'right',
                        paddingY: 0.5,
                        borderBottom: '1px solid #B9B9B9'
                      }}
                    >
                      {`$ ${numberWithCommas(
                        Number(
                          (type_payment === 'year'
                            ? +selectedPlan?.year_price
                            : +selectedPlan?.month_price) * (doors || 1)
                        ).toFixed(2)
                      )}`}
                    </Typography>
                  </Box>
                </Stack>
                {/* <Typography
                  sx={{
                    color: "#327CC4",
                    marginY: 2,
                    marginLeft: 3,
                    textAlign: "right",
                  }}
                >
                  {t("organization.next_invoice")}:{" "}
                  {moment().format("MM-DD-YYYY")}
                </Typography> */}
              </Box>
            </Box>
          </Stack>
          {isCheckout ? (
            <Stack direction="row" mb={2} gap={1}>
              <Stack direction="row" gap={1} marginTop={1}>
                {/* {returnCardAction(t('organization.modal.print_receipt'), PrintIcon, handlePrint)} */}
                {returnCard(
                  t('organization.modal.download_receipt'),
                  '',
                  DownloadIcon,
                  () => handlePrint(paymentId)
                )}
                {returnCard(
                  t('organization.modal.finish'),
                  '',
                  CheckImage,
                  finish
                )}
              </Stack>
            </Stack>
          ) : (
            <PaymentList
              addCard={false}
              payment_method={payment_method}
              setPayment_method={setPayment_method}
              previous={() => setStep(2)}
              checkout={() => setShowConfirmModal(true)}
            />
          )}
        </>
      )}

      {/* Confirmation modal */}
      <ConfirmationModal
        icon
        message={
          step === 2
            ? t('organization.skip_checkout_msg')
            : t('organization.confirm_checkout_msg')
        }
        cancelBtnTxt={t('general.btn.cancel')}
        submitBtnTxt={t('general.btn.confirm')}
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={() => {
          setShowConfirmModal(false);
          checkout(step === 2);
        }}
      />

    </Paper>
  );
};

export default Doors;
