/*
 * Description: theme-related settings for both light and dark modes
 * Remarkable changes: refactoring for cleaner code
*/

import { createTheme, ThemeOptions } from '@mui/material/styles';

// assets
import colors from '../assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import lightPalette from './lightPalette';
import darkPalette from './darkPalette';
import themeTypography from './typography';
import darkTypography from './darkTypography';

const color = colors;

const commonThemeOptions = {
  // Common properties for both light and dark modes
  customization: {
    fontFamily: [
      'Lato',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
};

const lightTheme = createTheme({
  direction: 'ltr',
  typography: themeTypography(commonThemeOptions),
  palette: lightPalette({
    ...{
      colors: color,
      paper: color.paper,
      backgroundDefault: color.secondaryLight,
      background: color.primaryLight
    }
  })
} as ThemeOptions);

const darkTheme = createTheme({
  direction: 'ltr',
  typography: darkTypography(commonThemeOptions),
  palette: darkPalette({
    ...{
      colors: color,
      paper: color.darkPaper,
      backgroundDefault: color.darkBackground,
      background: color.primaryLight
    }
  })
} as ThemeOptions);

// const theme = createTheme(darkmode ? darkTheme as ThemeOptions : lightTheme as ThemeOptions);
darkTheme.components = componentStyleOverrides(darkTheme as ThemeOptions);
lightTheme.components = componentStyleOverrides(lightTheme as ThemeOptions);

export { lightTheme, darkTheme};
