/*
 * Remarkable changes:
 * - 2024-09-11 / Diego Rincón <drincon@binwus.com>
 *   Check user permissions with action map from Redux store 
 */

import tableActions from '../../interfaces/tableActions.interface';
import { store } from '../../store';

/**
 * Filter the array of actions by checking if any of the tags in the action is true in the action map.
 * If the tab doesn't have tags, it will be included in the resulting array as well.
 * @param customActions
 * @returns filtered array of actions
 */
const disableOptionByTag = (
  customActions: tableActions[]
) => {
  const actionMap: Record<string, boolean> = store.getState().user.actionMap;
  return customActions.filter(
    (action) =>
      !action.tag ||
      action.tag.some((tag) => actionMap[tag.trim().toLocaleLowerCase()])
  );
};

export default disableOptionByTag;
