/*
 * Description: Component to conditionally render children based on user permissions.
 * Created by: Diego Rincón <drincon@binwus.com>
 * Created at: 2024-09-05
 */

import { PropsWithChildren } from 'react';
import {
  usePermission,
  UsePermissionParams
} from '../../utils/hooks/usePermission';

/**
 * Component to conditionally render children based on user permissions.
 *
 * @param actions - List of action tags to check.
 * @param checkMode - Mode to check permissions: 'and' requires all actions, 'or' requires at least one.
 * @param extraCondition - An additional condition to be met.
 * @returns Returns the children if the user has the required permissions, otherwise returns null.
 */
export const WithPermission = ({
  children,
  ...permissionParams
}: PropsWithChildren<UsePermissionParams>) => {
  const hasPermission = usePermission(permissionParams);

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
};
