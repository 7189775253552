const menu = {
  header: 'Lista de menú',
  add_menu: 'Añadir Menú',
  search_menu: 'Menú de búsqueda',
  submit: 'enviar',
  cancel: 'Cancelar',
  update_menu: 'Actualizar Menú',
  success_update: 'Actualización Exitosa',
  success_add: 'Nuevo Menú agregado exitosamente',
  forma: {
    name: 'Nombre',
    description: 'Descripción',
    path: 'Camino',
    order: 'Orden',
    roles_permission: 'Permiso de roles',
    menu_list: 'Lista de Menú',
    none: 'Ninguno',
    icon: 'Icono'
  }
};

export default menu;
