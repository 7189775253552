import HeadCell from '../../components/MaterialTable/interfaces/HeadCell.interface';

export const statusList = [
  {
    value: 'Missing',
    icon: 'circleIcon',
    color: 'red',
    text: 'status_text'
  },
  {
    value: 'Offline',
    icon: 'circleIcon',
    color: 'red',
    text: 'status_text'
  },
  {
    value: 'Forced',
    icon: 'circleIcon',
    color: 'red',
    text: 'status_text'
  },
  {
    value: 'Alarm',
    icon: 'circleIcon',
    color: 'red',
    text: 'status_text'
  },
  {
    value: 'Trouble',
    icon: 'circleIcon',
    color: 'yellow',
    colorIntensity: 700,
    text: 'status_text'
  },
  {
    value: 'Pre-Alarm',
    icon: 'circleIcon',
    color: 'yellow',
    colorIntensity: 700,
    text: 'status_text'
  },
  {
    value: 'Tamper',
    icon: 'circleIcon',
    color: 'yellow',
    colorIntensity: 700,
    text: 'status_text'
  },
  {
    value: 'Tamper Alarm',
    icon: 'circleIcon',
    color: 'red',
    text: 'status_text'
  }
];

export const alertsTableHeaders: HeadCell<any>[] = [
  {
    id: 'site_name',
    type: 'any',
    label: 'organization.table.site'
  },
  {
    id: 'alarmed_at',
    type: 'any',
    label: 'general.date_time'
  },
  {
    id: 'controller_serial_number',
    type: 'any',
    label: 'firmware.table.controller'
  },
  {
    id: 'object_type',
    type: 'any',
    label: 'reports.events.table.object_type'
  },
  {
    id: 'entity_associated_name',
    type: 'any',
    label: 'roles.table.name'
  },
  {
    id: 'status_text',
    type: 'iconOptions',
    label: 'general.alerts',
    iconList: statusList
  },
  {
    id: 'description',
    type: 'any',
    label: 'doors.table.description'
  }
];

export const alertsTableHeadersMini: HeadCell<any>[] = [
  {
    id: 'controller_serial_number',
    type: 'any',
    label: 'firmware.table.controller'
  },
  {
    id: 'object_type',
    type: 'any',
    label: 'reports.events.table.object_type'
  },
  {
    id: 'status_text',
    type: 'iconOptions',
    label: 'firmware.table.status',
    iconList: statusList
  }
];

export const dealerHeaders: HeadCell<any>[] = [
  {
    id: 'organization_name',
    type: 'any',
    label: 'organization.table.organization'
  },
  {
    id: 'site_name',
    type: 'any',
    label: 'organization.table.site'
  },
  {
    id: 'alarmed_at',
    type: 'any',
    label: 'general.date_time'
  },
  {
    id: 'trouble_count',
    type: 'any',
    label: 'general.quantity'
  },
  {
    id: 'status_text',
    type: 'iconOptions',
    label: 'general.alerts',
    iconList: statusList.map(({ text, ...rest }) => rest)
  }
];
