import { Box } from '@mui/material';
import React from 'react';
import Avatar from '../Avatar/Avatar';
import { green, grey } from '@mui/material/colors';

interface props {
  imgSrc?: string;
  imgUrl?: string;
  text?: string;
  status: boolean;
  dimmension?: number;
}

const UserAvatarStatus = ({
  imgSrc,
  imgUrl,
  text,
  status,
  dimmension
}: props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Avatar
        src={imgSrc || ''}
        url={imgUrl || ''}
        sx={{ mr: 1, width: dimmension || 70, height: dimmension || 70, mb: 0 }}
      >
        {text}
      </Avatar>
      <Box
        sx={{
          background: status ? green[500] : grey[500],
          borderRadius: '100%',
          position: 'absolute',
          width: 15,
          height: 15,
          top: '75%',
          right: 10
        }}
      ></Box>
    </Box>
  );
};

export default UserAvatarStatus;
