import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography
} from '@mui/material';

// Api
import Button from '../../../components/Button/Button';
import FormField from '../../../components/FormField/FormField';
import Countdown from '../../../components/Countdown/Countdown';

interface props {
    handleLogin: () => void;
    handleVerifyOtp: () => void;
    otp: string;
    setOtp: (otp:string) => void;
}


const LoginOTP = ({otp, setOtp, handleLogin, handleVerifyOtp}: props) => {

  const expireTime = 300;

  const [loading, setLoading] = useState(false);
  const [disabledOTP, setDisabledOTP] = useState(true);
  const [disabledResend, setDisabledResend] = useState(true);
  const [timeLeft, setTimeLeft] = useState(expireTime);

  const { t } = useTranslation();

  const onlyNumbers = /^[0-9]*$/;

  // useEffect for disabling OTP button
  useEffect(() => {
    if (otp.length === 6)
      setDisabledOTP(false);
    else setDisabledOTP(true);
  }, [otp]);

  useEffect(() => {
    if (timeLeft > 0) {
      const countdownInterval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);

      return () => clearInterval(countdownInterval);
    } else if (timeLeft === 0) {
      setDisabledResend(false);
    }
  }, [timeLeft]);

  const handleResendOTP = async () => {
    setLoading(true);
    setTimeLeft(expireTime)
    setOtp('');
    await handleLogin();
    setDisabledResend(true);
    setLoading(false);
  };

  return (
    <>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography variant="h4"  marginBottom={2}>{t('login.trust_device')}</Typography>
        <Typography marginBottom={2}>{t('login.otp_desc')}</Typography>
        <FormField
          bigForm
          placeholder={t('login.password')}
          type="password"
          value={otp}
          maxLength={6}
          width="60%"
          marginBottom={1}
          onChange={(event: any) => {
            if (
              (onlyNumbers.test(event.target.value))
            ) {
              setOtp(event.target.value)
            }
          }}
        />
        <Box sx={{width: '60%', marginBottom: 2, paddingX: 1, display: 'flex', justifyContent: 'start'}}>
          <Countdown timeLeft={timeLeft}></Countdown>
        </Box>
        { disabledResend === true ? (
          <Button 
            bigBtn
            width="60%"
            color="darkBlue"
            loading={loading}
            disabled={loading || disabledOTP}
            onClick={handleVerifyOtp}>
            {t('general.btn.confirm')}
          </Button>
        ) : (
          <Button 
            bigBtn
            width="60%"
            color="darkBlue"           
            loading={loading}
            disabled={loading}
            onClick={handleResendOTP}>
            {t('general.btn.resend')}
          </Button>
        )}
        {/* <Stack sx={{width:"60%", gap: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}> */}
        {/* <Typography variant="h4">
                Time Left: {formatTime(timeLeft)}
              </Typography> */}
        {/* <Countdown color={"primary"} resetKey={resetKey} seconds={300}></Countdown>
              <Box sx={{display: "flex", gap: 1}}>
              <Button 
                  color="primary"
                  loading={loading}
                  disabled={loading}
                  onClick={handleResendOTP}>
                {t("general.btn.resend")}
                </Button>
                <Button 
                    color="primary"
                    loading={loading}
                    disabled={loading || disabledOTP}
                    onClick={handleVerifyOtp}>
                  {t("general.btn.confirm")}
                </Button>
              </Box>

            </Stack> */}
      </Box>
    </>
  );
};

export default LoginOTP;
