const dealerRequests = {
  header: 'Dealers List',
  dealer_requests: 'Dealers Requests',
  search_dealers: 'Search Dealers',
  confirm_modal_cancel: 'No, cancel',
  confirm_modal_confirm: 'Yes, delete',
  request_pending: 'Requests Pending',
  approved: 'Approved',
  pending: 'Pending',
  denied: 'Denied',
  in_review: 'In Review',
  approve: 'Approve',
  deny: 'Deny',
  review: 'Review',
  close: 'Close',
  all: 'All',
  submit: 'Submit',
  cancel: 'Cancel',
  update: 'Update',
  back_to_list: 'Back to list',
  edit: 'Edit',
  profile: 'Profile',
  history: 'History',
  email_notifications: 'Email Notifications',
  email_modal_description: 'This list of email will receive the dealer application',
  table: {
    company: 'COMPANY',
    full_name: 'FULL NAME',
    email: 'EMAIL',
    country: 'COUNTRY',
    date: 'DATE',
    status: 'STATUS'
  }
};

export default dealerRequests;
