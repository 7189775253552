import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useSelector } from '../../store';
import { Box, Stack, Typography } from '@mui/material';
import { titleCase } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { blue } from '@mui/material/colors';

const RolesTooltip = ({
  roles,
  entity,
  roleKey,
  entityKey,
  children,
  ...props
}: Omit<TooltipProps, 'title'> & {
  roles: any;
  entity: any;
  roleKey: any;
  entityKey: any;
}) => {
  const userInfo = useSelector((store: any) => store.user.user);
  const { t } = useTranslation();

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: userInfo.darkmode ? '#515151' : '#f9f9f9',
      color: userInfo.darkmode ? 'white' : 'black',
      border: '1px solid',
      borderColor: userInfo.darkmode ? '#404040' : '#cacdd9'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: userInfo.darkmode ? '#404040' : '#cacdd9'
    }
  }));

  const getPermActions = (perm: any): string => {
    let str = '';

    for (let i = 0; i < 8; i++) {
      const action = perm[`action${i + 1}`];
      if (action.active && action.label)
        str += `${t(`perm.${action.label}`)}, `;
    }

    str = str.substring(0, str.length - 2);

    return str.split(',').length === perm.enabled_action_qty
      ? `${t('perm.ALL_ACCESS')}.`
      : `${str}.`;
  };

  return (
    <CustomTooltip
      {...props}
      title={
        roles &&
        entity &&
        (entity[entityKey] === '' ? (
          <Typography>{t('roles.select_role_details')}</Typography>
        ) : (
          roles.map((role: any) => {
            if (role[roleKey] === entity[entityKey]) {
              return (
                <Stack gap={0.6} key={role[roleKey]}>
                  {role.permissions && role.permissions.length > 0 ? (
                    role.permissions
                      .sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                      .map((perm: any) => (
                        <Stack
                          key={`role_${perm.permission}`}
                          direction={'row'}
                          alignItems="center"
                          gap={1}
                        >
                          <Box
                            height={'4px'}
                            minWidth={'8px'}
                            borderRadius={5}
                            style={{ background: blue[500] }}
                          ></Box>
                          <Typography variant="body2">
                            {`${titleCase(perm.name)}: ${getPermActions(perm)}`}
                          </Typography>
                        </Stack>
                      ))
                  ) : (
                    <Typography>{t('roles.no_permissions')}</Typography>
                  )}
                </Stack>
              );
            }
            return null;
          })
        ))
      }
    >
      {children}
    </CustomTooltip>
  );
};

export default RolesTooltip;
