import {
  combineReducers,
  configureStore,
  PreloadedState
} from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducers
import UserReducer from './reducers/user';
import { controllerReadersReducer } from './reducers/controller-readers';
import { hardwareInputReducer } from './reducers/hardware-inputs';
import { hardwareOutputReducer } from './reducers/hardware-outputs';
import { sitesLockdownReducer } from './reducers/sites-lockdown';
import selectedSiteReducer from './reducers/selected-site';
import stripePromiseReducer from './reducers/stripe-promise';
import snackAlertReducer from './reducers/snack-alert';
import darkmodeReducer from './reducers/darkmode';
import supportSessionReducer from './reducers/support-session';
import controllerSdkReducer from './reducers/controller-sdk';

const rootReducer = combineReducers({
  user: UserReducer,
  controllerReaders: controllerReadersReducer,
  hardwareInputs: hardwareInputReducer,
  hardwareOutputs: hardwareOutputReducer,
  lockdown: sitesLockdownReducer,
  selectedSite: selectedSiteReducer,
  stripePromise: stripePromiseReducer,
  snackAlert: snackAlertReducer,
  darkmode: darkmodeReducer,
  supportSession: supportSessionReducer,
  controllerSdk: controllerSdkReducer
});

// Configure persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['darkmode', 'user'] // Reducers to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store =  configureStore({
  reducer: persistedReducer
});

// This one is used for testing
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState
  });
}

export const persistor = persistStore(store);

// Store types
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

// Hooks
export const useDispatch: () => AppDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
