const general = {
  new: 'Nouveau',
  active: 'Actif',
  inactive: 'Inactif',
  yes: 'Oui',
  no: 'No',
  day: 'Jour',
  days: 'Jours',
  hour: 'Heure',
  minute: 'Minute',
  second: 'Deuxième',
  and: 'Et',
  welcome: 'Bienvenu',
  online: 'En ligne',
  offline: 'Hors ligne',
  all_sites: 'Tous les sites',
  refresh: 'Rafraîchir',
  success: 'Succès',
  error: 'Erreur',
  time_out: 'Temps libre',
  kprog_error: 'Kprog Erreur',
  replacing: 'Redémarrage',
  from: 'Depuis',
  to: 'Pour',
  general: 'Général',
  confirmation: 'Confirmation',
  filters: 'Filtres',
  results: 'Résultats',
  loading: 'Le bourrage',
  features: 'Características',
  coming_soon: 'À venir',
  reason: 'Raison',
  alerts: 'Alertes',
  multisite: 'Multi-Sites',
  comments: 'Commentaires',
  leave_message: 'Êtes-vous sûr de vouloir quitter? Toutes les modifications non enregistrées seront perdues.',
  date_time: 'Date - Heure',
  quantity: 'Quantité',
  status: 'Statut',
  summary: 'Résumé',
  btn: {
    launch: 'Lancement',
    discontinue: 'Cesser',
    continue: 'Continuer',
    back: 'Retour',
    open: 'Ouverte',
    new: 'Nouveau',
    select: 'Sélectionner',
    selected: 'Choisi',
    view: 'Voir',
    delete: 'Effacer',
    update: 'Mise à jour',
    edit: 'Éditer',
    submit: 'Soumettre',
    confirm: 'Confirmer',
    next: 'Prochain',
    previous: 'Précédent',
    save: 'Sauvegarder',
    checkout: 'Vérifier',
    activate: 'Activer',
    activated: 'Activé',
    inactivate: 'Désactiver',
    inactivated: 'Désactivé',
    finish: 'Finir',
    add_card: 'Ajouter\'d',
    cancel: 'Annuler',
    close: 'Fermer',
    dismiss: 'Rejeter',
    add_user: 'Ajouter \'r',
    edit_user: 'Modifier l\'utilisateur',
    enable: 'Activer',
    disable: 'Désactiver',
    done: 'Fait',
    replace: 'Remplacer',
    remove: 'Retirer',
    reset: 'Réinitialiser à l\'état normal',
    firmware: 'Micrologiciel',
    lock: 'Verrouillage (annulation)',
    unlock: 'Déverrouiller (remplacement)',
    grant_access: 'Accorder l\'accès',
    grant_extend: 'Accès étendu',
    actions: 'Actions',
    enabled: 'Activé',
    disabled: 'Désactivée',
    enable_access: 'Activé accéder',
    disable_access: 'Désactivée accéder',
    access: 'Accéder',
    unassigned: 'Non Attribué',
    assigned: 'Attribué',
    register: 'S\'inscrire',
    change: 'Monnaie',
    refresh: 'Rafraîchir',
    restore: 'Restaurer',
    resend_mail: 'Ré-envoyer l\'email',
    send: 'Envoyer',
    skip: 'Sauter',
    test: 'Test',
    list_view: 'Vue en liste',
    chart_view: 'Vue graphique',
    default: 'Défaut',
    sign_in: 'Se Connecter',
    go_back: 'Retourner',
    now: 'Maintenant',
    later: 'Plus tard',
    resend: 'Renvoyer',
    add: 'Ajouter',
    reset_position: 'Réinitialiser la position',
    ok: 'OK'
  },
  form: {
    timezone: 'Fuseau horaire'
  },
  month: {
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Peut',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre'
  }
};

export default general;
