import React from 'react';

// MUI
import { Button, Menu, MenuItem, SxProps, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Project imports
import { useTranslation } from 'react-i18next';
import disableOptionByTag from '../../../utils/security/disableOptionByTag';
import disableOptionByValue from '../../../utils/security/disableOptionByValue';

interface props {
  title?: string;
  onActionClick: any;
  customActions?: any;
  customLabel?: string;
  customBgColor?: string;
  customColor?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  row?: any;
  id?: any;
  sx?: SxProps<Theme> | undefined;
}

const TableOptionsBtn = ({
  title,
  onActionClick,
  customActions,
  customLabel,
  customBgColor,
  customColor,
  fullWidth,
  disabled = false,
  row,
  id,
  sx
}: props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    action: string
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (onActionClick) {
      onActionClick(action);
    }
  };

  const actionsToShow = disableOptionByValue(
    disableOptionByTag(customActions),
    row
  );

  if (!actionsToShow || actionsToShow.length === 0) {
    return null;
  }

  return (
    <div>
      {/* Show the single button only if one action is passed on the custom actions
          and the action is not disabled / user has permission
      */}
      {customActions.length === 1 && actionsToShow.length === 1 ? (
        <Button
          variant="contained"
          disabled={disabled}
          style={{ background: customBgColor || grey[100] }}
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            handleActionClick(event, actionsToShow[0].action)
          }
          id={`table-option-button-${id ? id : ''}`}
        >
          <span style={{ color: customColor || grey[500] }}>
            {title || t(actionsToShow[0].title)}
          </span>
        </Button>
      ) : (
        <>
          <Button
            sx={{ ...sx }}
            variant="contained"
            fullWidth={fullWidth}
            style={{ background: customBgColor || grey[100] }}
            onClick={handleClick}
            disableElevation
            disabled={disabled}
            id={`table-options-${id ? id : ''}`}
          >
            <span style={{ color: customColor || grey[500] }}>
              {customLabel ? t(customLabel) : t('general.btn.actions')}
            </span>
            <KeyboardArrowDownIcon
              style={{ color: customColor || grey[500], width: '0.6em' }}
            />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={(event: React.MouseEvent<HTMLElement>) =>
              handleClose(event)
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            {actionsToShow.map((ele: any, i: number) => (
              <MenuItem
                key={ele.title}
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleActionClick(event, ele.action)
                }
                id={`menu-option-${i}`}
              >
                {t(ele.title)}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
};

export default TableOptionsBtn;
