import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import Avatar from '../../../components/Avatar/Avatar';
import routes from '../../../routes/routes';
import { useNavigate } from 'react-router-dom';
import ChangePassword from '../ChangePassword/ChangePasword';
import SwitchProfile from '../SwitchProfile/SwitchProfile';
import user from '../../../interfaces/user.interface';
import { useDispatch, useSelector } from '../../../store';
import { removeUser, setUser } from '../../../store/actions/user';
import avatar from '../../../assets/images/media/avatars/avatar.png';
import lngs from '../../lngs';
import { putPreferences } from '../../../api/api';
import useAlert from '../../../utils/hooks/useAlert';
import useDarkmode from '../../../utils/hooks/useDarkmode';
import UserInfoMenuContent from './UserInfoMenuContent';

const UserInfoButton = ({ setMenu }: { setMenu: (menu: any) => void }) => {
  const navigate = useNavigate();
  const user: user = useSelector((store) => store.user.user);
  const dispatch = useDispatch();
  const { errorAlert, successAlert } = useAlert();
  const { darkmode, toggleDarkmode } = useDarkmode();

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const location = useLocation();
  const { pathname } = location;
  const { t, i18n } = useTranslation();

  const isFloorPlan = () =>
    pathname.includes(routes.floor_plan_view.split(':')[0]);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const [openSwitch, setOpenSwitch] = useState(false);
  const [openChangepsswd, setOpenChangepsswd] = useState(false);

  const checkUserProfile = useCallback(() => {
    if (
      user.user_profiles.length > 1 &&
      !user.user_profiles.find((p: any) => p.dealer_org_role_id === user.role)
    ) {
      setOpenSwitch(true);
    }
  }, [user.user_profiles]);

  useEffect(() => {
    checkUserProfile();
  }, [checkUserProfile]);

  useEffect(() => {
    i18n.changeLanguage(user.language);
    // if (!darkModeEnabled) {
    //   user.darkmode === true && putDarkMode();
    // };
  }, []);

  const putDarkMode = async (dark: boolean) => {
    toggleDarkmode();
    const body = {
      darkmode: !dark
    };
    try {
      await putPreferences(body, user.user);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleChangeLang = async (lng: string) => {
    const body = {
      language: lng
    };
    try {
      await putPreferences(body, user.user);
      dispatch(setUser({ ...user, language: lng }));
      i18n.changeLanguage(lng);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseLangMenu = () => {
    setAnchorElUser(null);
    setAnchorElLang(null);
  };

  const handleSuccess = (msg: string) => {
    setOpenChangepsswd(false);
    successAlert(msg);
  };

  const handleActionClick = (
    setting: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    switch (setting) {
      case 'config.logout':
        dispatch(removeUser());
        darkmode === true && toggleDarkmode();
        break;

      case 'config.profile':
        navigate(routes.profile);
        break;

      case 'config.change_password':
        setOpenChangepsswd(true);
        break;

      case 'config.switch_profile':
        setOpenSwitch(true);
        break;

      case 'config.language':
        handleOpenLangMenu(e);
        break;
      case 'config.dealer_info':
        navigate(routes.dealer_info_view);
        break;
      case 'config.dark_mode':
        putDarkMode(darkmode);
        break;
    }
    setAnchorElUser(null);
    setAnchorElLang(null);
  };

  return (
    <>
      <Button
        color="transparent"
        paddingLeft="1em"
        paddingRight="1em"
        onClick={handleOpenUserMenu}
        id="navigation-bar-user-menu-button"
        disabled={isFloorPlan()}
        sx={{
          '&:disabled': {
            backgroundColor: null,
            color: 'darkgray'
          }
        }}
      >
        <Stack
          direction={matches ? 'row' : 'column'}
          alignItems="center"
          spacing={matches ? 2 : 0}
          marginTop={matches ? 0 : 1}
          textAlign={'left'}
        >
          <Avatar
            className="transparent-avatar"
            sx={{ width: 40, height: 40 }}
            variant="rounded"
            src={
              user.logo
                ? `data:image/png;base64,${user.logo}`
                : '/static/images/avatar/2.jpg'
            }
          >
            <img
              src={user.logo ? `data:image/png;base64,${user.logo}` : avatar}
              alt={user.first_name + ' ' + user.last_name + ' avatar'}
            />
          </Avatar>
          <Box>
            {matches && (
              <Typography component="div" variant="subtitle1">
                {t('general.welcome')}
              </Typography>
            )}
            <Typography component="div" variant="caption">
              {user.first_name} {user.last_name}
            </Typography>
          </Box>
        </Stack>
      </Button>
      <Menu
        PaperProps={{ style: { width: 275, maxWidth: '100%' } }}
        sx={{ mt: '50px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <UserInfoMenuContent
          user={user}
          handleActionClick={handleActionClick}
          setAnchorElLang={setAnchorElLang}
        />
      </Menu>
      <Menu
        PaperProps={{ style: { width: 150, maxWidth: '100%' } }}
        sx={{ mt: '50px', boxShadow: 1 }}
        id="menu-appbar"
        anchorEl={anchorElLang}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        open={Boolean(anchorElLang)}
        onClose={handleCloseLangMenu}
      >
        {Object.keys(lngs).map((lng) => (
          <MenuItem
            style={{ margin: 6 }}
            key={lng}
            onClick={() => handleChangeLang(lng)}
          >
            <Stack direction={'row'} gap={1.5}>
              <img
                width={15}
                src={lngs[lng].flag}
                alt={t(lngs[lng].nativeName)}
              />
              <Typography component="div" textAlign="center">
                {t(lngs[lng].nativeName)}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>

      {/* Modals dialogs */}
      <SwitchProfile
        open={openSwitch}
        onClose={() => setOpenSwitch(false)}
        setMenu={setMenu}
      />
      <ChangePassword
        open={openChangepsswd}
        onClose={() => setOpenChangepsswd(false)}
        onSuccess={(msg: string) => handleSuccess(msg)}
        onError={(msg: string) => errorAlert(msg)}
      />
    </>
  );
};

export default UserInfoButton;
