import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// MUI
import { Stack, Box, Typography, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// interfaces
import user from '../../../../interfaces/user.interface';
import { payment } from '../../interfaces/payment.interface';
// Imports
import Button from '../../../../components/Button/Button';
import AddPaymentModal from '../AddPaymentModal/AddPaymentModal';
import { deletePayment, getPayment } from '../../../../api/api';
import { useSelector } from '../../../../store';
import { green, grey } from '@mui/material/colors';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import CardIcon from '../../../../assets/images/dashboard_icons/tarjeta30_1.png';
import useAlert from '../../../../utils/hooks/useAlert';

interface props {
  payment_method: string;
  setPayment_method: (s: string) => void;
  previous?: () => void;
  checkout?: () => void;
  addCard?: boolean;
  displayTitle?: boolean;
  fullWidth?: boolean;
}

const PaymentList = ({
  payment_method,
  setPayment_method,
  previous,
  checkout,
  addCard = true,
  displayTitle = true,
  fullWidth
}: props) => {
  const { t } = useTranslation();
  const user: user = useSelector((store) => store.user.user);
  const { errorAlert, successAlert } = useAlert();

  const [payments, setPayments] = useState<Array<payment>>([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    fetchPayments();
  }, []);

  const payment = payments.find((p) => p.payment_method === payment_method);

  const fetchPayments = async () => {
    try {
      const { status, methods } = await getPayment(
        user.selectedDealer?.dealer || user.currentProfile
      );
      if (status === 200) {
        setPayments(
          methods.sort((a: any, b: any) => b.is_default - a.is_default)
        );
        setPayment_method(
          methods.length > 0 ? methods[0].payment_method : undefined
        );
      }
    } catch (error: any) {
      console.error(error.response?.data.message);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const { status, message } = await deletePayment(id);
      if (status === 200) {
        successAlert(message)
        fetchPayments();
      }
    } catch (error: any) {
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  const handleSelectPayment = (payment_method: string) => {
    setPayment_method(payment_method);
  };

  return (
    <>
      {displayTitle && (
        <Stack direction="row" gap={1} mb={1}>
          <Box alignSelf="center">
            <img src={CardIcon} width={20} alt="Cards" />
          </Box>
          <Typography component="h4" variant="h4">
            {t('organization.payment_methods')}
          </Typography>
        </Stack>
      )}
      <Box marginBottom={2}>
        <Grid container spacing={2}>
          {payments.map((p) => (
            <Grid xs={12} sm={fullWidth ? 12 : 6} item>
              <Box
                onClick={() => handleSelectPayment(p.payment_method)}
                padding={2}
                paddingBottom={0.5}
                sx={{
                  border: `0.1em solid ${grey[300]}`,
                  borderRadius: 5,
                  cursor: 'pointer',
                  background:
                    p.payment_method === payment_method ? grey[100] : ''
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography>{p.name_on_card}</Typography>
                  {p.is_default && (
                    <Typography
                      sx={{
                        background: green[50],
                        color: green[500],
                        padding: '0 0.5em',
                        borderRadius: 2
                      }}
                    >
                      Default
                    </Typography>
                  )}
                </Stack>
                <Stack justifyContent="space-between">
                  <Typography>
                    {p.brand} **** {p.last4}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography>
                    {t('organization.expires_at')} {p.exp_month}/{p.exp_year}
                  </Typography>
                  <Box>
                    {/* <IconButton onClick={handleEdit}>
                      <EditIcon sx={{ fontSize: 16 }} />{" "}
                      <Typography>{t("general.btn.edit")}</Typography>
                    </IconButton> */}
                    <IconButton onClick={() => setShowConfirmModal(true)}>
                      <DeleteIcon sx={{ fontSize: 16 }} />
                      <Typography>{t('general.btn.delete')}</Typography>
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Stack
        gap={1}
        direction="row"
        justifyContent={previous && checkout ? 'space-between' : 'end'}
      >
        <Stack gap={1} direction="row" justifyContent="end">
          {previous && (
            <Button color="gray" onClick={previous}>
              {t('general.btn.previous')}
            </Button>
          )}
          {addCard && (
            <Button onClick={() => setShowPaymentModal(true)}>
              {t('general.btn.add_card')}
            </Button>
          )}
        </Stack>
        {checkout && (
          <Button onClick={checkout}>{t('general.btn.checkout')}</Button>
        )}
      </Stack>
      {/* Add Payment modal */}
      <AddPaymentModal
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        onSuccess={() => fetchPayments()}
      />
      {/* Confirmation for delete */}
      <ConfirmationModal
        icon
        message={`${t('organization.modal.delete_payment_description')} ${
          payment?.brand + ' **** ' + payment?.last4
        }?`}
        cancelBtnTxt={t('users.confirm_modal_cancel')}
        submitBtnTxt={t('users.confirm_modal_confirm')}
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={() => {
          handleDelete(payment_method);
          setShowConfirmModal(false);
        }}
      />

    </>
  );
};

export default PaymentList;
