import { Box, Grow, Typography } from '@mui/material';
import React from 'react';
// Imports
import InputMask from 'react-input-mask';
import InfoIconLabel from '../InfoIconLabel/InfoIconLabel';
import RequiredFieldIndicator from '../RequiredFieldIndicator/RequiredFieldIndicator';

interface props {
  mask: string | (string | RegExp)[];
  value: string | number;
  onChange: (a: any) => void;
  label?: string;
  placeholder?: string;
  width?: string;
  marginTop?: number;
  marginBottom?: number;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  info?: string;
  error?: boolean;
  errorText?: string;
  maxLength?: number;
  rows?: number;
  pattern?: string;
  bigForm?: boolean;
  fontSize?: number;
  maskPlaceholder?: string | null;
  onBlur?: (a: any) => void;
}

const MaskedInput = ({
  mask,
  value,
  onChange,
  label,
  placeholder,
  width,
  marginTop,
  marginBottom,
  disabled,
  required,
  info,
  error,
  errorText,
  maskPlaceholder = '_',
  fontSize,
  onBlur
}: props) => (
  <Box
    width={width ? width : '100%'}
    sx={{ flex: 1 }}
    marginTop={marginTop ? marginTop : 0}
    marginBottom={marginBottom ? marginBottom : 3.5}
  >
    {label && (
      <Box
        minHeight={20}
        position="relative"
        sx={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}
      >
        <Typography
          fontWeight="bold"
          sx={{
            marginLeft: '0.25em',
            color: disabled ? 'lightgray' : null,
            fontSize: '0.75em'
          }}
        >
          {label}
          {required && <RequiredFieldIndicator disabled={disabled} />}
          {info && <InfoIconLabel sx={{ marginTop: -1 }} info={info} />}
        </Typography>
      </Box>
    )}
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      style={{
        fontSize: fontSize? fontSize : 14,
        height: '2.5em',
        borderRadius: '0.5em',
        width: '100%',
        backgroundColor: '#0000000D',
        padding: '0.6em',
        border: 'none',
        marginTop: 3,
        outline: error ? '2px solid #f44336' : 'none'
        //   "&:focus": {
        //     border: "none",
        //   },
      }}
      maskPlaceholder={maskPlaceholder}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={disabled}
    ></InputMask>
    {errorText && (
      <Grow
        in={error}
        timeout={400}
        unmountOnExit
        style={{
          height: '0.25em',
          marginBottom: '-0.25em',
          transformOrigin: '0 0 0'
        }}
      >
        <Typography color="#f44336" marginLeft={1} fontSize="small">
          {errorText}
        </Typography>
      </Grow>
    )}
  </Box>
);

export default MaskedInput;
