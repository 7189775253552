import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import MaterialTable from '../MaterialTable/MaterialTable';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getOrganizationAlarms, getDealerAlarms } from '../../api/api';
import {
  alertsTableHeaders,
  alertsTableHeadersMini,
  dealerHeaders
} from './alertsTableHeaders';
import { io } from 'socket.io-client';
import constant from '../../api/constants';
import { postKeepChannel } from '../../api/api';
import useInterval from 'use-interval';
import { Alarm, SocketData } from './interfaces';
import DescriptionModal from './DescriptionModal';
import user from '../../interfaces/user.interface';
import { useDispatch, useSelector } from '../../store';
import { useNavigate } from 'react-router-dom';
import { changeProfile } from '../../store/actions/user';
import routes from '../../routes/routes';
import moment from 'moment';

interface AlertsTableProps {
  _organization?: string;
  _site?: string;
  _dealer?: string;
}

const socket = io(
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? constant.SOCKET_BASE_URL
    : constant.SOCKET_PROD_URL,
  {
    reconnection: true,
    path: '/app/ws',
    transports: ['websocket']
  }
);

const parseAlarm = (data: Alarm): Alarm => {
  return {
    ...data,
    alarmed_at: moment(data.alarmed_at.replace('Z', '')).format(
      'MM/DD/YYYY    hh:mm:ss a'
    )
  };
};

const parseAlarmsArray = (data: Alarm[]): Alarm[] => {
  return data.map((alarm) => parseAlarm(alarm));
};

const initAlarms: Alarm[] = [
  {
    entity_alarm: '',
    description: '',
    entity_serial_number: '',
    entity_id: 0,
    object_type: '',
    controller: '',
    controller_serial_number: '',
    controller_name: '',
    organization: '',
    organization_name: '',
    site: '',
    site_name: '',
    alarmed_at: '',
    is_missing: false,
    is_forced: false,
    is_alarm: false,
    is_tamper: false,
    is_trouble: false,
    entity_associated_name: '',
    entity_associated_uuid: '',
    type_alarm: '',
    event_id: 0,
    event_name: '',
    status: 0,
    status_text: '',
    multi_site: false,
    trouble_count: 0
  }
];

const AlertsTable = ({ _organization, _site, _dealer }: AlertsTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const user: user = useSelector((store) => store.user.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [alarms, setAlarms] = useState(initAlarms);
  const [selectedAlarm, setSelectedAlarm] = useState<Alarm | null>(null);

  const channel = useMemo(() => {
    if (_dealer) return `dealer-alert/${_dealer}`;

    if (_site) {
      return `site-alert/${_site}`;
    } else {
      return `account-alert/${_organization}`;
    }
  }, [_organization, _site, _dealer]);

  // Use Interval to keep the channel alive
  useInterval(
    () => {
      postKeep();
    },
    30000,
    true
  );

  const postKeep = async (): Promise<any> => {
    try {
      await postKeepChannel(channel);
    } catch (error: any) {
      console.error(error.response.status);
    }
  };

  const handleSocketDataGeneric = useCallback(
    (data: SocketData, comparisonFn: (alarm: Alarm) => boolean) => {
      const { alarm_info, status } = data;
      const parsedAlarm = parseAlarm(alarm_info);

      if (status === 'restored') {
        setAlarms((prevAlarms) => {
          return prevAlarms.filter((alarm) => !comparisonFn(alarm));
        });
      } else {
        setAlarms((prevAlarms) => {
          const index = prevAlarms.findIndex(comparisonFn);
          if (index === -1) {
            return [parsedAlarm, ...prevAlarms];
          } else {
            const newAlarms = [...prevAlarms];
            newAlarms[index] = parsedAlarm;
            return newAlarms;
          }
        });
      }
    },
    []
  );

  const handleDealerSocketData = useCallback(
    (data: SocketData) => {
      const { site, organization } = data;

      handleSocketDataGeneric(
        data,
        (alarm) => alarm.site === site && alarm.organization === organization
      );
    },
    [handleSocketDataGeneric]
  );

  const handleSocketData = useCallback(
    (data: SocketData) => {
      const { entity_alarm, alarm_info } = data;

      handleSocketDataGeneric(
        data,
        (alarm) => alarm.entity_alarm === entity_alarm
      );

      if (selectedAlarm?.entity_alarm === entity_alarm) {
        setSelectedAlarm(alarm_info);
      }
    },
    [selectedAlarm, handleSocketDataGeneric]
  );

  useEffect(() => {
    if (_dealer) {
      socket.on(channel, handleDealerSocketData);
    } else {
      socket.on(channel, handleSocketData);
    }

    return () => {
      // Unsubscribe from both events to avoid memory leak
      socket.off(channel, handleSocketData);
      socket.off(channel, handleDealerSocketData);
    };
  }, [channel, handleSocketData, _dealer, handleDealerSocketData]);

  const tableHeaders = useMemo(() => {
    if (_dealer) {
      // TODO: Check for matches to show mobile headers for dealers
      return dealerHeaders;
    } else {
      return matches
        ? alertsTableHeaders.slice(!!_site ? 1 : 0)
        : alertsTableHeadersMini;
    }
  }, [_site, _dealer, matches]);

  const fetchOrgAlarms = useCallback(async () => {
    try {
      const { alarms } = await getOrganizationAlarms(_organization!, _site);
      setAlarms(parseAlarmsArray(alarms));
    } catch (error) {
      console.log('🚀 ~ fetchOrgAlarms ~ error:', error);
    }
  }, [_organization, _site]);

  const fetchDealerAlarms = useCallback(async () => {
    try {
      const { alarms } = await getDealerAlarms(_dealer!);
      setAlarms(parseAlarmsArray(alarms));
    } catch (error) {
      console.log('🚀 ~ fetchDealerAlarms ~ error:', error);
    }
  }, [_dealer]);

  useEffect(() => {
    if (!!_dealer) {
      fetchDealerAlarms();
    } else {
      fetchOrgAlarms();
    }
  }, [_dealer, fetchDealerAlarms, fetchOrgAlarms]);

  const handleRowClick = (row: Alarm) => {
    if (_dealer) {
      dispatch(
        changeProfile(
          user.permissions,
          user.role,
          user.role_name,
          user.currentProfile,
          user.profile_type,
          {
            organization: row.organization,
            name: row.organization_name,
            multi_site: row.multi_site
          },
          user.selectedDealer
        )
      );

      navigate(routes.organization_info);
    } else {
      setSelectedAlarm(row);
    }
  };

  return (
    <Box id="alerts-table-container">
      <Box
        p={1}
        borderRadius={2}
        mx={{ xs: 2, sm: 0 }}
        mb={1}
        sx={{ color: 'white', background: theme.palette.primary.dark }}
      >
        <Typography>{t('general.alerts')}</Typography>
      </Box>
      <Box
        sx={{ background: theme.palette.background.paper }}
        id="alerts-table"
      >
        {alarms?.length === 0 ? (
          <Typography component="h2" variant="h2" p={2}>
            {t('dealers.no_alerts_reported')}
          </Typography>
        ) : (
          <MaterialTable
            removeToolBar
            headCells={tableHeaders}
            onRowClick={handleRowClick}
            rows={alarms || []}
            id="alerts-table-content"
          />
        )}
      </Box>
      <DescriptionModal
        open={!!selectedAlarm}
        onClose={() => setSelectedAlarm(null)}
        alarm={selectedAlarm!}
      />
    </Box>
  );
};

export default AlertsTable;
