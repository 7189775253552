const doors = {
  doors: 'Des portes',
  search_door: 'Porte de recherche',
  search_event: 'Rechercher un événement',
  configuration: 'Configuration',
  events: 'Événements',
  notes: 'Remarques',
  basic: 'Basique',
  advanced: 'Avancé',
  side_b: 'Côté B',
  side_a: 'Face A',
  options: 'Options',
  schedule: 'Calendrier',
  timing: 'Chronométrage',
  user_location: 'Emplacement de l\'utilisateur',
  update_user_location: 'Mettre à jour l\'emplacement de l\'utilisateur sur',
  timing_user_location: 'Emplacement de l\'utilisateur de synchronisation activé',
  edit: 'Modifier',
  edit_basic: 'Modifier-Basique',
  edit_advanced: 'Modifier-Avancé',
  door_commands: 'Commandes de porte',
  legend_title: 'Légende',
  restore_tamper_alarm: 'Rétablir l\'alarme anti-sabotage',
  no_tampered_msg: 'La porte n\'est pas forcée',
  lockdown_grant: 'Verrouiller l\'accès aux autorisations',
  no_lockdown_msg: 'La porte n\'est pas verrouillée',
  total_active_doors: 'Total des portes actives',
  form: {
    serial: 'En série',
    door: 'Porte',
    active: 'Actif',
    state: 'État',
    lock_status: 'État de verrouillage',
    access_status: 'Statut d\'accès',
    grant_access: 'Accorder l\'accès',
    disable_access: 'Désactiver l\'accès',
    type_here: 'Écrivez ici',
    name: 'Nom',
    controller_name: 'Nom du contrôleur',
    unlock_schedule: 'Déverrouiller le calendrier',
    unlock_first: 'Déverrouiller au premier accès/premier homme entré',
    relock_door_open: 'Reverrouille porte ouverte',
    relock_door_closed: 'Reverrouille porte fermée',
    left_open: 'Peut rester ouvert',
    double_swipe: 'Double balayage',
    access_granted: 'Accès autorisé',
    doors_open: 'Portes ouvertes',
    unlock_time: 'Temps de déverrouillage (secondes)',
    extended_unlock: 'Déverrouillage étendu (secondes)',
    open_prealarm: 'Ouvrir les pré-alarmes trop longues (secondes)',
    open_alarm: 'Ouvrir les alarmes trop longues (secondes)',
    rex: 'REX',
    reader: 'Lecteur',
    contact_input: 'Entrée de contact',
    lock: 'Serrure',
    cards: 'Certificats',
    pin: 'et épingler',
    keypad_code: 'Code clavier',
    door_open: 'Et même si porte ouverte',
    interlock_side: 'Interverrouillage de ce côté',
    two_man_rule: 'Règle de deux hommes (chape)',
    pin_schedule: 'Calendrier des épingles',
    rex_schedule: 'Calendrier REX',
    unlock_options: 'Options de déverrouillage'
  },
  table: {
    serial: 'EN SÉRIE',
    door: 'PORTE',
    active: 'ACTIF',
    state: 'ETAT',
    lock_status: 'STATUT VERROUILLAGE',
    access_status: 'STATUT D\'ACCES',
    grant_access: 'ACCÈS ACCORDÉ',
    disable_access: 'DÉSACTIVER L\'ACCÈS',
    date_time: 'DATE/HEURE',
    description: 'LA DESCRIPTION',
    object: 'CHOSE',
    instigator: 'INSTIGATEUR'
  },
  legend: {
    closed: 'Fermé',
    pre_alarm: 'Pré-alarme',
    alarm: 'Alarme',
    locked: 'Fermé à clé',
    unlocked: 'Débloqué',
    unlocked_access: 'Déverrouillé pour l\'accès',
    access_disabled: 'Accès désactivé',
    trouble: 'Difficulté',
    open: 'Ouvrir',
    alarm_forced: 'En alarme forcée',
    locked_operator: 'Verrouillé par l\'opérateur',
    unlocked_operator: 'Déverrouillé par l\'opérateur',
    access_enabled: 'Accès activé',
    unknow: 'Inconnu',
    door_tampered: 'En alarme altérée'
  }
};

export default doors;
