// Interfaces

import { site } from '../../views/OrganizationAdmin/Sites/interfaces/site.interface';

// Actions
export const SET_SITE = 'SET_SITE';
export const REMOVE_SITE = 'REMOVE_SITE';

// Action creators
export const setSelectedSite = (site: site | undefined) => ({
  type: SET_SITE,
  site: site
});

// export const removeUser = () => ({
//   type: REMOVE_SITE,
// });
