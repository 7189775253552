import flagEn from './../assets/images/media/flags/united-states.svg';
import flagEs from './../assets/images/media/flags/spain.svg';
import flagFr from './../assets/images/media/flags/france.svg';

const lngs: any = {
  en: { nativeName: 'English', flag: flagEn },
  es: { nativeName: 'Español', flag: flagEs },
  fr: { nativeName: 'Français', flag: flagFr }
};

export default lngs;
