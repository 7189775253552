import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { getUserDashboard } from '../../api/api';

import employees from '../../assets/images/dashboard_icons/employees2.png';
import cardIcon from '../../assets/images/dashboard_icons/card.png';
import organizationImg from '../../assets/images/dashboard_icons/organization30.png';

import user from '../../interfaces/user.interface';
import { useDispatch, useSelector } from '../../store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes/routes';
import MyPlanTabs from './MyPlanTabs/MyPlanTabs';
import { changeProfile } from '../../store/actions/user';
import TotalDoorsCard from '../../components/TotalDoorsCard/TotalDoorsCard';
import { setSelectedSite } from '../../store/actions/selected-site';
import AlertsTable from '../../components/AlertsTable/AlertsTable';
import { WithPermission } from '../../components/WithPermission/WithPermission';
import { Summary } from '../../interfaces/summary.interface';
import DashboardCard from '../../components/DashboardCard/DashboardCard';

const initSummary: Summary = {
  summary: {
    organizations: 0,
    dealers: 0,
    sites: 0,
    controllers: 0,
    doors: 0,
    users: 0,
    credentials: 0,
    lockdown_enabled: 2,
    lockdown_disabled: 0,
    has_subscription: false,
    has_payment_method: false
  },
  online_controllers: 0,
  offline_controllers: 0,
  events_organization: [],
  offline_controllers_list: [
    {
      controller: '',
      serial: '',
      dealer_name: '',
      organization_name: '',
      offline_at: ''
    }
  ]
};

const getFontSize = (length: number) => {
  if (length <= 3) return 34;
  if (length <= 4) return 28;
  return 24;
};

interface props {
  _dealer?: string;
  _organization?: string;
}

const DashboardDealer = ({ _organization, _dealer }: props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user: user = useSelector((store) => store.user.user);
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [summary, setSummary] = useState<Summary>(initSummary);
  const [loading, setLoading] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);

  useEffect(() => {
    fetchDashboard();
    clearAccount();
  }, []);

  const clearAccount = () => {
    dispatch(
      changeProfile(
        user.permissions,
        user.role,
        user.role_name,
        user.currentProfile,
        user.profile_type,
        null,
        user.selectedDealer
      )
    );
    dispatch(setSelectedSite(undefined));
  };

  const fetchDashboard = async () => {
    setLoading(true);
    try {
      const { status, summary } = await getUserDashboard(_dealer);
      status === 200 && setSummary(summary);
      console.log(summary)
      setOpenPlan(
        !summary.summary.has_payment_method &&
        !_organization &&
        !_dealer &&
        user.profile_type === 'dealer'
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const addEmployee = () => {
    !summary.summary.has_payment_method
      ? setOpenPlan(true)
      : navigate(`${routes.dealer_info_view}?add_employee=true`);
  };

  const goToMyplan = () => {
    !summary.summary.has_payment_method
      ? setOpenPlan(true)
      : navigate(`${routes.dealer_info_view}`);
  };

  return (
    <Box maxWidth="100em" width="100%">
      <Stack
        direction="row"
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        alignContent={'center'}
        flexWrap="wrap"
        mb={2}
        mt={{ xs: 2, sm: 0 }}
        gap={2}
      >
        <Stack
          direction="row"
          justifyContent={{ xs: 'center', sm: 'start' }}
          flexWrap="wrap"
          gap={2}
        >
          {summary?.summary?.organizations === 0 && !loading ? (
            <WithPermission actions={['account-add']}>
              <DashboardCard
                width={170}
                height={95}
                src={organizationImg}
                title={t('organization.add_organization')}
                action={() =>
                  !summary.summary.has_payment_method
                    ? setOpenPlan(true)
                    : navigate(routes.organization_create)
                }
              />
            </WithPermission>
          ) : (
            <WithPermission actions={['account-view']}>
              <DashboardCard
                width={170}
                height={95}
                src={organizationImg}
                title={t('organization.organizations')}
                numberSize={getFontSize(String(summary.summary.organizations || 0).length)}
                number={Number(summary.summary.organizations || 0)}
                action={() => navigate(routes.organization_list)}
              />
            </WithPermission>
          )}
          <WithPermission actions={['homedealer-employee']}>
            <DashboardCard
              width={170}
              height={95}
              src={employees}
              title={t('dealers.your_employees_info')}
              action={addEmployee}
            />
          </WithPermission>
          <WithPermission actions={['homedealer-profile']}>
            <DashboardCard
              width={170}
              height={95}
              src={cardIcon}
              title={t('dealers.your_dealer_info')}
              action={goToMyplan}
            />
          </WithPermission>
        </Stack>
        {summary?.summary?.organizations !== 0 &&
          <TotalDoorsCard doors={summary.summary.doors} />
        }
      </Stack>
      {matches && summary?.summary?.organizations !== 0 && <AlertsTable _dealer={user.currentProfile} />}
      <MyPlanTabs
        open={openPlan}
        onClose={() => setOpenPlan(false)}
        hasPayment={summary?.summary?.has_payment_method}
        finish={() => {
          setOpenPlan(false);
          setSummary({
            ...summary,
            summary: {
              ...summary.summary,
              has_payment_method: true,
              has_subscription: true
            }
          });
        }}
      />
    </Box>
  );
};

export default DashboardDealer;
