const roles = {
  header: 'Roles List',
  view_role: 'View Role',
  edit_role: 'Edit Role',
  add_role: 'Add a Role',
  update_role: 'Update a Role',
  add_new_role: 'Add New Role',
  admin_access: 'Administrator Access',
  cancel: 'Cancel',
  submit: 'Submit',
  total_users: 'Total users with this role',
  menu_details: 'Menu Details',
  permission_details: 'Permission Details',
  search_role: 'Search Role',
  select_role_details: 'Select a role to see details',
  no_permissions: 'No permissions set up for this role.',
  table: {
    name: 'Name',
    description: 'Description',
    active: 'Active',
    users: 'Users',
    admin: 'Admin'
  },
  form: {
    role_name: 'Role Name',
    allow_full_access: 'Allows a full access to the system',
    roles_permission: 'Roles Permissions',
    select_all: 'Select All',
    view: 'View',
    write: 'Write',
    create: 'create',
    delete: 'delete'
  },
  error: {},
  roleType: {
    label: 'Role Type',
    admin: 'System Admin',
    dealer: 'Dealer',
    organization: 'Account'
  },
  defaultRole: 'Default Role'
};

export default roles;
