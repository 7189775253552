const lockdown = {
  lockdown: 'Aislamiento',
  lockdown_options: 'Opciones de bloqueo',
  start_lockdown: 'Iniciar bloqueo',
  stop_lockdown: 'Detener el bloqueo',
  lockdown_started_msg: '(Se inició el bloqueo del sistema)',
  start_lockdown_message:
    'Para iniciar el bloqueo del sitio - {{site}}, escriba su contraseña y luego presione confirmar.',
  stop_lockdown_message:
    'Para detener el bloqueo del sitio - {{site}}, escriba su contraseña y luego presione confirmar.',
  settings_modal_tile: 'Bloqueo - Editar {{site}}',
  start_options: 'Opciones de inicio',
  stop_options: 'Detener opciones',
  login_required: 'Se requiere contraseña de inicio de sesión',
  card_pin_options: 'Opciones de credenciales y PIN',
  unavailable_lockdown: 'No disponible durante el bloqueo',
  confirm_start_lockdown:
    '¿Está seguro de que quieres iniciar el bloqueo del sitio {{site}}?',
  confirm_stop_lockdown:
    '¿Está seguro de que quieres detener el bloqueo del sitio {{site}}?',
  pin_option: {
    single_swipe: 'un solo golpe',
    double_swipe: 'Se requiere doble deslizamiento',
    two_men: 'Se requiere el gobierno de dos hombres'
  }
};

export default lockdown;
