import React from 'react';
// Door Icons
import doorOpen from '../../assets/images/doors/door_open.png';
import doorClosed from '../../assets/images/doors/door_inactive.png';
import doorPrealarm from '../../assets/images/doors/door_prealarm.png';
import doorForced from '../../assets/images/doors/door_forced.png';
import doorTampered from '../../assets/images/doors/door_tampered.png';
import doorLockdown from '../../assets/images/doors/door_lockdown.png';
// Access icons
import accessDisabled from '../../assets/images/doors/access_disabled.png';
import accessEnabled from '../../assets/images/doors/access_enabled.png';
// Lock Status
import lockOperator from '../../assets/images/doors/locked_blue.png';
import unlockOperator from '../../assets/images/doors/unlocked_blue.png';
import locked from '../../assets/images/doors/locked_gray.png';
import unlocked from '../../assets/images/doors/unlocked_green.png';
import unlockedAccess from '../../assets/images/doors/unlocked_green.png';
import unlockedGrant from '../../assets/images/doors/unlocked.png';
// others
import unknow from '../../assets/images/doors/unknow.png';
import warning from '../../assets/images/doors/warning.png';
// Material
import { Box } from '@mui/material';
import icon from '../../interfaces/icon.interface';

interface props {
  state: icon['state'];
  width?: number;
  id?: string;
}

const getImg = (key: string) => {
  switch (key) {
  case 'open':
    return doorOpen;
  case 'closed':
    return doorClosed;
  case 'prealarm':
    return doorPrealarm;
  case 'forced':
    return doorForced;
  case 'tampered':
    return doorTampered;
  case 'accessDisabled':
    return accessDisabled;
  case 'accessEnabled':
    return accessEnabled;
  case 'lockOperator':
    return lockOperator;
  case 'unlockOperator':
    return unlockOperator;
  case 'locked':
    return locked;
  case 'unlocked':
    return unlocked;
  case 'unlockedAccess':
    return unlockedAccess;
  case 'unknow':
    return unknow;
  case 'warning':
    return warning;
  case 'unlockedGrant':
    return unlockedGrant;
  case 'lockdown':
    return doorLockdown;
  }
};

const DoorStateIcon = ({ state, width, id }: props) => {
  return (
    <Box maxWidth={width || 30} id={id}>
      <img className={state === 'tampered' ? 'blink' : ''} width="100%" src={getImg(String(state))} alt="Door state"/>
    </Box>
  );
};

export default DoorStateIcon;
