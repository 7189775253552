import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from '../../../../store';
import { Box, CircularProgress, Grid, Paper } from '@mui/material';
import {
  deleteDealerUserAssignAccount,
  getDealerUserDetails,
  getOrganizationDealer,
  getSitesByOrganization,
  putDealerUserEditSites
} from '../../../../api/api';
import AddUserModal from '../DealerUsers/AddUser/AddUserModal';
import user from '../../../../interfaces/user.interface';
import EmployeeCard from './EmployeeCard/EmployeeCard';
import AssignAccount from './AssignAccount/AssignAccount';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import EditSites from './EditSites/EditSites';
import useAlert from '../../../../utils/hooks/useAlert';
import { WithPermission } from '../../../../components/WithPermission/WithPermission';

let selectedAccount: any = null;

const ViewEmployee = () => {
  const { _employee } = useParams();
  const { t } = useTranslation();
  const userState: user = useSelector((store) => store.user.user);
  const dealerId = userState.selectedDealer?.dealer || userState.currentProfile;
  const { errorAlert, successAlert } = useAlert();

  const [user, setUser] = useState<any>(null);
  // const [account, setAccount] = useState<any>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditSites, setShowEditSites] = useState(false);
  const [accountList, setAccountList] = useState<any[]>([]);
  const [siteList, setSiteList] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const returnSiteList = useMemo(() => {
    return (
      siteList.find(
        (s) => s[0].organization.organization === selectedAccount?.organization
      ) || []
    );
  }, [selectedAccount]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await getOrganizations();
    // await fetchUserImage();
  };

  const fetchUser = async (organizations: any) => {
    try {
      setLoading(true);
      const { dealer_user } = await getDealerUserDetails(String(_employee));
      //   const { user_org_img } = await getUserorgImage(String(_user));
      setUser({
        ...dealer_user,
        photo: ''
        //   `data:image/png;base64,${user_org_img}`,
      });
      const accounts = dealer_user.dealer_u_organizations?.map(
        (a: any) => a.organization
      );
      setSelected(
        organizations.filter((a: any) => accounts?.includes(a.organization))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getOrganizations = async () => {
    try {
      const { status, organizations } = await getOrganizationDealer(
        String(dealerId),
        'all',
        ''
      );
      if (status === 200) {
        setAccountList(
          organizations.map((o: any) => {
            return {
              ...o,
              site_title: !o.multi_site
                ? t('organization.single_site')
                : t('general.all_sites')
            };
          })
        );
        await getSitesByOrg(organizations);
        fetchUser(organizations);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const getSitesByOrg = async (organizations: any) => {
    try {
      const list = await Promise.all(
        organizations.map(
          async (org: any) =>
            await getSitesByOrganization(String(org.organization))
        )
      );
      const sitesTemp = list.map((l) => l.sites);
      setSiteList(sitesTemp);
    } catch (error: any) {
      console.error(error);
    }
  };

  const removeAccess = async (organization: string) => {
    try {
      const { status, message } = await deleteDealerUserAssignAccount(
        String(_employee),
        organization
      );
      if (status === 201) {
        setSelected((s) => s.filter((a) => a.organization !== organization));
        successAlert(message);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      selectedAccount = null;
      setShowConfirmModal(false);
    }
  };

  const handleUpdateUser = (newUserInfo: any) => {
    setUser({ ...newUserInfo });
  };

  const handleAssignSuccess = (obj: any) => {
    const { msg, selected: newList } = obj;
    setSelected((s) => s.concat(newList));
    successAlert(msg);
  };

  const successEditSites = async (selectedSites: any[]) => {
    try {
      const body = {
        organization: selectedAccount?.organization,
        sites: selectedSites,
        all_sites: returnSiteList?.length === selectedSites?.length,
        site_title:
          returnSiteList?.length === selectedSites?.length
            ? t('general.all_sites')
            : returnSiteList
                .filter((s: any) => selectedSites.includes(s.site))
                .map((s: any) => s.name)
                .join(',')
      };
      const { status, message } = await putDealerUserEditSites(
        String(_employee),
        body
      );
      if (status === 201) {
        successAlert(message);
        setSelected(
          selected.map((a: any) =>
            a.organization === body.organization ? { ...a, ...body } : { ...a }
          )
        );
        setShowEditSites(false);
      }
    } catch (error: any) {
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  //  if loading show progress
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="20em"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <WithPermission actions={['homedealer-employee']}>
      <Grid
        container
        direction={'row'}
        columnSpacing={4}
        rowSpacing={4}
        paddingTop={4}
      >
        <Grid item xs={12} md={4}>
          {user && (
            <EmployeeCard
              user={user}
              dealer={dealerId}
              setUser={(userInfo: any) => {
                handleUpdateUser(userInfo);
              }}
              clearList={() => {}}
              openEdit={() => setShowAddUserModal(true)}
            />
          )}
        </Grid>
        {/* Security tag check */}
        <Grid item xs={12} md={8}>
          {user && (
            <Paper sx={{ borderRadius: 4, p: 2 }}>
              <AssignAccount
                user={user}
                setUser={(userInfo: any) => {
                  handleUpdateUser(userInfo);
                }}
                clearList={() => setSelected([])}
                // open={showAssignAccount}
                accountList={accountList}
                selectedAccounts={selected}
                siteList={siteList}
                _employee={String(_employee)}
                onClose={() => {}}
                onSuccess={handleAssignSuccess}
              />
            </Paper>
          )}
        </Grid>
        {/* Add Employee Modal */}
        <AddUserModal
          editMode
          _user={user}
          dealer={dealerId}
          open={showAddUserModal}
          onClose={() => setShowAddUserModal(false)}
          onSuccess={(userInfo: any) => {
            //   getSecurityUserList();
            setUser(userInfo);
            setShowAddUserModal(false);
          }}
        />
        {/* Confirmation Modal */}
        <ConfirmationModal
          icon
          message={t('dealers.remove_access_msg', {
            name: selectedAccount?.name
          })}
          cancelBtnTxt={`${t('general.no')}, ${t('general.btn.cancel')}`}
          submitBtnTxt={`${t('general.yes')}, ${t('general.btn.remove')}`}
          open={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={() => removeAccess(selectedAccount?.organization)}
        />

        {/* Edit sites modal */}
        <EditSites
          open={showEditSites}
          account={selectedAccount}
          siteList={returnSiteList}
          onClose={() => setShowEditSites(false)}
          onSuccess={successEditSites}
        />
      </Grid>
    </WithPermission>
  );
};

export default ViewEmployee;
