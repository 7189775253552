import React, { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import SectionPanel from '../../../components/SectionPanel/SectionPanel';
import { useTranslation } from 'react-i18next';
import routes from '../../../routes/routes';
import DashboardCard from '../../../components/DashboardCard/DashboardCard';
//Images
import controllerImg from '../../../assets/images/dashboard_icons/controllers.png';
import dealerImg from '../../../assets/images/dashboard_icons/dealer30.png';
import doorImg from '../../../assets/images/dashboard_icons/door30.png';
import organizationImg from '../../../assets/images/dashboard_icons/organization30.png';
import siteImg from '../../../assets/images/dashboard_icons/sites30.png';
import { profileType } from '../../../interfaces/user.interface';
import { useNavigate } from 'react-router-dom';
import { Summary } from '../../../interfaces/summary.interface';
import { calcMaxFontSize } from '../../../utils/cardFontSize';

interface props {
  show: (roles: profileType[]) => boolean;
  _dealer: string | undefined;
  summary: Summary;
  numberSize: number;
}

const SummaryPanel = ({ show, _dealer, summary, numberSize }: props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <SectionPanel
      title={t('general.summary')}
    >
      <Stack direction="row" flexWrap="wrap" gap={2}>
        {show(['system']) && !_dealer &&
          <DashboardCard
            width={180}
            height={105}
            padding={1.8}
            src={dealerImg}
            title={t('dealers.dealers')}
            number={summary.summary.dealers || 0}
            fontSize={14}
            numberSize={numberSize}
            action={() => navigate(routes.dealer_list)}
          />
        }
        {show(['system', 'dealer']) &&
          <DashboardCard
            width={180}
            height={105}
            padding={1.8}
            src={organizationImg}
            title={t('organization.organizations')}
            number={summary.summary.organizations || 0}
            fontSize={14}
            numberSize={numberSize}
            action={() => navigate(routes.organization_list)}
          />
        }
        {show(['dealer']) && !_dealer &&
          <DashboardCard
            width={180}
            height={105}
            padding={1.8}
            src={siteImg}
            title={t('sites.sites')}
            number={summary.summary.sites || 0}
            fontSize={14}
            numberSize={numberSize}
            action={() => navigate('/home')}
          />
        }
        {!show(['dealer']) && !_dealer &&
          <DashboardCard
            width={180}
            height={105}
            padding={1.8}
            src={controllerImg}
            title={t('dealers.controllers')}
            number={summary.summary.controllers || 0}
            fontSize={14}
            numberSize={numberSize}
            action={() => navigate('/home')}
          />
        }
        <DashboardCard
          width={180}
          height={105}
          padding={1.8}
          src={doorImg}
          title={t('doors.doors')}
          number={summary.summary.doors || 0}
          fontSize={14}
          numberSize={numberSize}
          action={() => navigate('/home')}
        />
      </Stack>
    </SectionPanel>
  )
}

export default SummaryPanel;