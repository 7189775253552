import { useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  ListSubheader,
  Stack,
  ListItemButton,
  ListItemText,
  Collapse
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// Interfaces
import route from '../../interfaces/route.interface';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import routes from '../../routes/routes';

import site from '../../assets/images/menu_icons/site.png';
import controller from '../../assets/images/menu_icons/controllers30.png';
import door from '../../assets/images/menu_icons/door.png';
import holiday from '../../assets/images/menu_icons/holiday.png';
import schedule from '../../assets/images/menu_icons/schedule.png';
import accesslevel from '../../assets/images/menu_icons/accesslevel.png';
import users from '../../assets/images/menu_icons/users.png';
import card from '../../assets/images/menu_icons/card.png';
import organization from '../../assets/images/dashboard_icons/organization30.png';
import dealer from '../../assets/images/dashboard_icons/dealer30.png';
import lockdown from '../../assets/images/menu_icons/lockdown.png';
import input from '../../assets/images/menu_icons/input.png';
import output from '../../assets/images/menu_icons/output.png';
import hardware from '../../assets/images/menu_icons/hardware.png';
import accountInfo from '../../assets/images/menu_icons/account_info.png';
import siteInfo from '../../assets/images/menu_icons/site_info.png';
import firmwareManager from '../../assets/images/menu_icons/firmware_manager.png';
import userReport from '../../assets/images/menu_icons/user_report.png';
import cardReport from '../../assets/images/menu_icons/card_report.png';
import eventsReport from '../../assets/images/menu_icons/event_report.png';
import global from '../../assets/images/menu_icons/global.png';
import floorPlan from '../../assets/images/menu_icons/floor1.png';
import interlocks from '../../assets/images/dashboard_icons/interlocks.png';
import events from '../../assets/images/menu_icons/events.png';
import sdtNewSession from '../../assets/images/menu_icons/sdt_newsession.png';
import sdtOpenSessions from '../../assets/images/menu_icons/sdt_opensessions.png';
import sdtCurrentSession from '../../assets/images/menu_icons/sdt_currentsession.png';
import sdtDiagnoseController from '../../assets/images/menu_icons/diagnose_controller.png';
import { idFormat } from '../../utils/idFormat';

interface NavigationMenuProps {
  routes?: Array<any>;
}
const padding = 2.5;

const names = ['hardware', 'orgusers_report', 'orgcards_report'];

const icon = [
  routes.sites_list,
  routes.controller_list,
  routes.door_list,
  routes.holiday_list,
  routes.schedule_list,
  routes.access_level,
  routes.user_list,
  routes.user_org_list,
  routes.card_list,
  routes.organization_list,
  routes.dealer_list,
  routes.organization_profile,
  routes.lockdown,
  routes.input_list,
  routes.output_list,
  routes.organization_info,
  routes.site_dashboard,
  routes.firmware_manager,
  routes.report_events,
  routes.global_settings,
  routes.floor_plan,
  routes.interlocks_list,
  routes.event_list,
  routes.support_new_session,
  routes.support_open_sessions,
  routes.support_current_session,
  routes.support_diagnose_controller
];

const NavigationMenu = ({ routes = [] }: NavigationMenuProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [collapse, setCollapse] = useState<string[]>([]);

  const handleCollapse = (id: string) => {
    collapse.includes(id)
      ? setCollapse((c) => c.filter((ele) => ele !== id))
      : setCollapse((c) => [...c, id]);
  };

  const verifyCollapse = (id: string) => {
    return collapse.includes(id);
  };

  const getIcon = (icon: string) => {
    return (
      <img
        style={{ marginRight: '.8em', filter: 'invert(85%)' }}
        src={
          process.env.PUBLIC_URL +
          `/icons/duotune/${icon || 'arrows/arr089.svg'}`
        }
        alt={icon}
      />
    );
  };

  const returnImg = (src: any, width?: number | string, filter?: string) => {
    return (
      <img
        style={{
          marginRight: '.8em',
          width: width || '',
          filter: filter || 'brightness(130%)'
        }}
        src={src}
        alt="Navigation icon"
      />
    );
  };

  const customIcon = (node: any) => {
    switch (node.url) {
    case icon[0]:
      return returnImg(site, 26);
    case icon[1]:
      return returnImg(controller, 24);
    case icon[2]:
      return returnImg(door);
    case icon[3]:
      return returnImg(holiday);
    case icon[4]:
      return returnImg(schedule);
    case icon[5]:
      return returnImg(accesslevel);
    case icon[6]:
    case icon[7]:
      return returnImg(users);
    case icon[8]:
      return returnImg(card);
    case icon[9]:
    case icon[11]:
      return returnImg(organization, 25, 'brightness(265%) grayscale(1)');
    case icon[10]:
      return returnImg(dealer, 25, 'brightness(265%) grayscale(1)');
    case icon[12]:
      return returnImg(lockdown, 24);
    case icon[13]:
      return returnImg(input, 24);
    case icon[14]:
      return returnImg(output, 24);
    case icon[15]:
      return returnImg(accountInfo, 24);
    case icon[16]:
      return returnImg(siteInfo, 24);
    case icon[17]:
      return returnImg(firmwareManager, 24);
    case icon[18]:
      return returnImg(eventsReport, 24);
    case icon[19]:
      return returnImg(global, 24);
    case icon[20]:
      return returnImg(floorPlan, 24);
    case icon[21]:
      return returnImg(interlocks, 24, 'invert(100%)');
    case icon[22]:
      return returnImg(events, 24);
      case icon[23]: 
        return returnImg(sdtNewSession, 26);
      case icon[24]:
        return returnImg(sdtOpenSessions, 26);
      case icon[25]:
        return returnImg(sdtCurrentSession, 26);
      case icon[26]:
        return returnImg(sdtDiagnoseController, 26); 
    default:
      switch (node.name.toLocaleLowerCase()) {
      case 'hardware':
        return returnImg(hardware, 25);
      case 'orgusers_report':
        return returnImg(userReport, 24);
      case 'orgcards_report':
        return returnImg(cardReport, 24);
      }
    }
  };

  // Recursive function for rendering route children
  const renderChildren = (
    route: route,
    renderPadding: number
  ): JSX.Element | JSX.Element[] | ReactNode => {
    return route.children?.map((child_route: route) => {
      return (
        <List
          key={child_route.id}
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'transparent',
            paddingBottom: 0
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListItemButton
              id={`navmenu-${idFormat(child_route.name)}`}
              className={`listItemButton ${
                window.location.pathname === child_route.url && 'selected'
              }`}
              sx={{ pl: renderPadding }}
              onClick={(event: any) => {
                event.stopPropagation();
                if (child_route.children && child_route.children?.length > 0) {
                  handleCollapse(child_route.id);
                } else {
                  navigate(child_route.url.substring(1));
                }
              }}
            >
              {icon.includes(child_route.url) ||
              names.includes(child_route.name.toLocaleLowerCase())
                ? customIcon(child_route)
                : getIcon(child_route.icon)}
              <ListItemText
                className="listItemText"
                primary={t(child_route.name)}
              />
              {child_route.children &&
                child_route.children?.length > 0 &&
                (verifyCollapse(child_route.id) ? (
                  <ExpandLess sx={{ color: grey[100] }} />
                ) : (
                  <ExpandMore sx={{ color: grey[100] }} />
                ))}
            </ListItemButton>
          }
        >
          <Collapse
            in={verifyCollapse(child_route.id)}
            timeout="auto"
            unmountOnExit
          >
            {renderChildren(child_route, renderPadding + padding)}
          </Collapse>
        </List>
      );
    });
  };

  return (
    <>
      {routes.map((route: route) => {
        // const isOpen = selectedMenu === route.id;
        return (
          <>
            <List
              key={route.id}
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'transparent',
                paddingBottom: 0,
                paddingRight: '1em'
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{ bgcolor: 'transparent', position: 'relative' }}
              >
                <Stack
                  direction="row"
                  alignContent="center"
                  sx={{
                    color: grey[400],
                    textTransform: 'uppercase',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block'
                  }}
                >
                  {t(route.name)}
                </Stack>
              </ListSubheader>
              {renderChildren(route, padding)}
            </List>
            {/* <Divider /> */}
          </>
        );
      })}
    </>
  );
};

export default NavigationMenu;
