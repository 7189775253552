import React, { useState, useEffect } from 'react';
import { Fab, Fade, Paper, Box, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import FormField from '../FormField/FormField';
import validateEmail from '../../utils/email-validation';
import { postEmailSupport } from '../../api/api';
import useAlert from '../../utils/hooks/useAlert';

const SupportWidget: React.FC = () => {
  const { errorAlert, successAlert } = useAlert();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [formVisible, setFormVisible] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);


  //reset fields
  useEffect(() => {
    setName('');
    setEmail('');
    setMessage('');
    setValidEmail(true);
  }, [formVisible]);

  //disable send button
  useEffect(() => {
    if (
      !name ||
      !validEmail ||
      !message 
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [name, email, message]);

  const handleClick = () => {
    setFormVisible(!formVisible);
  };

  const handleEmailChange = (email: string) => {
    setEmail(email);
    setValidEmail(validateEmail(email));
  };

  const handleEmailSupport = async () => {
    setLoading(true)
    const body ={
      'name': name,
      'email': email,
      'message': message
    }
    try {
      const { message, status, error }= await postEmailSupport(body)
      console.log(status, message, error)
      if (status === 200) {
        successAlert(message)
      }
      setFormVisible(false);
      setLoading(false);
    } catch (error:any) {
      errorAlert(error.response?.data?.message || error.message);
      setFormVisible(false);
      setLoading(false);
    }
  }

  return (
    <Box position="relative">
      <Fab
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 1000,
          borderRadius: '50% !important'
        }}
        onClick={handleClick}
      >
        <EmailIcon />
      </Fab>
      <Fade in={formVisible}>
        <Paper
          sx={{
            position: 'fixed',
            bottom: 80,
            right: 10,
            width: 250,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            backgroundColor: '#fff',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: 4
          }}
        >
          <FormField 
            label="Name"  
            value={name}
            onChange={(event: any) => setName(event.target.value)}     
          />  
          <FormField 
            label="Email" 
            value={email} 
            error={!validEmail}
            errorText="Invalid email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailChange(e.target.value)}
          />
          <FormField
            label="Message" 
            value={message} 
            rows={4} 
            maxLength={1000}
            onChange={(event: any) => setMessage(event.target.value)}
          />
          <Button onClick={handleEmailSupport} disabled={disableBtn || loading} variant="contained" color="primary">
              Send
          </Button>
        </Paper>
      </Fade>
  
    </Box>
  );
};

export default SupportWidget;