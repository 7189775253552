const global_settings = {
  keypad_tab: 'Código de teclado',
  fields_tab: 'Campos personalizados de usuario',
  code_length: 'Longitud del código de usuario',
  min_length: 'Longitud mínima',
  max_length: 'Longitud máxima',
  keypad_confirmation_msg:
    '¿Estás seguro de que deseas cambiar la longitud del código de usuario de {{initialLength}} a {{keypadCodeLength}}?',
  keypad_var_confirmation_msg:
    '¿Estás seguro de que deseas cambiar la longitud mínima y máxima del código de usuario?',
  field_label: 'Etiqueta del campo',
  field_type: 'Tipo de campo',
  fields_confirmation_msg:
    '¿Estás seguro de que deseas guardar los cambios? Cualquier eliminación de campos personalizados afectará a los usuarios que los tenían.',
  fields_multi_confirmation_msg:
    'Cambiar a multi-sitio afectará a todos los sitios, eliminando cualquier dato que pueda haber, ¿estás seguro de que quieres continuar?',
  fields_delete_msg:
    '¿Estás seguro de que deseas eliminar este campo personalizado? Se ha asignado a {{user_count}} usuarios.',
  fields_apply_default_msg:
    'Si aplicas la configuración de la cuenta, se eliminarán todos los datos en los campos personalizados existentes, ¿estás seguro de que quieres continuar?',
  fields_success: 'Campos personalizados guardados con éxito',
  fields_error: 'Error al guardar campos personalizados',
  keypad_success: 'Longitud del código de usuario cambiada con éxito',
  keypad_error: 'Error al cambiar la longitud del código de usuario',
  keypad_exists:
    'Hay usuarios con código de teclado, las modificaciones estarán limitadas.',
  apply_multisite: 'Aplicar a todos los sitios',
  apply_default: 'Aplicar configuración de cuenta',
  multisite_keypad:
    'La opción multi-sitio está habilitada, no se puede cambiar la longitud del código de usuario.',
  multisite_customfields:
    'La opción multi-sitio está habilitada, no se pueden cambiar los campos personalizados de usuario.'
};

export default global_settings;
