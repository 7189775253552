const login = {
  log_into_account: 'Login to your account',
  log_msg: 'Enter your username and password',
  username: 'Username',
  password: 'Password',
  remember_me: 'Remember Me',
  login: 'Login',
  sign_in: 'Sign In',
  click_msg: 'By clicking Sign In, you agree to our',
  terms: 'Terms and Conditions',
  and: 'and',
  privacy: 'Privacy Policy',
  account_msg: 'Dont have an acount?',
  register_here: 'Register here',
  forgot_password: 'Forgot password?',
  trust_device: 'Trust device',
  otp_desc: 'A one time password has been sent to your email please check your inbox'
};

export default login;
