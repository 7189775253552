const proPlan = {
  cdvi_plan: '02898a67-53ff-11ed-b2ae-fb9e932635c5',
  name: 'CDVI Cloud PRO',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur labore fugiat sapiente ex! Magnam eos necessitatibus architecto voluptates voluptatibus recusandae similique ex porro perspiciatis, ratione provident velit dignissimos temporibus illo.',
  order: 1,
  month_price: '1.00',
  year_price: '12.00',
  active: true,
  feature: [
    {
      id: 'dc29e45e-4ccf-11ee-8c41-a7e9e78d3dd6',
      description: 'Unlimited sites',
      order: 1,
      included: true
    },
    {
      id: 'dc29e45f-4ccf-11ee-8c41-e70ccfec084f',
      description: 'Unlimited users and cards',
      order: 2,
      included: true
    },
    {
      id: 'dc29e460-4ccf-11ee-8c41-1f57e358b14d',
      description: 'Mobile passes',
      order: 3,
      included: true
    },
    {
      id: 'dc29e461-4ccf-11ee-8c41-2379e3edd646',
      description: 'Cloud storage',
      order: 4,
      included: true
    },
    {
      id: 'dc29e462-4ccf-11ee-8c41-e7cc45d26968',
      description:
        'Technical support - Available Monday to Friday, from 8:30 am to 8:00 pm EST.',
      order: 5,
      included: true
    },
    {
      id: 'dc29e463-4ccf-11ee-8c41-e3e4ae32510b',
      description: 'Access to CDVI Academy - Training',
      order: 6,
      included: true
    }
    // {
    //   id: "dc29e464-4ccf-11ee-8c41-df1786eaa22f",
    //   description: "Access to CDVI Academy - Webinars",
    //   order: 7,
    //   included: true,
    // },
    // {
    //   id: "dc29e465-4ccf-11ee-8c41-0baa3ef42bbd",
    //   description: "Access to CDVI Academy - Video tutorials",
    //   order: 8,
    //   included: true,
    // },
  ]
};

export default proPlan;
