/*
 * Description: palette for darkMode theme
 * Remarkable changes: refactoring for cleaner code
*/

/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

const darkPalette = (theme: any) => {
  return {
    mode: 'dark',
    common: {
      black: '#fff',
      white: '#111936'
    },
    background: {
      paper: theme.paper,
      default: theme.backgroundDefault
    },
    primary: {
      light: theme.colors?.darkPrimaryLight,
      main: theme.colors?.darkPrimaryMain,
      dark: theme.colors?.darkPrimaryDark
    },
    secondary: {
      light: theme.colors?.darkSecondaryLight,
      main: theme.colors?.darkSecondaryMain,
      dark: theme.colors?.darkSecondaryDark
    },
    grey: {
      50: theme.colors?.grey900,
      100: theme.colors?.grey900,
      200: theme.colors?.grey800,
      300: theme.colors?.grey700,
      400: theme.colors?.grey600,
      500: theme.colors?.grey500,
      600: theme.colors?.grey400,
      700: theme.colors?.grey300,
      800: theme.colors?.grey200,
      900: theme.colors?.grey100
    },
    info: {
      main: theme.colors?.primaryMain
    },
    text: {
      primary: '#ffffff',
      secondary: theme.colors?.grey300
    },
    input: {
      background: theme.colors?.grey800,
      disabled: theme.colors?.darkSecondaryDark
    },
    button: {
      background: theme.colors?.darkPrimaryMain,
      icon: '#FFFFFF',
      radio: '#FFFFFF',
      disabled: theme.colors?.darkSecondaryDark
    }
  }
};

export default darkPalette;
