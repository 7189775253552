const outputs = {
  table: {
    displayName: 'Nombre',
    serialNumber: 'Serial',
    normallyOpen: 'Abierto normalmente',
    searchPlaceHolder: 'Nombre / Serial',
    enabled: 'Habilitado'
  },
  modal: {
    title: 'Propiedades',
    tabInformation: 'Informacion General',
    TabEvents: 'Eventos'
  },
  form: {
    options: 'Opciones',
    checkEnable: 'Activar',
    radioNormallyOpen: 'Normalmente abierto',
    radioNormallyClose: 'Normalmente cerrado',
    inputResponseTime: 'Tiempo de respuesta (ms)',
    inputRestoreTime: 'Tiempo de restauracion (ms)'
  }
};

export default outputs;
