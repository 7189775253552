import { Box, Divider, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react'
import Avatar from '../../../components/Avatar/Avatar';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useTranslation } from 'react-i18next';
import avatar from '../../../assets/images/media/avatars/avatar.png';
import lngs from '../../lngs';
import { idFormat } from '../../../utils/idFormat';
import user from '../../../interfaces/user.interface';
import { OpenSession } from '../../../interfaces/supportTool.interface';
import { useSelector } from '../../../store';

interface props {
  user: user,
  handleActionClick: (setting: string, e: React.MouseEvent<HTMLElement>) => void,
  setAnchorElLang: (value: React.SetStateAction<null | HTMLElement>) => void
}

const UserInfoMenuContent = ({ user, handleActionClick, setAnchorElLang }: props) => {
  const { t, i18n } = useTranslation();
  const darkModeEnabled = useMemo(() => user?.features.find((element: any) => element.feature === 'dark_mode').enabled, [])
  const currentProfile = useMemo(() =>
    user.user_profiles.find(
      (profile: any) => profile.dealer_org_role_id === user.role
    ),
    [user.user_profiles]
  );
  const session: OpenSession = useSelector(
    (store) => store.supportSession.session
  );

  const settings: { config: string, enabled: boolean }[] = useMemo(() => [
    { config: 'config.profile', enabled: !session },
    // { config: 'config.dealer_info', enabled: user.profile_type === 'dealer' },
    { config: 'config.switch_profile', enabled: (user.user_profiles.length >= 2 || false) && !session},
    { config: 'config.change_password', enabled: !session },
    { config: 'config.language', enabled: true },
    { config: 'config.dark_mode', enabled: darkModeEnabled },
    { config: 'config.logout', enabled: !session }
  ], [darkModeEnabled, currentProfile]);

  return (
    <>
      <Stack
        direction="row"
        padding={1}
        alignItems="center"
        spacing={2}
        textAlign={'left'}
      >
        <Avatar
          className="transparent-avatar"
          variant="rounded"
          sx={{ width: 40, height: 40 }}
          src={
            user.logo
              ? `data:image/png;base64,${user.logo}`
              : '/static/images/avatar/2.jpg'
          }
        >
          <img
            src={user.logo ? `data:image/png;base64,${user.logo}` : avatar}
            alt={user.first_name + ' ' + user.last_name + ' avatar'}
          />
        </Avatar>
        <Box>
          <Typography component="div">
            {user.first_name} {user.last_name}
          </Typography>
          <Typography component="div" variant="caption">
            {currentProfile?.company_org_name || null}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      {settings.map((s) =>
        s.enabled === true && (
          <MenuItem
            key={s.config}
            style={{ margin: 6 }}
            id={`menu-item-${idFormat(s.config)}`}
            onClick={(e) => {
              if (s.config !== 'config.language') {
                handleActionClick(s.config, e);
              }
              if (s.config === 'config.language') {
                setAnchorElLang(e.currentTarget);
              }
            }}
          >
            <Stack
              direction={'row'}
              width="100%"
              justifyContent="space-between"
              gap={1}
            >
              {s.config !== ('config.dark_mode' || 'config.language') && (
                <Typography component="div" textAlign="center">
                  {t(s.config)}
                </Typography>
              )}
              {s.config === 'config.dark_mode' && (
                darkModeEnabled && (
                  <Stack
                    direction={'row'}
                    width="100%"
                    justifyContent={'space-between'}
                    gap={1}
                  >
                    <Typography component="div" textAlign="center">
                      {user.darkmode ? 'Light Mode' : 'Dark Mode'}
                    </Typography>
                    <IconButton sx={{ padding: 0 }}>
                      {user.darkmode ? (
                        <Brightness7Icon
                          sx={{ color: 'white', fontSize: 18.5 }}
                        />
                      ) : (
                        <Brightness4Icon sx={{ fontSize: 18.5 }} />
                      )}
                    </IconButton>
                  </Stack>
                )
              )}
              {s.config === 'config.language' && (
                <Stack
                  direction={'row'}
                  width="100%"
                  justifyContent="flex-end"
                  gap={1}
                >
                  <Typography component="div" textAlign="center">
                    {t(lngs[i18n.language.substring(0, 2)].nativeName)}{' '}
                  </Typography>
                  <img
                    width={15}
                    src={t(lngs[i18n.language.substring(0, 2)].flag)}
                    alt={t(lngs[i18n.language.substring(0, 2)].nativeName)}
                  />
                </Stack>
              )}
            </Stack>
          </MenuItem>
        )
      )}
    </>
  )
}

export default UserInfoMenuContent