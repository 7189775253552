import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Material UI
import { Box, Stack, Theme, useMediaQuery } from '@mui/material';

// Store
import { useDispatch, useSelector } from '../../store';
import { changeProfile } from '../../store/actions/user';
// Imports
import user from '../../interfaces/user.interface';
import { getDealersImg, getOrganizationDetail } from '../../api/api';
import Avatar from '../../components/Avatar/Avatar';
import UserInfoButton from './UserInfoButton/UserInfoButton';
import SelectionNav from './SelectionNav/SelectionNav';
import SelectionNavMini from './SelectionNavMini/SelectionNavMini';
import Clock from './Clock/Clock';
import routes from '../../routes/routes';
import placeholder from './../../assets/images/media/avatars/logo_placeholder.png';

const NavigationBar = ({
  open,
  setMenu
}: {
  open: boolean;
  setMenu: (menu: any) => void;
}) => {
  const location = useLocation();
  const user: user = useSelector((store) => store.user.user);
  const dispatch = useDispatch();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { pathname } = location;

  const [organization, setOrganization] = useState<any>();


  useEffect(() => {
    fecthOrgnization();
  }, []);

  useEffect(() => {
    user.selectedDealer?.dealer && fetchImage();
  }, [user.selectedDealer?.dealer]);

  const displayNavbar = () =>
    !pathname.includes(routes.floor_plan_view.split(':')[0]);

  const fecthOrgnization = async () => {
    try {
      const { status, data } = await getOrganizationDetail(
        user.selectedOrg?.organization || user.currentProfile
      );
      status === 200 &&
        setOrganization({
          ...data
        });
    } catch (error: any) {}
  };

  const fetchImage = async () => {
    try {
      const { image } = await getDealersImg(
        String(user.selectedDealer?.dealer)
      );
      dispatch(
        changeProfile(
          user.permissions,
          user.role,
          user.role_name,
          user.currentProfile,
          user.profile_type,
          user.selectedOrg,
          {
            ...user.selectedDealer,
            image: image ? `data:image/jpeg;base64,${image}` : ''
          }
        )
      );
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{ alignItems: 'center' }}
        justifyContent="space-between"
        width="100%"
      >
        {displayNavbar() ? (
          <Box pl={matches ? (open ? 1 : 10) : open ? 0 : 4}>
            {matches ? <SelectionNav /> : <SelectionNavMini />}
          </Box>
        ) : (
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Avatar
              className="transparent-avatar"
              variant="rounded"
              sx={{ width: 40, height: 40, marginTop: 1 }}
              src={
                organization?.logo
                  ? `data:image/png;base64,${organization?.logo}`
                  : placeholder
              }
            >
              <img width={50} height={50} src={placeholder} alt="Account avatar" />
            </Avatar>
            <Box pl={1}>
              {matches ? <SelectionNav /> : <SelectionNavMini />}
            </Box>
          </Box>
        )}
        {/* User section and settings */}
        <Box
          sx={{ flexGrow: 0, display: 'flex', gap: 1, alignItems: 'center' }}
        >
          {matches && <Clock />}
          <UserInfoButton setMenu={setMenu}/>
        </Box>
      </Stack>
    </>
  );
};
export default NavigationBar;
