import { Box, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  getUserDashboard,
  getDealerRequests,
  getUserDashboardEvents
} from '../../api/api';
import user from '../../interfaces/user.interface';
import { useSelector } from '../../store';
import { useTranslation } from 'react-i18next';
import MyPlanTabs from './MyPlanTabs/MyPlanTabs';
import { useDispatch } from 'react-redux';
import { changeProfile } from '../../store/actions/user';
import { setSelectedSite } from '../../store/actions/selected-site';
import { useTheme } from '@mui/system';
import DealerRequestPanel from './DashboardPanels/DealerRequestPanel';
import { profileType } from '../../interfaces/user.interface';
import SummaryPanel from './DashboardPanels/SummaryPanel';
import ControllerPanel from './DashboardPanels/ControllerPanel';
import { Summary } from '../../interfaces/summary.interface';
import { calcMaxFontSize } from '../../utils/cardFontSize';

const initSummary: Summary = {
  summary: {
    organizations: 0,
    dealers: 0,
    sites: 0,
    controllers: 0,
    doors: 0,
    users: 0,
    credentials: 0,
    lockdown_enabled: 2,
    lockdown_disabled: 0,
    has_subscription: false,
    has_payment_method: false
  },
  online_controllers: 0,
  offline_controllers: 0,
  events_organization: [],
  offline_controllers_list: [
    {
      controller: '',
      serial: '',
      dealer_name: '',
      organization_name: '',
      offline_at: ''
    }
  ]
};

interface props {
  _dealer?: string;
}

const Dashboard = ({ _dealer }: props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const user: user = useSelector((store) => store.user.user);
  const [dealerRequests, setDealerRequests] = useState<any[]>([]);
  const [summary, setSummary] = useState<Summary>(initSummary);
  // const [events, setEvents] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);

  const maxCardNumberSize = useMemo(() => calcMaxFontSize([
    summary.summary?.dealers || 0,
    summary.summary?.organizations || 0,
    summary.summary?.controllers || 0,
    summary.summary?.doors || 0,
    summary?.online_controllers || 0,
    summary?.offline_controllers || 0
  ]), [summary]);

  useEffect(() => {
    fetchDashboard();
    getDealerRequestList();
    // fetchDashboardEvents();
    clearAccount();
  }, []);

  const clearAccount = () => {
    dispatch(
      changeProfile(
        user.permissions,
        user.role,
        user.role_name,
        user.currentProfile,
        user.profile_type,
        null,
        user.selectedDealer
      )
    );
    dispatch(setSelectedSite(undefined));
  };

  const getDealerRequestList = async (filter?: string) => {
    try {
      const { dealers } = await getDealerRequests(filter);
      const list: Array<any> = dealers
        .filter((ele: any) => !ele.deleted && ele.status === 'P')
        .slice(-3);
      setDealerRequests(list);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDashboard = async () => {
    setLoading(true);
    try {
      const { status, summary } = await getUserDashboard(_dealer);
      status === 200 && setSummary(summary);
      console.log('use filter to only get last 3 drequests')
      setOpenPlan(
        !summary.summary.has_payment_method &&
        !_dealer &&
        user.profile_type === 'dealer'
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  // const fetchDashboardEvents = async () => {
  //   setLoading(true);
  //   try {
  //     const {
  //       status,
  //       summary: { events_organization }
  //     } = await getUserDashboardEvents();
  //     status === 200 && setEvents(events_organization);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.error(error);
  //   }
  // };

  const show = (type: profileType[]): boolean => {
    return type.includes(user.profile_type);
  };

  return (
    <Box maxWidth="100em" width="100%">
      {!_dealer && (
        <>
          <Typography
            sx={{ color: theme.palette.info.main }}
            variant="h3"
            gutterBottom
          >
            {t('general.welcome')}, {user.first_name}.
          </Typography>
          <Typography color="grey" variant="h4" marginBottom={3}>
            {
              user.user_profiles.find(
                (ele: any) => ele.dealer_org_id === user.currentProfile
              )?.company_org_name
            }
          </Typography>
        </>
      )}
      <Grid container spacing={4}>
        <Grid item lg={5.5} xs>
          <SummaryPanel
            show={show}
            _dealer={_dealer}
            summary={summary}
            numberSize={maxCardNumberSize}
          />
        </Grid>
        <Grid item spacing={2} lg={5.8} xs>
          <Stack gap={2}>
            <ControllerPanel
              summary={summary}
              numberSize={maxCardNumberSize}
            />
            {user.role_name === 'Admin' && !_dealer && (
              <DealerRequestPanel
                requests={dealerRequests}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <MyPlanTabs
        open={openPlan}
        onClose={() => setOpenPlan(false)}
        hasPayment={summary?.summary?.has_payment_method}
        finish={() => {
          setOpenPlan(false);
          setSummary({
            ...summary,
            summary: {
              ...summary.summary,
              has_payment_method: true,
              has_subscription: true
            }
          });
        }}
      />
    </Box>
  );
};

export default Dashboard;
