export const getFontSize = (length: number) => {
  if (length <= 2) return 34;
  if (length <= 3) return 33;
  if (length <= 4) return 26;
  return 22;
};

/**
 * Calculates the maximum font size based on the length of numbers.
 * 
 * @param numbers - An array of numbers.
 * @returns The maximum font size.
 */
export const calcMaxFontSize = (numbers: number[]) => {
  const max = Math.max(...numbers.map((n) => String(n).length));
  return getFontSize(max);
};