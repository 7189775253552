const schedules = {
  title: 'Calendriers',
  edit: 'Modifier',
  delete: 'Supprimer',
  activate: 'Activer/Désactiver',
  add_schedule: 'Ajouter un calendrier',
  edit_schedule: 'Modifier un calendrier',
  add_schedules: 'Ajouter des calendriers',
  search_schedules: 'Horaires de recherche',
  cancel: 'Annuler',
  save: 'Sauvegarder',
  update: 'Mettre à jour',
  schedule_basic: 'Basique',
  schedule_advanced: 'Avancé',
  cycle_length: 'Longueur du cycle ({{days}}jours)',
  days_label: 'Jours de la semaine',
  dow: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  name: 'Nom',
  date: 'Date',
  cycle_date: 'Date de Début du Cycle',
  default_start_time: 'Heure de Début par Défaut',
  default_end_time: 'Heure de Fin par défaut',
  startTime: 'Heure de début',
  endTime: 'Heure de fin',
  today: 'Aujourd\'hui',
  month: 'Mois',
  week: 'Semaine',
  goToDate: 'Aller à la date de début',
  custom: 'Coutume',
  cycle_length_text: 'Durée du Cycle',
  custom_modal_msg: 'Sélectionnez le nombre de jours dans le cycle (max 28)',
  confirmation_msg: 'Êtes-vous sûr de vouloir modifier la durée du cycle? Les modifications actuelles seront perdues',
  holidays: {
    include: 'Inclure',
    exclude: 'Exclure',
    drag_and_drop: 'Faites glisser et déposez un jour férié pour l\'inclure ou l\'exclure',
    holidays: 'Vacances',
    add_new: 'Ajouter un Nouveau',
    INCLUDE: 'INCLURE',
    EXCLUDE: 'EXCLURE ',
    include_description: 'La durée du séjour sera AJOUTÉE au planning',
    exlude_description: 'La durée du séjour sera SUPPRIMÉE du planning'
  },
  table: {
    schedule_id: 'ID',
    schedule_label: 'SCHEDULES',
    cycle_length: 'CYCLE LENGTH',
    startdate_label: 'START DATE',
    active_label: 'STATE'
  }
};

export default schedules;
