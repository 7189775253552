const login = {
  log_into_account: 'Ingrese a su cuenta',
  log_msg: 'Ingrese su nombre de usuario y contraseña',
  username: 'Nombre de usuario',
  password: 'Clave',
  remember_me: 'Recuérdame',
  login: 'Acceso',
  sign_in: 'Iniciar sesión',
  click_msg: 'Al hacer clic en Iniciar sesión, aceptas nuestros',
  terms: 'Términos y condiciones',
  and: 'y',
  privacy: 'Política de privacidad',
  account_msg: '¿No tienes cuenta?',
  register_here: 'Registrarse aquí',
  forgot_password: '¿Olvidó tu contraseña?',
  trust_device: 'Confiar en dispositivo',
  otp_desc: 'Se ha enviado una contraseña de un solo uso a tu correo electrónico, por favor revisa tu bandeja de entrada'
};

export default login;
