import React from 'react';
import { grey } from '@mui/material/colors';
import { Box, Stack, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@mui/system';
import { idFormat } from '../../utils/idFormat';

interface props {
  title: string;
  action: () => void;
  src?: string;
  number?: number;
  forward?: boolean;
  back?: boolean;
  invert?: boolean;
  width?: number;
  height?: number;
  numberSize?: number;
  padding?: number;
  fontSize?: number;
  color?: string;
  fontWeight?: string;
}

const DashboardCard = ({
  src = '',
  title,
  number,
  action,
  forward,
  back,
  invert,
  width,
  height,
  numberSize,
  padding,
  fontSize,
  color
}: props) => {
  const theme = useTheme();

  return (
    <Box
      className="onhover-btn"
      id={`dashboard-card-${idFormat(title)}`}
      width={width || '49%'}
      height={height || 'auto'}
      minWidth={100}
      padding={padding || 1.5}
      borderRadius={4}
      maxWidth={350}
      sx={{
        background: theme.palette.secondary.light,
        border: '0.5px #cecece solid',
        position: 'relative',
        boxShadow: 'rgba(0, 0, 0, 0.20) 1.95px 1.95px 6px',
        cursor: 'pointer'
      }}
    >
      <span onClick={action}>
        <Box display="flex" justifyContent="space-between">
          {back ? (
            <ArrowBackIosIcon
              sx={{ color: grey[500], marginBottom: 1, marginLeft: 1 }}
              fontSize="large"
            />
          ) : (
            <img src={src} width={45} alt={title} />
          )}
          {forward ? (
            <ArrowForwardIosIcon
              sx={{ color: grey[500], alignSelf: 'center', marginRight: 1 }}
              fontSize="small"
            />
          ) : (
            <Typography
              variant="h1"
              mr={1}
              fontSize={numberSize || 34}
              color={color ? color : grey[500]}
            >
              {number}
            </Typography>
          )}
        </Box>
        <Stack direction="column" alignContent="center">
          <Typography
            color={color || ''}
            fontSize={fontSize || 12}
            marginLeft={0.5}
            marginTop={0.5}
          >
            <strong>{title}</strong>
          </Typography>
        </Stack>
      </span>
    </Box>
  );
};

export default DashboardCard;
