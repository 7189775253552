import React, { ChangeEventHandler } from 'react';

// Material UI
import {
  Box,
  Button,
  FormControlLabel,
  FormControl,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  FormLabel,
  Checkbox,
  useMediaQuery,
  Theme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/system';

// DatePickers
import DatePicker from '../../DatePicker/DatePicker';
import dayjs from 'dayjs';

// Project imports
import MaterialSearchBar from '../../MaterialSearchBar/MaterialSearchBar';
import Select from '../../Select/Select';

// Translation
import { useTranslation } from 'react-i18next';

// React suite Icons
import { Icon } from '@rsuite/icons';
import FormField from '../../FormField/FormField';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';

const InputContainer = styled('div')(({ theme }) => ({
  width: 'auto',
  height: '2.25rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '10px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  '&:focus-within': {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}33`
  }
}));

const RadioButton = styled(Radio)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&.Mui-checked': {
    color: theme.palette.primary.main
  }
}));

const CheckBoxButton = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&.Mui-checked': {
    color: theme.palette.primary.main
  }
}));

const EnhancedTableToolbar: React.FC<{
  numSelected: number;
  title?: string;
  actionBtn?: () => void;
  secondActionBtn?: () => void;
  thirdActionBtn?: () => void;
  forceDisplayBtn?: boolean;
  actionBtnTitle?: string;
  secondActionBtnTitle?: string;
  thirdActionBtnTitle?: string;
  actionBtnDisabled?: boolean;
  secondActionBtnLabel?: string;
  searchbarPlaceholder?: string;
  onChangeSearchbar?: ChangeEventHandler<HTMLInputElement>;
  searchBarValue?: string;
  secondSearchBarPlaceholder?: string;
  onChangeSecondSearchbar?: ChangeEventHandler<HTMLInputElement>;
  secondSearchBarValue?: string;
  deleteSelected?: () => void;
  filterRadioValue?: any;
  filterRadioOptions?: any;
  filterRadioOnChange?: (value: string) => void;
  filterRadioName?: string;
  filterRadio2Value?: any;
  filterRadio2Options?: any;
  filterRadio2Name?: string;
  filterRadio2OnChange?: (value: string) => void;
  filterCheckBoxValues?: string[];
  filterCheckBoxOptions?: string[];
  filterCheckBoxOnChange?: (value: string) => void;
  filterFildSetCheckBoxName?: string;
  filterSelectValue?: any;
  filterSelectOptions?: any;
  filterSelectOnChange?: (value: string) => void;
  hideAddIcon?: any;
  secondFilterSelectValue?: any;
  secondFilterSelectOptions?: any;
  secondFilterSelectOnChange?: (value: string) => void;
  thirdFilterSelectValue?: any;
  thirdFilterSelectOptions?: any;
  thirdFilterSelectOnChange?: (value: string) => void;
  fourthFilterSelectValue?: any;
  fourthFilterSelectOptions?: any;
  fourthFilterSelectOnChange?: (value: string) => void;
  firstSelectPlaceholder?: string;
  secondSelectPlaceholder?: string;
  thirdSelectPlaceholder?: string;
  fourthSelectPlaceholder?: string;
  clearBtn?: boolean;
  onClearBtn?: () => void;
  hiddenBtnIcon?: boolean;
  datePickers?: boolean;
  fromDate?: Date | null;
  toDate?: Date | null;
  fromDatePickerOnChange?: (date: Date | null) => void;
  toDatePickerOnChange?: (date: Date | null) => void;
  disableActionBtn?: boolean;
  refreshBtnIcon?: boolean;
  dropDownButton?: boolean;
  dropDownButtonTitle?: string;
  dropDownActions?: any;
  dropDownActionClick?: (action: string) => void;
  fifthFilterSelectValue?: any;
  fifthFilterSelectOptions?: any;
  fifthFilterSelectOnChange?: (value: string) => void;
  styleToolBar?: 1 | 2 | 3 | 4;
  reportStyle?: boolean;
  hideSelectedCount?: boolean;
  dateTimePickers?: boolean;
}> = ({
  numSelected,
  title,
  actionBtn,
  secondActionBtn,
  forceDisplayBtn,
  actionBtnTitle,
  secondActionBtnTitle,
  actionBtnDisabled,
  secondActionBtnLabel,
  searchbarPlaceholder,
  onChangeSearchbar,
  searchBarValue,
  secondSearchBarPlaceholder,
  onChangeSecondSearchbar,
  secondSearchBarValue,
  deleteSelected,
  filterRadioValue,
  filterRadioOptions,
  filterRadioOnChange,
  filterRadioName,
  filterRadio2Value,
  filterRadio2Options,
  filterRadio2OnChange,
  filterRadio2Name,
  filterCheckBoxOptions,
  filterCheckBoxValues,
  filterCheckBoxOnChange,
  filterFildSetCheckBoxName,
  filterSelectValue,
  filterSelectOptions,
  filterSelectOnChange,
  hideAddIcon,
  secondFilterSelectValue,
  secondFilterSelectOptions,
  secondFilterSelectOnChange,
  thirdFilterSelectValue,
  thirdFilterSelectOptions,
  thirdFilterSelectOnChange,
  fourthFilterSelectValue,
  fourthFilterSelectOptions,
  fourthFilterSelectOnChange,
  firstSelectPlaceholder,
  secondSelectPlaceholder,
  thirdSelectPlaceholder,
  fourthSelectPlaceholder,
  clearBtn,
  onClearBtn,
  hiddenBtnIcon,
  datePickers,
  fromDate,
  toDate,
  fromDatePickerOnChange,
  toDatePickerOnChange,
  disableActionBtn,
  refreshBtnIcon,
  dropDownButton,
  dropDownButtonTitle,
  dropDownActions,
  dropDownActionClick,
  fifthFilterSelectValue,
  fifthFilterSelectOnChange,
  fifthFilterSelectOptions,
  thirdActionBtn,
  thirdActionBtnTitle,
  styleToolBar = 1,
  reportStyle,
  hideSelectedCount = false,
  dateTimePickers
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const open = Boolean(anchorEl);

  // Custom SVG
  const arrowsSVG = React.forwardRef((props, ref: any) => (
    <svg
      {...props}
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M89.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L370.3 160H320c-17.7 0-32 14.3-32 32s14.3 32 32 32H447.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L398.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C57.2 122 39.6 150.7 28.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM23 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L109.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H32.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
    </svg>
  ));

  const handleChange = (e: any) => {
    filterRadioOnChange && filterRadioOnChange(e.target.value);
  };

  const handleChange2 = (e: any) => {
    filterRadio2OnChange && filterRadio2OnChange(e.target.value);
  };

  const handleChangeCheckbox = (e: any) => {
    filterCheckBoxOnChange && filterCheckBoxOnChange(e.target.value);
  };

  const handleSelectChange = (e: any) => {
    filterSelectOnChange && filterSelectOnChange(e.target.value);
  };

  const handleSecondSelectChange = (e: any) => {
    secondFilterSelectOnChange && secondFilterSelectOnChange(e.target.value);
  };

  const handleThirdSelectChange = (e: any) => {
    thirdFilterSelectOnChange && thirdFilterSelectOnChange(e.target.value);
  };

  const handleFourthSelectChange = (e: any) => {
    fourthFilterSelectOnChange && fourthFilterSelectOnChange(e.target.value);
  };

  const hanldeFifthSelectChange = (e: any) => {
    fifthFilterSelectOnChange && fifthFilterSelectOnChange(e.target.value);
  };

  const handleFromDatePickerChange = (date: Date | null) => {
    fromDatePickerOnChange && fromDatePickerOnChange(date);
  };

  const handleToDatePickerChange = (date: Date | null) => {
    toDatePickerOnChange && toDatePickerOnChange(date);
  };

  // DropDownActionButton
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    action: string
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (dropDownActionClick) {
      dropDownActionClick(action);
    }
  };

  if (styleToolBar === 2) {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
          pt: { xs: 2, sm: 2 },
          backgroundColor: theme.palette.background.paper
        }}
      >
        <Stack
          width={'100%'}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignContent={'left'}
          flexWrap="wrap"
        >
          <Stack
            width={'100%'}
            direction={{ xs: 'row' }}
            justifyContent={'space-between'}
            alignContent={'left'}
          >
            <Typography
              alignSelf={'left'}
              variant="h6"
              id="tableTitle"
              component="div"
              marginBottom={2}
            >
              <Stack direction={{ xs: 'row', md: 'row' }} gap={1}>
                {searchbarPlaceholder && onChangeSearchbar && (
                  <MaterialSearchBar
                    placeholder={searchbarPlaceholder}
                    onChange={onChangeSearchbar || undefined}
                    value={searchBarValue}
                  />
                )}
                {secondSearchBarPlaceholder && onChangeSecondSearchbar && (
                  <MaterialSearchBar
                    placeholder={secondSearchBarPlaceholder}
                    onChange={onChangeSecondSearchbar || undefined}
                    hideIcon={true}
                    value={secondSearchBarValue}
                  />
                )}
                {filterSelectOptions && (
                  <Select
                    sx={{
                      width: '13.5rem'
                    }}
                    value={filterSelectValue}
                    onChange={(e: any) => handleSelectChange(e)}
                    options={filterSelectOptions}
                    optionName="name"
                    optionValue="value"
                    marginBottom="0.1"
                  />
                )}
                {secondFilterSelectOptions && (
                  <Select
                    sx={{
                      width: '13.5rem'
                    }}
                    value={secondFilterSelectValue}
                    onChange={(e: any) => handleSecondSelectChange(e)}
                    options={secondFilterSelectOptions}
                    optionName="name"
                    optionValue="value"
                    marginBottom="0.1"
                  />
                )}
                {thirdFilterSelectOptions && (
                  <Select
                    sx={{
                      width: '13.5rem'
                    }}
                    placeholder={thirdSelectPlaceholder}
                    value={thirdFilterSelectValue}
                    onChange={(e: any) => handleThirdSelectChange(e)}
                    options={thirdFilterSelectOptions}
                    optionName="name"
                    optionValue="value"
                    marginBottom="0.1"
                  />
                )}
              </Stack>
            </Typography>
          </Stack>

          <Stack
            width={'100%'}
            direction={{ xs: 'row' }}
            justifyContent={'space-between'}
            alignContent={'left'}
          >
            <Typography
              alignSelf={'left'}
              variant="h6"
              id="tableTitle"
              component="div"
              marginBottom={2}
            >
              <Stack direction={{ xs: 'row', md: 'row' }} gap={1}>
                {fourthFilterSelectOptions && (
                  <Select
                    sx={{
                      width: '13.5rem'
                    }}
                    value={fourthFilterSelectValue}
                    onChange={(e: any) => handleFourthSelectChange(e)}
                    options={fourthFilterSelectOptions}
                    optionName="name"
                    optionValue="value"
                    marginBottom="0.1"
                  />
                )}
                {fifthFilterSelectOptions && (
                  <Select
                    sx={{
                      width: '13.5rem'
                    }}
                    value={fifthFilterSelectValue}
                    onChange={(e: any) => hanldeFifthSelectChange(e)}
                    options={fifthFilterSelectOptions}
                    optionName="name"
                    optionValue="value"
                    marginBottom="0.1"
                  />
                )}
                {datePickers && (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                  >
                    <DatePicker
                      marginBottom={1}
                      value={fromDate}
                      error={dayjs(fromDate).isAfter(dayjs(toDate))}
                      helperText={
                        dayjs(fromDate).isAfter(dayjs(toDate))
                          ? 'From-date must be before to-date'
                          : ''
                      }
                      onChange={handleFromDatePickerChange}
                      id="table-datepicker-activation"
                    />
                    <Typography>to</Typography>
                    <DatePicker
                      marginBottom={1}
                      value={toDate}
                      error={dayjs(toDate).isBefore(dayjs(fromDate))}
                      helperText={
                        dayjs(toDate).isBefore(dayjs(fromDate))
                          ? 'To-date must be after from-date'
                          : ''
                      }
                      onChange={handleToDatePickerChange}
                      id="table-datepicker-expiration"
                    />
                  </Box>
                )}
              </Stack>
            </Typography>
          </Stack>

          <Stack
            width={'100%'}
            direction={{ xs: 'row', md: 'row' }}
            justifyContent={'center'}
            alignContent={'left'}
          >
            <>
              <Box
                sx={{
                  height: 35,
                  display: 'flex',
                  flexFlow: 'row',
                  gap: '4px'
                }}
                flexDirection={'row'}
              >
                {((actionBtnTitle && actionBtn) || forceDisplayBtn) && (
                  <>
                    <Button
                      variant="contained"
                      onClick={actionBtn}
                      disabled={disableActionBtn}
                      id={'action-btn-0'}
                    >
                      {!hideAddIcon && <AddIcon />}
                      {refreshBtnIcon && (
                        <Icon
                          as={arrowsSVG}
                          spin
                          style={{
                            fontSize: '18px',
                            alignSelf: 'center',
                            color: 'green'
                          }}
                        />
                      )}
                      {matches && <Box>{actionBtnTitle}</Box>}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ display: clearBtn ? 'block' : 'none' }}
                      onClick={onClearBtn}
                    >
                      {!hideAddIcon && <AddIcon />}
                      <Typography
                        children={`${t('tasks.general.clear')}`}
                        sx={{ fontWeight: 'bold' }}
                      ></Typography>
                    </Button>
                  </>
                )}
                {secondActionBtnTitle && secondActionBtn && (
                  <Button variant="contained" onClick={secondActionBtn} id={'action-btn-1'}>
                    {!hideAddIcon && <AddIcon />}
                    {matches && <Box>{secondActionBtnTitle}</Box>}
                  </Button>
                )}
                {thirdActionBtnTitle && thirdActionBtn && (
                  <Button variant="contained" onClick={thirdActionBtn} id={'action-btn-2'}>
                    {!hideAddIcon && <AddIcon />}
                    {matches && <Box>{thirdActionBtnTitle}</Box>}
                  </Button>
                )}
                {dropDownButton && (
                  <>
                    <Button variant="contained" onClick={handleClick}>
                      {dropDownButtonTitle}
                      <KeyboardArrowDownIcon
                        style={{ color: 'white', width: '0.8em' }}
                      />
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={(event: React.MouseEvent<HTMLElement>) =>
                        handleClose(event)
                      }
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                    >
                      {dropDownActions.map((ele: any) => {
                        return (
                          <MenuItem
                            key={ele.title}
                            onClick={(event: React.MouseEvent<HTMLElement>) =>
                              handleActionClick(event, ele.action)
                            }
                          >
                            {t(ele.title)}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </>
                )}
              </Box>
            </>
          </Stack>
        </Stack>
      </Toolbar>
    );
  }

  if (styleToolBar === 3) {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
          pt: { xs: 2, sm: 2 }
        }}
      >
        <Stack
          width={'100%'}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignContent={'left'}
          flexWrap="wrap"
        >
          <Stack width="100%" direction={'row'} flexWrap="wrap" gap={1}>
            {filterSelectOptions && (
              <Box>
                <Select
                  sx={{
                    width: '13.5rem'
                  }}
                  value={filterSelectValue}
                  onChange={(e: any) => handleSelectChange(e)}
                  options={filterSelectOptions}
                  optionName="name"
                  optionValue="value"
                  marginBottom="0.1"
                />
              </Box>
            )}
            {searchbarPlaceholder && onChangeSearchbar && (
              <Box>
                <Box>
                  <FormField
                    placeholder={searchbarPlaceholder}
                    onChange={onChangeSearchbar || undefined}
                    value={searchBarValue}
                  />
                </Box>
              </Box>
            )}
            {secondSearchBarPlaceholder && onChangeSecondSearchbar && (
              <Box>
                <Box>
                  <FormField
                    placeholder={secondSearchBarPlaceholder}
                    onChange={onChangeSecondSearchbar || undefined}
                    value={secondSearchBarValue}
                  />
                </Box>
              </Box>
            )}
            {filterRadioOptions && (
              <Box>
                <FormControl sx={{ height: '2rem' }}>
                  <FormLabel
                    sx={{
                      // background: "white",
                      position: 'absolute',
                      top: -10,
                      left: 10,
                      fontSize: '12px !important'
                    }}
                  >
                    {filterRadioName || 'options'}
                  </FormLabel>
                  <InputContainer sx={{border: '1px solid gray'}}>
                    <RadioGroup
                      aria-label="opciones"
                      name="opciones"
                      value={filterRadioValue}
                      onChange={(e) => handleChange(e)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      {filterRadioOptions.map((ele: any) => (
                        <FormControlLabel
                          key={ele.value}
                          value={ele.value}
                          control={<RadioButton />}
                          label={ele.name}
                        />
                      ))}
                    </RadioGroup>
                  </InputContainer>
                </FormControl>
              </Box>
            )}
            {filterRadio2Options && (
              <Box>
                <FormControl sx={{ height: '2rem' }}>
                  <FormLabel
                    sx={{
                      // background: "white",
                      position: 'absolute',
                      top: -10,
                      left: 10,
                      fontSize: '12px !important'
                    }}
                  >
                    {filterRadio2Name || 'options 2'}
                  </FormLabel>
                  <InputContainer sx={{border: '1px solid gray'}}>
                    <RadioGroup
                      aria-label="opciones2"
                      name="opciones2"
                      value={filterRadio2Value}
                      onChange={handleChange2}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      {filterRadio2Options.map((ele: any) => (
                        <FormControlLabel
                          key={ele.value}
                          value={ele.value}
                          control={<RadioButton />}
                          label={ele.name}
                        />
                      ))}
                    </RadioGroup>
                  </InputContainer>
                </FormControl>
              </Box>
            )}
            {filterCheckBoxOptions && (
              <Box>
                <FormControl sx={{ height: '2rem'}}>
                  <FormLabel
                    sx={{
                      // background: "white",
                      position: 'absolute',
                      top: -10,
                      left: 10,
                      fontSize: '12px !important'
                    }}
                  >
                    {filterFildSetCheckBoxName || 'Checboxes options'}
                  </FormLabel>
                  <InputContainer sx={{border: '1px solid gray'}}>
                    <FormGroup
                      aria-label="opciones2"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      {filterCheckBoxOptions.map((ele: any) => (
                        <FormControlLabel
                          key={ele.value}
                          value={ele.value}
                          control={
                            <CheckBoxButton
                              checked={filterCheckBoxValues?.includes(
                                ele.value
                              )}
                              onChange={handleChangeCheckbox}
                              value={ele.value}
                            />
                          }
                          label={ele.name}
                          // onChange={filterCheckBoxOnChange}
                        />
                      ))}
                    </FormGroup>
                  </InputContainer>
                </FormControl>
              </Box>
            )}
          </Stack>
          <Stack
            width={'100%'}
            direction={{ xs: 'row' }}
            justifyContent={'space-between'}
            alignContent={'left'}
          >
            <Typography
              alignSelf={'left'}
              variant="h6"
              id="tableTitle"
              component="div"
              marginBottom={2}
            >
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                direction={{ xs: 'row', md: 'row' }}
                gap={1}
              >
                {secondFilterSelectOptions && (
                  <Select
                    sx={{
                      width: '13.5rem'
                    }}
                    value={secondFilterSelectValue}
                    onChange={(e: any) => handleSecondSelectChange(e)}
                    options={secondFilterSelectOptions}
                    optionName="name"
                    optionValue="value"
                    marginBottom="0.1"
                  />
                )}
                {thirdFilterSelectOptions && (
                  <Select
                    sx={{
                      width: '13.5rem'
                    }}
                    placeholder={thirdSelectPlaceholder}
                    value={thirdFilterSelectValue}
                    onChange={(e: any) => handleThirdSelectChange(e)}
                    options={thirdFilterSelectOptions}
                    optionName="name"
                    optionValue="value"
                    marginBottom="0.1"
                  />
                )}
                {fourthFilterSelectOptions && (
                  <Select
                    value={fourthFilterSelectValue}
                    onChange={(e: any) => handleFourthSelectChange(e)}
                    options={fourthFilterSelectOptions}
                    optionName="name"
                    optionValue="value"
                    marginBottom="0.1"
                  />
                )}
                {datePickers && (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                  >
                    <DatePicker
                      marginBottom={1}
                      value={fromDate}
                      error={dayjs(fromDate).isAfter(dayjs(toDate))}
                      helperText={
                        dayjs(fromDate).isAfter(dayjs(toDate))
                          ? 'From-date must be before to-date'
                          : ''
                      }
                      onChange={handleFromDatePickerChange}
                      id="table-datepicker-activation"
                    />
                    <Typography>to</Typography>
                    <DatePicker
                      marginBottom={1}
                      value={toDate}
                      error={dayjs(toDate).isBefore(dayjs(fromDate))}
                      helperText={
                        dayjs(toDate).isBefore(dayjs(fromDate))
                          ? 'To-date must be after from-date'
                          : ''
                      }
                      onChange={handleToDatePickerChange}
                      id="table-datepicker-expiration"
                    />
                  </Box>
                )}
              </Stack>
            </Typography>
          </Stack>
          <Stack
            width="100%"
            direction={'row'}
            flexWrap="wrap"
            gap={1}
            justifyContent={'center'}
          >
            <>
              <Box
                sx={{
                  height: 35,
                  display: 'flex',
                  flexFlow: 'row',
                  gap: '4px'
                }}
                flexDirection={'row'}
              >
                {((actionBtnTitle && actionBtn) || forceDisplayBtn) && (
                  <>
                    <Button
                      variant="contained"
                      onClick={actionBtn}
                      disabled={disableActionBtn}
                      id={'action-btn-0'}
                    >
                      {!hideAddIcon && <AddIcon />}
                      {refreshBtnIcon && (
                        <Icon
                          as={arrowsSVG}
                          spin
                          style={{
                            fontSize: '18px',
                            alignSelf: 'center',
                            color: 'green'
                          }}
                        />
                      )}
                      {matches && <Box>{actionBtnTitle}</Box>}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ display: clearBtn ? 'block' : 'none' }}
                      onClick={onClearBtn}
                    >
                      {!hideAddIcon && <AddIcon />}
                      <Typography
                        children={`${t('tasks.general.clear')}`}
                        sx={{ fontWeight: 'bold' }}
                      ></Typography>
                    </Button>
                  </>
                )}
                {secondActionBtnTitle && secondActionBtn && (
                  <Button variant="contained" onClick={secondActionBtn} id={'action-btn-1'}>
                    {!hideAddIcon && <AddIcon />}
                    <Box>{secondActionBtnTitle}</Box>
                  </Button>
                )}
                {thirdActionBtnTitle && thirdActionBtn && (
                  <Button variant="contained" onClick={thirdActionBtn} id={'action-btn-2'}>
                    {!hideAddIcon && <AddIcon />}
                    <Box>{thirdActionBtnTitle}</Box>
                  </Button>
                )}
                {dropDownButton && (
                  <>
                    <Button variant="contained" onClick={handleClick}>
                      {dropDownButtonTitle}
                      <KeyboardArrowDownIcon
                        style={{ color: 'white', width: '0.8em' }}
                      />
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={(event: React.MouseEvent<HTMLElement>) =>
                        handleClose(event)
                      }
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                    >
                      {dropDownActions.map((ele: any) => {
                        return (
                          <MenuItem
                            key={ele.title}
                            onClick={(event: React.MouseEvent<HTMLElement>) =>
                              handleActionClick(event, ele.action)
                            }
                          >
                            {t(ele.title)}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </>
                )}
              </Box>
            </>
          </Stack>
        </Stack>
      </Toolbar>
    );
  } 

  //Floor plan style
  if (styleToolBar === 4) {
    return (
      <Toolbar
        sx={{
          pl: { sm: 0 },
          pr: { xs: 1, sm: 1 },
          pt: { xs: 1, sm: 1 }
        }}
      >
        <Stack
          width={'100%'}
          direction={{ xs: 'column', md: 'column' }}
          justifyContent={'space-between'}
          alignContent={'left'}
          flexWrap="wrap"
        >
          <Typography
            alignSelf={'left'}
            variant="h6"
            id="tableTitle"
            component="div"
            marginBottom={2}
          >
            <Stack direction={{ xs: 'row', md: 'row' }} gap={1} flexWrap="wrap">
              {searchbarPlaceholder && onChangeSearchbar && (
                <MaterialSearchBar
                  width="13rem"
                  placeholder={searchbarPlaceholder}
                  onChange={onChangeSearchbar || undefined}
                  value={searchBarValue}
                />
              )}
              {secondFilterSelectOptions && (
                <Select
                  value={secondFilterSelectValue}
                  onChange={(e: any) => handleSecondSelectChange(e)}
                  options={secondFilterSelectOptions}
                  optionName="name"
                  optionValue="value"
                  marginBottom="0.1"
                  id="filter-select-2"
                  sx={{width: '115px'}}
                />
              )}
              
              {datePickers && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <DatePicker
                    marginBottom={1}
                    value={fromDate}
                    error={dayjs(fromDate).isAfter(dayjs(toDate))}
                    helperText={
                      dayjs(fromDate).isAfter(dayjs(toDate))
                        ? 'From-date must be before to-date'
                        : ''
                    }
                    onChange={handleFromDatePickerChange}
                    id="table-datepicker-activation"
                  />
                  <Typography>to</Typography>
                  <DatePicker
                    marginBottom={1}
                    value={toDate}
                    error={dayjs(toDate).isBefore(dayjs(fromDate))}
                    helperText={
                      dayjs(toDate).isBefore(dayjs(fromDate))
                        ? 'To-date must be after from-date'
                        : ''
                    }
                    onChange={handleToDatePickerChange}
                    id="table-datepicker-expiration"
                  />
                </Box>
              )}
            </Stack>
          </Typography>
          {!hideSelectedCount && numSelected > 0 ? (
            <Stack direction={'row'} gap={2}>
              <Typography
                alignSelf={'center'}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                <strong>{numSelected} selected</strong>
              </Typography>
            </Stack>
          ) : (
            <Stack direction={'row'}>
              {((actionBtnTitle && actionBtn) || forceDisplayBtn) && (
                <>
                  <Button
                    variant="contained"
                    onClick={actionBtn}
                    disabled={disableActionBtn}
                    id={'action-btn-0'}
                  >
                    {!hideAddIcon && <AddIcon />}
                    {refreshBtnIcon && (
                      <Icon
                        as={arrowsSVG}
                        spin
                        style={{
                          fontSize: '18px',
                          alignSelf: 'center',
                          color: 'green'
                        }}
                      />
                    )}
                    {matches && <Box>{actionBtnTitle}</Box>}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ display: clearBtn ? 'block' : 'none' }}
                    onClick={onClearBtn}
                  >
                    {!hideAddIcon && <AddIcon />}
                    <Typography
                      children={`${t('tasks.general.clear')}`}
                      sx={{ fontWeight: 'bold' }}
                    ></Typography>
                  </Button>
                </>
              )}
              {secondActionBtnTitle && secondActionBtn && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Button variant="contained" onClick={secondActionBtn} id={'action-btn-1'}>
                    {!hideAddIcon && <AddIcon />}
                    <Box>{secondActionBtnTitle}</Box>
                  </Button>
                  {secondActionBtnLabel && (
                    <Typography textAlign="center" marginTop={1} fontSize={12}>
                      {secondActionBtnLabel}
                    </Typography>
                  )}
                </Box>
              )}
              {thirdActionBtnTitle && thirdActionBtn && (
                <Button variant="contained" onClick={thirdActionBtn} id={'action-btn-2'}>
                  {!hideAddIcon && <AddIcon />}
                  <Box>{thirdActionBtnTitle}</Box>
                </Button>
              )}
              {dropDownButton && (
                <>
                  <Button variant="contained" onClick={handleClick}>
                    {dropDownButtonTitle}
                    <KeyboardArrowDownIcon
                      style={{ color: 'white', width: '0.8em' }}
                    />
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={(event: React.MouseEvent<HTMLElement>) =>
                      handleClose(event)
                    }
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                  >
                    {dropDownActions.map((ele: any) => {
                      return (
                        <MenuItem
                          key={ele.title}
                          onClick={(event: React.MouseEvent<HTMLElement>) =>
                            handleActionClick(event, ele.action)
                          }
                        >
                          {t(ele.title)}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </>
              )}
            </Stack>
          )}
        </Stack>
      </Toolbar>
    );
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        pt: { xs: 2, sm: 2 }
      }}
    >
      <Stack
        width={'100%'}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={'space-between'}
        alignContent={'left'}
        flexWrap="wrap"
      >
        <Typography
          alignSelf={'left'}
          variant="h6"
          id="tableTitle"
          component="div"
          marginBottom={2}
        >
          <Stack direction={{ xs: 'row', md: 'row' }} gap={1}>
            {searchbarPlaceholder && onChangeSearchbar && (
              <MaterialSearchBar
                width="17rem"
                placeholder={searchbarPlaceholder}
                onChange={onChangeSearchbar || undefined}
                value={searchBarValue}
              />
            )}
            {secondSearchBarPlaceholder && onChangeSecondSearchbar && (
              <MaterialSearchBar
                placeholder={secondSearchBarPlaceholder}
                onChange={onChangeSecondSearchbar || undefined}
                hideIcon={true}
                value={secondSearchBarValue}
              />
            )}
            {filterSelectOptions && (
              <Select
                value={filterSelectValue}
                onChange={(e: any) => handleSelectChange(e)}
                options={filterSelectOptions}
                optionName="name"
                optionValue="value"
                marginBottom="0.1"
                id="filter-select-1"
              />
            )}
            {secondFilterSelectOptions && (
              <Select
                value={secondFilterSelectValue}
                onChange={(e: any) => handleSecondSelectChange(e)}
                options={secondFilterSelectOptions}
                optionName="name"
                optionValue="value"
                marginBottom="0.1"
                id="filter-select-2"
              />
            )}
            {thirdFilterSelectOptions && (
              <Select
                placeholder={thirdSelectPlaceholder}
                value={thirdFilterSelectValue}
                onChange={(e: any) => handleThirdSelectChange(e)}
                options={thirdFilterSelectOptions}
                optionName="name"
                optionValue="value"
                marginBottom="0.1"
                id="filter-select-3"
              />
            )}
            {fourthFilterSelectOptions && (
              <Select
                value={fourthFilterSelectValue}
                onChange={(e: any) => handleFourthSelectChange(e)}
                options={fourthFilterSelectOptions}
                optionName="name"
                optionValue="value"
                marginBottom="0.1"
                id="filter-select-4"
              />
            )}
            {fifthFilterSelectOptions && (
              <Select
                value={fifthFilterSelectValue}
                onChange={(e: any) => hanldeFifthSelectChange(e)}
                options={fifthFilterSelectOptions}
                optionName="name"
                optionValue="value"
                marginBottom="0.1"
                id="filter-select-5"
              />
            )}
            {datePickers && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <DatePicker
                  marginBottom={1}
                  value={fromDate}
                  error={dayjs(fromDate).isAfter(dayjs(toDate))}
                  helperText={
                    dayjs(fromDate).isAfter(dayjs(toDate))
                      ? 'From-date must be before to-date'
                      : ''
                  }
                  onChange={handleFromDatePickerChange}
                  id="table-datepicker-activation"
                />
                <Typography>to</Typography>
                <DatePicker
                  marginBottom={1}
                  value={toDate}
                  error={dayjs(toDate).isBefore(dayjs(fromDate))}
                  helperText={
                    dayjs(toDate).isBefore(dayjs(fromDate))
                      ? 'To-date must be after from-date'
                      : ''
                  }
                  onChange={handleToDatePickerChange}
                  id="table-datepicker-expiration"
                />
              </Box>
            )}
            {dateTimePickers && (
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                <DateTimePicker
                  marginBottom={1}
                  value={fromDate}
                  error={dayjs(fromDate).isAfter(dayjs(toDate))}
                  helperText={
                    dayjs(fromDate).isAfter(dayjs(toDate))
                      ? 'From-date must be before to-date'
                      : ''
                  }
                  onChange={handleFromDatePickerChange}
                  id="table-datepicker-activation"
                />
                <Typography>to</Typography>
                <DateTimePicker
                  marginBottom={1}
                  value={toDate}
                  error={dayjs(toDate).isBefore(dayjs(fromDate))}
                  helperText={
                    dayjs(toDate).isBefore(dayjs(fromDate))
                      ? 'To-date must be after from-date'
                      : ''
                  }
                  onChange={handleToDatePickerChange}
                  id="table-datepicker-expiration"
                />
              </Box>
            )}
            {filterRadioOptions && (
              <RadioGroup
                value={filterRadioValue}
                onChange={(e) => handleChange(e)}
              >
                <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
                  {filterRadioOptions.map((ele: any) => (
                    <FormControlLabel
                      key={ele.value}
                      value={ele.value}
                      control={<Radio id={`radio-${ele.value}`}/>}
                      label={ele.name}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            )}
          </Stack>
        </Typography>
        {!hideSelectedCount && numSelected > 0 ? (
          <Stack direction={'row'} gap={2}>
            <Typography
              alignSelf={'center'}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              <strong>{numSelected} selected</strong>
            </Typography>
          </Stack>
        ) : (
          <Stack sx={{ height: 35 }} direction={'row'}>
            {((actionBtnTitle && actionBtn) || forceDisplayBtn) && (
              <>
                <Button
                  variant="contained"
                  onClick={actionBtn}
                  disabled={disableActionBtn}
                  id={'action-btn-0'}
                >
                  {!hideAddIcon && <AddIcon />}
                  {refreshBtnIcon && (
                    <Icon
                      as={arrowsSVG}
                      spin
                      style={{
                        fontSize: '18px',
                        alignSelf: 'center',
                        color: 'green'
                      }}
                    />
                  )}
                  {matches && <Box>{actionBtnTitle}</Box>}
                </Button>
                <Button
                  variant="contained"
                  sx={{ display: clearBtn ? 'block' : 'none' }}
                  onClick={onClearBtn}
                >
                  {!hideAddIcon && <AddIcon />}
                  <Typography
                    children={`${t('tasks.general.clear')}`}
                    sx={{ fontWeight: 'bold' }}
                  ></Typography>
                </Button>
              </>
            )}
            {secondActionBtnTitle && secondActionBtn && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button variant="contained" onClick={secondActionBtn} id={'action-btn-1'}>
                  {!hideAddIcon && <AddIcon />}
                  <Box>{secondActionBtnTitle}</Box>
                </Button>
                {secondActionBtnLabel && (
                  <Typography textAlign="center" marginTop={1} fontSize={12}>
                    {secondActionBtnLabel}
                  </Typography>
                )}
              </Box>
            )}
            {thirdActionBtnTitle && thirdActionBtn && (
              <Button variant="contained" onClick={thirdActionBtn} id={'action-btn-2'}>
                {!hideAddIcon && <AddIcon />}
                <Box>{thirdActionBtnTitle}</Box>
              </Button>
            )}
            {dropDownButton && (
              <>
                <Button variant="contained" onClick={handleClick}>
                  {dropDownButtonTitle}
                  <KeyboardArrowDownIcon
                    style={{ color: 'white', width: '0.8em' }}
                  />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(event: React.MouseEvent<HTMLElement>) =>
                    handleClose(event)
                  }
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  {dropDownActions.map((ele: any) => {
                    return (
                      <MenuItem
                        key={ele.title}
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          handleActionClick(event, ele.action)
                        }
                      >
                        {t(ele.title)}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            )}
          </Stack>
        )}
      </Stack>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
