const resources = {
  table: {
    product: 'Product',
    category: 'Category',
    description: 'Description',
    status: 'Status',
    date: 'Date',   
    actions: 'Actions'
  },
  form: {
    product: 'Product',
    category: 'Category',
    description: 'Description',
    content: 'Content',
    url: 'URL'   
  },
  attachments: 'Attachments',
  files: 'Files',
  create_new: 'Create New',
  search_resources: 'Search resources',
  resources: 'Resources',
  add_new_resource: 'Add New Resource',
  drag_drop: 'Drag and drop your file here or'
}

export default resources;
