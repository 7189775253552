import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlansList from '../../Organizations/CreateTabs/PlansList/PlansList';
import { Plans } from '../../Organizations/interfaces/plans.interface';
import { getSubscriptions, postDealerSubscription } from '../../../api/api';
import Doors from '../../Organizations/CreateTabs/Doors/Doors';
import user from '../../../interfaces/user.interface';
import { useSelector } from '../../../store';
import CheckoutForm from '../../../components/CheckoutForm/CheckoutForm';
import useAlert from '../../../utils/hooks/useAlert';
import Modal from '../../../components/Modal/Modal';
import PaymentList from '../../Organizations/CreateTabs/PaymentList/PaymentList';

interface props {
  finish: () => void;
  hasPayment: boolean;
  open: boolean;
  onClose: () => void;
}

let paymentId: string = 'bd4d6535-4b2a-11ee-8c41-e7f71ef1b2db';

const MyPlanTabs = ({ finish, hasPayment, open, onClose }: props) => {
  const { t } = useTranslation();
  const user: user = useSelector((store) => store.user.user);
  const { errorAlert, successAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [doors, setDoors] = useState<number>(0);
  // const [mobiles, setMobiles] = useState<number>(0);
  const [plans, setPlans] = useState([]);
  const [cdvi_plan, setCdvi_plan] = useState<Plans | undefined>();
  const [payment_method, setPayment_method] = useState<string>('');
  const [type_payment, setType_payment] = useState<'month' | 'year'>('month');


  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const { plans } = await getSubscriptions();
      setCdvi_plan(undefined);
      setPlans(plans);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckout = async (skip?: boolean) => {
    setLoading(true);
    try {
      // dealer: user.selectedDealer?.dealer || user.currentProfile,
      const body = {
        doors: doors,
        mobilepass: 0,
        cdvi_plan: cdvi_plan?.cdvi_plan,
        type_payment: type_payment,
        payment_method: payment_method,
        amount: cdvi_plan
          ? type_payment === 'year'
            ? +cdvi_plan?.year_price * doors
            : +cdvi_plan?.month_price * doors
          : 0
      };
      const { payment } = await postDealerSubscription(
        user.selectedDealer?.dealer || user.currentProfile,
        body
      );
      paymentId = payment;
      setCheckout(true);
      skip && finish();
    } catch (error: any) {
      errorAlert(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSuccess = (msg: string) => {
    successAlert(msg);
  };

  const onError = (msg: string) => {
    errorAlert(msg);
  };

  const returnDoors = () => (
    <Doors
      step={value}
      setStep={setValue}
      selectedPlan={cdvi_plan as Plans}
      type_payment={type_payment}
      doors={doors}
      setDoors={setDoors}
      payment_method={payment_method}
      setPayment_method={setPayment_method}
      loading={loading}
      checkout={handleCheckout}
      finish={finish}
      paymentId={paymentId}
      isCheckout={checkout}
    />
  );

  const tabs = [
    {
      label: t('organization.payment_methods'),
      component: (
        <>
          <Stack direction="row" gap={2}>
            <Box flex={1}>
              <Typography component="div" gutterBottom>
                {t('dealers.card_enrollment')}
              </Typography>
              <PaymentList
                displayTitle={false}
                addCard={false}
                payment_method={payment_method}
                setPayment_method={setPayment_method}
                fullWidth
              />
            </Box>
            <Box flex={1}>
              <Typography component="div" gutterBottom>
                {t('dealers.card_information')}
              </Typography>
              <CheckoutForm
                onSuccess={(msg) => {
                  msg && onSuccess(msg);
                  finish();
                }}
                disabled={Boolean(payment_method)}
                onError={onError}
                onCancel={onClose}
              />
            </Box>
          </Stack>
        </>
      )
    },
    {
      label: 'Plans',
      component: (
        <PlansList
          plans={plans}
          selectedPlan={cdvi_plan as any}
          setSelectedPlan={setCdvi_plan}
          type_payment={type_payment}
          setType_payment={setType_payment}
          next={() => setValue(2)}
          previous={() => setValue(0)}
        />
      )
    },
    {
      label: `${t('doors.doors')}`,
      component: returnDoors()
    },
    {
      label: t('general.btn.finish'),
      component: returnDoors()
    }
  ];

  return (
    <Modal
      open={open}
      width="md"
      onClose={checkout ? finish : onClose}
      title={t('organization.payment_methods')}
    >
      <Box width="100%">
        {tabs[0].component}
        {/* <Tabs
          children={tabs}
          newValue={value}
          onTabChange={(n) => setValue(n)}
          disabled
        /> */}
      </Box>
    </Modal>
  );
};

export default MyPlanTabs;
