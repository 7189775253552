import React from 'react';
// MUI
import { IconButton, Theme, useMediaQuery } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/system';

interface props {
  open: boolean;
  handleDrawer: () => void;
}

const NavbarButton = ({ open, handleDrawer }: props) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const theme = useTheme();
  return (
    <IconButton
      className="navBtn"
      sx={{
        left: !open ? (!matches ? 10 : 60) : 255
      }}
      onClick={handleDrawer}
    >
      {!open ? (
        !matches ? (
          <MenuIcon />
        ) : (
          <ChevronRightIcon sx={{color: 'gray !important'}} />
        )
      ) : (
        <ChevronLeftIcon sx={{color: 'gray !important'}} />
      )}
    </IconButton>
  );
};

export default NavbarButton;
