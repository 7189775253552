/*
 * Description: Actions for the support session Redux store
 * Created by: Diego Rincón <drincon@binwus.com>
 * Created at: 2024-06-18
 */

// Interfaces
import { OpenSession } from '../../interfaces/supportTool.interface';

// Actions
export const SET_CURRENT_SESSION = 'SET_CURRENT_SESSION';

// Action creators
export const setCurrentSession = (session: OpenSession | undefined) => ({
  type: SET_CURRENT_SESSION,
  session
});
