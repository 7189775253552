import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Stack,
  ListItemButton,
  Tooltip
} from '@mui/material';
// Interfaces
import route from '../../interfaces/route.interface';
import { useTranslation } from 'react-i18next';
import routes from '../../routes/routes';

import site from '../../assets/images/menu_icons/site.png';
import controller from '../../assets/images/menu_icons/controllers30.png';
import door from '../../assets/images/menu_icons/door.png';
import holiday from '../../assets/images/menu_icons/holiday.png';
import schedule from '../../assets/images/menu_icons/schedule.png';
import accesslevel from '../../assets/images/menu_icons/accesslevel.png';
import users from '../../assets/images/menu_icons/users.png';
import card from '../../assets/images/menu_icons/card.png';
import organization from '../../assets/images/dashboard_icons/organization30.png';
import dealer from '../../assets/images/dashboard_icons/dealer30.png';
import lockdown from '../../assets/images/menu_icons/lockdown.png';
import input from '../../assets/images/menu_icons/input.png';
import output from '../../assets/images/menu_icons/output.png';
import hardware from '../../assets/images/menu_icons/hardware.png';
import accountInfo from '../../assets/images/menu_icons/account_info.png';
import siteInfo from '../../assets/images/menu_icons/site_info.png';
import firmwareManager from '../../assets/images/menu_icons/firmware_manager.png';
import userReport from '../../assets/images/menu_icons/user_report.png';
import cardReport from '../../assets/images/menu_icons/card_report.png';
import eventsReport from '../../assets/images/menu_icons/event_report.png';
import global from '../../assets/images/menu_icons/global.png';
import floorPlan from '../../assets/images/menu_icons/floor1.png';
import interlocks from '../../assets/images/dashboard_icons/interlocks.png';
import events from '../../assets/images/menu_icons/events.png';
import sdtNewSession from '../../assets/images/menu_icons/sdt_newsession.png';
import sdtOpenSessions from '../../assets/images/menu_icons/sdt_opensessions.png';
import sdtDiagnoseController from '../../assets/images/menu_icons/diagnose_controller.png';
import sdtCurrentSession from '../../assets/images/menu_icons/sdt_currentsession.png';

interface NavigationMenuProps {
  routes?: Array<any>;
}

const names = ['hardware', 'orgusers_report', 'orgcards_report'];

const icon = [
  routes.sites_list,
  routes.controller_list,
  routes.door_list,
  routes.holiday_list,
  routes.schedule_list,
  routes.access_level,
  routes.user_list,
  routes.user_org_list,
  routes.card_list,
  routes.organization_list,
  routes.dealer_list,
  routes.organization_profile,
  routes.lockdown,
  routes.input_list,
  routes.output_list,
  routes.organization_info,
  routes.site_dashboard,
  routes.firmware_manager,
  routes.report_events,
  routes.global_settings,
  routes.floor_plan,
  routes.interlocks_list,
  routes.event_list,
  routes.support_new_session,
  routes.support_open_sessions,
  routes.support_current_session,
  routes.support_diagnose_controller
];

const NavigationMenuMini = ({ routes = [] }: NavigationMenuProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getIcon = (icon?: string) => {
    return (
      <img
        style={{ filter: 'invert(85%)' }}
        src={
          process.env.PUBLIC_URL +
          `/icons/duotune/${icon || 'arrows/arr089.svg'}`
        }
        alt={icon}
      />
    );
  };

  const returnImg = (src: any, width?: number | string, filter?: string) => {
    return (
      <img
        style={{
          marginRight: '.8em',
          width: width || '',
          filter: filter || 'brightness(130%)'
        }}
        src={src}
        alt="Navigation icon"
      />
    );
  };

  const customIcon = (node: any) => {
    switch (node.url) {
    case icon[0]:
      return returnImg(site, 26);
    case icon[1]:
      return returnImg(controller, 24);
    case icon[2]:
      return returnImg(door);
    case icon[3]:
      return returnImg(holiday);
    case icon[4]:
      return returnImg(schedule);
    case icon[5]:
      return returnImg(accesslevel);
    case icon[6]:
    case icon[7]:
      return returnImg(users);
    case icon[8]:
      return returnImg(card);
    case icon[9]:
    case icon[11]:
      return returnImg(organization, 25, 'brightness(265%) grayscale(1)');
    case icon[10]:
      return returnImg(dealer, 25, 'brightness(265%) grayscale(1)');
    case icon[12]:
      return returnImg(lockdown, 24);
    case icon[13]:
      return returnImg(input, 24);
    case icon[14]:
      return returnImg(output, 24);
    case icon[15]:
      return returnImg(accountInfo, 24);
    case icon[16]:
      return returnImg(siteInfo, 24);
    case icon[17]:
      return returnImg(firmwareManager, 24);
    case icon[18]:
      return returnImg(eventsReport, 24);
    case icon[19]:
      return returnImg(global, 24);
    case icon[20]:
      return returnImg(floorPlan, 24);
    case icon[21]:
      return returnImg(interlocks, 24, 'invert(100%)');
    case icon[22]:
      return returnImg(events, 24);
      case icon[23]: 
        return returnImg(sdtNewSession, 24);
      case icon[24]:
        return returnImg(sdtOpenSessions, 24);
      case icon[25]:
        return returnImg(sdtCurrentSession, 24);
      case icon[26]:
        return returnImg(sdtDiagnoseController, 24); 
    default:
      switch (node.name.toLocaleLowerCase()) {
      case 'hardware':
        return returnImg(hardware, 25);
      case 'orgusers_report':
        return returnImg(userReport, 24);
      case 'orgcards_report':
        return returnImg(cardReport, 24);
      }
    }
  };

  // Recursive function for rendering route children
  const renderChildren = (
    route: route
  ): JSX.Element | JSX.Element[] | ReactNode => {
    return route.children?.map((child_route: route) => {
      const hasChild = child_route.children && child_route.children?.length > 0;
      return (
        <Stack
          key={child_route.id}
          sx={{ width: '100%' }}
          direction="column"
          alignContent="center"
          justifyContent="center"
        >
          {!hasChild && (
            <Box alignSelf="center">
              <Tooltip title={`${t(child_route.name)}`} placement="right">
                <ListItemButton
                  className={`listItemButtonMini ${
                    window.location.pathname === child_route.url && 'selected'
                  }`}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    !hasChild && navigate(child_route.url.substring(1));
                  }}
                >
                  {icon.includes(child_route.url) ||
                  names.includes(child_route.name.toLocaleLowerCase())
                    ? customIcon(child_route)
                    : getIcon(child_route.icon)}
                </ListItemButton>
              </Tooltip>
            </Box>
          )}

          {renderChildren(child_route)}
        </Stack>
      );
    });
  };

  return (
    <Box paddingTop={3}>
      {routes.map((route: route) => (
        /* const isOpen = selectedMenu === route.id;*/ <>
          <Stack
            key={route.id}
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'transparent',
              paddingBottom: 0
            }}
            direction="column"
            alignContent="center"
            justifyContent="center"
          >
            <Box
              alignSelf="center"
              width={35}
              display="flex"
              justifyContent="center"
            >
              {getIcon()}
            </Box>
            {renderChildren(route)}
          </Stack>
        </>
      ))}
    </Box>
  );
};

export default NavigationMenuMini;
