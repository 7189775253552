const login = {
  log_into_account: 'Connectez-vous à votre compte',
  log_msg: 'Entrez votre nom d\'utilisateur et votre mot de passe',
  username: 'Nom d\'utilisateur',
  password: 'Mot de passe',
  remember_me: 'Souviens-toi de moi',
  login: 'Connexion',
  sign_in: 'S\'identifier',
  click_msg: 'En cliquant sur Se connecter, vous acceptez nos',
  terms: 'Termes et conditions',
  and: 'et',
  privacy: 'Politique de confidentialité',
  account_msg: 'Vous n\'avez pas de compte ?',
  register_here: 'Inscrivez-vous ici',
  forgot_password: 'Mot de passe oublié?',
  trust_device: 'Appareil de confiance',
  otp_desc: 'Un mot de passe à usage unique a été envoyé à votre adresse e-mail, veuillez vérifier votre boîte de réception'
};

export default login;
