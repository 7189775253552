/*
 * Description: typography styling for lightMode theme
 * Remarkable changes: refactoring for cleaner code
*/

import colors from '../assets/scss/_themes-vars.module.scss';

export default function themeTypography(theme: any) {
  return {
    fontFamily: theme?.customization?.fontFamily,
    h6: {
      fontSize: '0.75rem',
      fontWeight: 500
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 500
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 600
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700
    },
    h1: {
      fontSize: '2.125rem',
      fontWeight: 700
    },
    subtitle1: {
      fontSize: '0.875rem',
      color: colors.grey700,
      fontWeight: 500
    },
    caption: {
      fontSize: '0.75rem',
      color: colors.grey700,
      fontWeight: 400
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: '1.334em'
    },
    body2: {
      letterSpacing: '0em',
      fontWeight: 400,
      lineHeight: '1.5em'
    },
    button: {
      textTransform: 'capitalize'
    }
  };
}
