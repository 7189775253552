import { useEffect, useState } from 'react';
// MUI
import {
  Box,
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Stack
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { postOtp, postUserActivate } from '../../../api/api';
import useAlert from '../../../utils/hooks/useAlert';
import { useNavigate, useParams } from 'react-router-dom';
import { removeUser } from '../../../store/actions/user';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormField from '../../../components/FormField/FormField';
import logo from '../../../assets/images/media/logos/CDVI_CLOUD4.png'
import { blue } from '@mui/material/colors';
import Countdown from '../../../components/Countdown/Countdown';
import { useDispatch } from '../../../store';

const Activate = () => {
  const expireTime = 300;

  const { t, i18n } = useTranslation();
  const { _dealer } = useParams();
  const navigate = useNavigate();
  const { errorAlert, successAlert } = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();
  // get query params
  const queryParams = queryString.parse(location.search);

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(
    queryParams?.email ? queryParams.email : ''
  );
  const [catpchaValidated, setCatpchaValidated] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disabledResend, setDisabledResend] = useState(true);
  const [timeLeft, setTimeLeft] = useState(expireTime);

  const hasUpper = /[A-Z]/;
  const hasLower = /[a-z]/;
  const hasNumber = /\d/;
  const hasCharacters = /[-!@#$%^&*()_]/;
  const onlyNumbers = /^[0-9]*$/;

  useEffect(() => {
    if (timeLeft > 0) {
      const countdownInterval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);

      return () => clearInterval(countdownInterval);
    } else if (timeLeft === 0) {
      setDisabledResend(false);
    }
  }, [timeLeft]);

  useEffect(() => {
    setDisableBtn(
      !(
        password.length >= 8 &&
        password.length <= 24 &&
        hasLower.test(password) &&
        hasUpper.test(password) &&
        hasNumber.test(password) &&
        hasCharacters.test(password) &&
        password === confirmPassword &&
        catpchaValidated
      )
    );
  }, [password, confirmPassword, catpchaValidated]);

  const returnText = (
    text: string,
    validation: boolean,
    icon: boolean = true
  ) => (
    <Typography
      color={validation ? 'green' : ''}
      fontSize="small"
      component="div"
    >
      {icon && (
        <CheckCircleIcon
          sx={{
            fontSize: 14,
            color: validation ? 'success' : 'gray',
            mb: -0.3
          }}
        />
      )}{' '}
      {text}
    </Typography>
  );

  const steps = [
    {
      label: t('dealers.validate.approved_email'),
      description: (
        <FormField
          sx={{backgroundColor: '#F2F2F2'}}
          disabled
          label={t('forgotPassword.email')}
          value={String(email)}
          onChange={(e: any) => setEmail(e.target.value)}
        />
        // <TextField
        //   id="email"
        //   fullWidth
        //   error={Boolean(email) && !validateEmail(String(email))}
        //   helperText={
        //     email && !validateEmail(String(email))
        //       ? t("forgotPassword.error.email_format")
        //       : ""
        //   }
        //   label={t("forgotPassword.email")}
        //   variant="outlined"
        //   value={email}
        //   onChange={(e: any) => setEmail(e.target.value)}
        // />
      )
    },
    {
      label: t('dealers.validate.verification_code'),
      description: (
        <>
          <FormField
            sx={{backgroundColor: '#F2F2F2'}}
            required
            label={'OTP'}
            value={otp}
            maxLength={6}
            onChange={(event: any) => {
              if (
                (onlyNumbers.test(event.target.value))
              ) {
                setOtp(event.target.value)
              }
            }}          />
          <FormField
            sx={{backgroundColor: '#F2F2F2'}}
            required
            label={t('forgotPassword.new_password')}
            type="password"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <FormField
            sx={{backgroundColor: '#F2F2F2'}}
            required
            label={t('forgotPassword.confirm_password')}
            error={password !== confirmPassword}
            type="password"
            value={confirmPassword}
            onChange={(e: any) => setConfirmPassword(e.target.value)}
          />
          {returnText(
            `${t('dealers.validate.password_must_contain')}:`,
            false,
            false
          )}
          <Box pl={3} mb={2}>
            {returnText(
              t('dealers.validate.number_of_charachters'),
              password.length >= 8 && password.length <= 24
            )}
            {returnText(
              t('dealers.validate.lowercase'),
              hasLower.test(password)
            )}
            {returnText(
              t('dealers.validate.uppercase'),
              hasUpper.test(password)
            )}
            {returnText(t('dealers.validate.number'), hasNumber.test(password))}
            {returnText(
              `${t('dealers.validate.special_character')} -!@#$%^&*()_`,
              hasCharacters.test(password)
            )}
            {returnText(
              t('dealers.validate.password_match'),
              password !== '' && (password === confirmPassword)
            )}
          </Box>
          <Box maxWidth={{ xs: 200, sm: '100%' }} sx={{ overflow: 'hidden' }}>
            <ReCAPTCHA
              hl={i18n.language}
              sitekey="6LcjMZ0iAAAAALh7DhdWgFtamCP530K9YZK7eX7F"
              onChange={(e: any) => {
                setCatpchaValidated(true);
              }}
            />
          </Box>
        </>
      )
    },
    {
      label: t('dealers.validate.done'),
      description: t('dealers.validate.success')
    }
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleFinish = () => {
    dispatch(removeUser());
    navigate('login');
  };

  const handleEmail = async () => {
    setLoading(true);
    try {
      const { message, status } = await postOtp(String(email), false);
      setLoading(false);
      if (status === 200) {
        successAlert(message)
        handleNext();
      }
    } catch (error: any) {
      setLoading(false);
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  const handleResendOTP = async () => {
    setLoading(true);
    try {
      const { message, status } = await postOtp(String(email));
      console.log(status)
      if (status === 200) {
        setTimeLeft(expireTime)
        setDisabledResend(true)
        setOtp('');
        successAlert(message)
      }
    } catch (error: any) {
      errorAlert(error.response?.data?.message || error.message);
    }
    setLoading(false);
  };

  const handleActivate = async () => {
    setLoading(true);
    try {
      const { message, status } = await postUserActivate(
        String(password),
        String(email),
        +String(otp),
        String(_dealer)
      );
      setLoading(false);
      if (status === 200) {
        successAlert(message)
        setDisabledResend(true)
        handleNext();
      }
    } catch (error: any) {
      setLoading(false);
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  return (
    <Paper>
      <Box padding={4} sx={{ maxWidth: '100%' }}>
        <Stack direction="row" justifyContent="center">
          <img src={logo} width={200} alt="CDVI Logo" style={{ marginLeft: -80 }} />
        </Stack>
        <Typography
          component="h3"
          variant="h3"
          fontWeight={1000}
          fontSize={24}
          color={blue[900]}
          textAlign="center"
          marginBottom={2}
        >
          {t('dealers.user_activation')}
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 0 ? (
                    activeStep !== 0 && (
                      <Typography variant="caption">{email}</Typography>
                    )
                  ) : index === 1 ? (
                    <Typography variant="caption">
                      {t('dealers.validate.validation_label')}
                    </Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{width: '100%', alignItems: 'center', marginTop: 2, display: 'flex', flexDirection: 'row'}}>
                  {disabledResend === true ? (
                    <Button
                      variant="contained"
                      disabled={activeStep === 1 && disableBtn}
                      onClick={
                        index === 0
                          ? handleEmail
                          : index === 1
                            ? handleActivate
                            : handleFinish
                      }
                      sx={{ mr: 1 }}
                    >
                      {index === steps.length - 1
                        ? t('general.btn.sign_in')
                        : t('dealers.validate.continue')}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{mr: 1 }}
                      disabled={loading}
                      onClick={handleResendOTP}>
                      {t('general.btn.resend')}
                    </Button>
                  )}
                  {activeStep === 1 && (
                    <Countdown timeLeft={(timeLeft)}/>
                  )}
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>

    </Paper>
  );
};

export default Activate;
