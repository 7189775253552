import { useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import { amber } from '@mui/material/colors';
// Components
import Modal from '../../../../../components/Modal/Modal';
import FormField from '../../../../../components/FormField/FormField';
import Select from '../../../../../components/Select/Select';
import Button from '../../../../../components/Button/Button';
// Utils
import validateEmail from '../../../../../utils/email-validation';
// API
import {
  postDealerUser,
  putDealerUser,
  getDealerRolesList
} from '../../../../../api/api';
import {
  Grid,
  Stack,
  Typography
} from '@mui/material';
import RolesTooltip from '../../../../../components/RolesTooltip/RolesTooltip';
import useAlert from '../../../../../utils/hooks/useAlert';

interface props {
  open: boolean;
  dealer?: string;
  onClose: () => void;
  onSuccess: (user?: any) => void;
  _user?: any;
  editMode?: boolean;
}

interface UserInterface {
  first_name: string;
  last_name: string;
  email: string;
  dealer_role: string;
  dealer_user: string;
  last_login: string | null;
  all_org: boolean;
  organization: OrganizationInterface[];
}

const initUser: UserInterface = {
  first_name: '',
  last_name: '',
  email: '',
  dealer_role: '',
  dealer_user: '',
  last_login: null,
  all_org: false,
  organization: []
};

interface OrganizationInterface {
  organization: string;
  all_sites: boolean;
  sites: string[];
}

const AddUserModal = ({
  open,
  onClose,
  onSuccess,
  editMode,
  _user,
  dealer
}: props) => {
  const { t } = useTranslation();
  const { errorAlert, successAlert } = useAlert();

  const [disableBtn, setDisableBtn] = useState(false);
  const [roles, setRoles] = useState([]);
  const [validEmail, setValidEmail] = useState(true);
  const [user, setUser] = useState(initUser);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);

  useEffect(() => {
    fecthRoles();
    // getOrganizations();
  }, []);

  useEffect(() => {
    !open && setUser(initUser);
    _user &&
      setUser({
        ..._user,
        dealer_user: _user.dealer_user,
        first_name: _user.first_name,
        last_name: _user.last_name,
        email: _user.email,
        dealer_role: _user.dealer_role,
        last_login: _user.last_login,
        all_org: _user.dealer_u_allorg
        // organization: _user.dealer_u_organizations,
      });
  }, [open]);

  useEffect(() => {
    const userHasFilledFields =
      user.first_name !== '' ||
      user.last_name !== '' ||
      user.email !== '' ||
      user.dealer_role !== '';

    const isUserInvalid =
      !user.first_name ||
      !user.last_name ||
      (user.email && !validEmail) ||
      !user.dealer_role;

    const isUserChanged =
      editMode &&
      (user.first_name !== _user.first_name ||
        user.last_name !== _user.last_name ||
        user.email !== _user.email ||
        user.dealer_role !== _user.dealer_role);

    if (!editMode && userHasFilledFields) {
      setShowLeaveConfirmation(true);
    } else {
      setShowLeaveConfirmation(false);
    }
    
    if (isUserChanged) {
      setDisableBtn(false);
      setShowLeaveConfirmation(true);
    } else if (editMode) {
      setDisableBtn(true);
    }

    if (isUserInvalid) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [user, validEmail, _user, editMode]);

  useEffect(() => {
    if (user.email) {
      setValidEmail(validateEmail(user.email));
    } else {
      setValidEmail(true);
    }
  }, [user.email]);

  // --------------------------------- API CALLS --------------------------------------------

  const fecthRoles = async () => {
    try {
      const { status, roles } = await getDealerRolesList(String(dealer));
      status === 200 && setRoles(roles);
    } catch (error: any) {
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  const addUser = async () => {
    try {
      const res = await postDealerUser(
        String(dealer),
        String(user.first_name),
        String(user.last_name),
        String(user.email),
        String(user.dealer_role)
        // user.all_org,
        // null
      );
      if (res.status === 201) {
        successAlert(t('dealers.add_edit.success'));
        onSuccess(res.dealer_user);
      }
    } catch (error: any) {
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  const editUser = async () => {
    try {
      const { status } = await putDealerUser(
        String(user.dealer_user),
        String(user.first_name),
        String(user.last_name),
        String(user.email),
        String(user.dealer_role),
        user.last_login !== null ? true : false
      );
      if (status === 201) {
        successAlert(t('dealers.add_edit.update'));
        onSuccess(user);
      }
    } catch (error: any) {
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  // --------------------------------- BUTTONS --------------------------------------------
  const dialogActions = (): ReactNode => {
    return (
      <Stack direction="row" spacing={1}>
        <Button id="add-user-cancel-button" color="gray" onClick={onClose}>
          {t('general.btn.cancel')}
        </Button>
        <Button
          disabled={disableBtn || user.email === ''}
          onClick={() => {
            editMode ? editUser() : addUser();
          }}
          id="add-user-save-button"
        >
          {editMode ? t('general.btn.update') : t('general.btn.save')}
        </Button>
      </Stack>
    );
  };

  // ------------------------------- TABS ----------------------------------------------
  const tabs = [
    {
      label: 'General',
      component: (
        <Box>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={6}>
              <FormField
                label={t('dealers.form.first_name')}
                required
                value={user.first_name}
                onChange={(event: any) =>
                  setUser({
                    ...user,
                    first_name: event.target.value
                  })
                }
                id="add-user-first-name-field"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label={t('dealers.form.last_name')}
                required
                value={user.last_name}
                onChange={(event: any) =>
                  setUser({
                    ...user,
                    last_name: event.target.value
                  })
                }
                id="add-user-last-name-field"
              />
            </Grid>
          </Grid>
          {/* email */}
          <FormField
            required
            label={t('dealers.form.email')}
            maxLength={100}
            error={!validEmail}
            disabled={editMode === true}
            value={user.email}
            onChange={(event: any) =>
              setUser({ ...user, email: event.target.value })
            }
            id="add-user-email-field"
          />

          {/* Roles */}
          <Typography
            variant="h4"
            sx={{
              marginBottom: 0.2,
              fontSize: 11,
              marginLeft: 0.3
            }}
          >
            {t('dealers.emp_role')}
            <Typography
              component="span"
              sx={{ marginLeft: 0.2, color: '#EE2222' }}
            >
              *
            </Typography>
            <RolesTooltip
              arrow
              placement="right-start"
              roles={roles}
              entity={user}
              roleKey="dealer_role"
              entityKey="dealer_role"
            >
              <InfoIcon
                sx={{
                  fontSize: 16,
                  color: amber[400],
                  marginLeft: 1,
                  marginBottom: 0.2
                }}
              />
            </RolesTooltip>
          </Typography>
          <Select
            required
            value={user.dealer_role}
            onChange={(event: any) =>
              setUser({ ...user, dealer_role: event.target.value })
            }
            options={roles}
            optionName="name"
            optionValue="dealer_role"
            id="add-user-roles-select"
          />

          {/* Organization check */}
          {/* <FormControlLabel
            disabled={loading}
            checked={user.all_org}
            onChange={(event: any) =>
              setUser({
                ...user,
                all_org: event.target.checked,
                organization: [],
              })
            }
            control={<Checkbox />}
            label={t("dealers.access_all_accounts")}
            sx={{ userSelect: "none" }}
          /> */}
        </Box>
      )
    }
  ];

  // --------------------------------- RENDER --------------------------------------------
  return (
    <>
      <Modal
        width={'md'}
        open={open}
        onClose={onClose}
        title={editMode ? t('dealers.edit_emp') : t('dealers.add_emp')}
        actions={dialogActions()}
        showLeaveConfirmation={showLeaveConfirmation}
      >
        {/* <Tabs children={tabs} /> */}
        {tabs[0].component}
      </Modal>

    </>
  );
};

export default AddUserModal;
