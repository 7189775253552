import { ChangeEventHandler, FC, useState } from 'react';
import EnhancedTableToolbar from './EnhancedTableToolbar/EnhancedTableToolbar';

// Interfaces
import EnhancedTableProps from './interfaces/EnhancedTableHead.interface';
import HeadCell from './interfaces/HeadCell.interface';

// Material UI
import {
  Box,
  Button,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import Checkbox from '../Checkbox/Checkbox';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// project imports
import EnhancedTableHead from './EnhancedTableHead/EnhancedTableHead';
import TableOptionsBtn from './TableOptionsBtn/TableOptionsBtn';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TableIcons from './TableIcons/TableIcons';
import UserAvatarStatus from '../UserAvatarStatus/UserAvatarStatus';
import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { idFormat } from '../../utils/idFormat';
import SectionPanel from '../SectionPanel/SectionPanel';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const MaterialTable: FC<{
  headCells: HeadCell<any>[];
  rows: any[];
  id: string;
  actionBtn?: () => void;
  secondActionBtn?: () => void;
  thirdActionBtn?: () => void;
  forceDisplayBtn?: boolean;
  actionBtnTitle?: string;
  secondActionBtnTitle?: string;
  thirdActionBtnTitle?: string;
  actionBtnDisabled?: boolean;
  secondActionBtnLabel?: string;
  searchbarPlaceholder?: string;
  onChangeSearchbar?: ChangeEventHandler<HTMLInputElement>;
  searchBarValue?: string;
  secondSearchBarPlaceholder?: string;
  onChangeSecondSearchbar?: ChangeEventHandler<HTMLInputElement>;
  secondSearchBarValue?: string;
  actions?: boolean;
  selectable?: boolean;
  onActionClick?: (action: string, row: any) => void;
  onSwitchChange?: (row: any, event: any) => void;
  customActions?: any[];
  hideCustomActions?: string[];
  deleteSelected?: (selected: any) => void;
  filterRadioValue?: any;
  filterRadioOptions?: any;
  filterRadioOnChange?: (value: string) => void;
  filterRadioName?: string;
  filterRadio2Value?: any;
  filterRadio2Options?: any;
  filterRadio2OnChange?: (value: string) => void;
  filterRadio2Name?: string;
  filterSelectValue?: any;
  filterSelectOptions?: any;
  filterSelectOnChange?: (value: string) => void;
  filterCheckBoxValues?: string[];
  filterCheckBoxOptions?: any[];
  filterCheckBoxOnChange?: (value: string) => void;
  filterFildSetCheckBoxName?: string;
  secondFilterSelectValue?: any;
  secondFilterSelectOptions?: any;
  secondFilterSelectOnChange?: (value: string) => void;
  thirdFilterSelectValue?: any;
  thirdFilterSelectOptions?: any;
  thirdFilterSelectOnChange?: (value: string) => void;
  fourthFilterSelectValue?: any;
  fourthFilterSelectOptions?: any;
  fourthFilterSelectOnChange?: (value: string) => void;
  fifthFilterSelectValue?: any;
  fifthFilterSelectOptions?: any;
  fifthFilterSelectOnChange?: (value: string) => void;
  firstSelectPlaceholder?: string;
  secondSelectPlaceholder?: string;
  thirdSelectPlaceholder?: string;
  fourthSelectPlaceholder?: string;
  clearBtn?: boolean;
  onClearBtn?: () => void;
  fromDate?: Date | null;
  toDate?: Date | null;
  fromDatePickerOnChange?: (date: Date | null) => void;
  toDatePickerOnChange?: (date: Date | null) => void;
  hiddenBtnIcon?: boolean;
  datePickers?: boolean;
  onRowClick?: (row: any) => void;
  removeToolBar?: boolean;
  hideAddIcon?: boolean;
  individualActions?: boolean;
  addCardAction?: (row: any) => void;
  assignUserAction?: (row: any) => void;
  disableActionBtn?: boolean;
  refreshBtnIcon?: boolean;
  dropDownButton?: boolean;
  dropDownButtonTitle?: string;
  dropDownActions?: any;
  dropDownActionClick?: (action: string) => void;
  rowsPerPageOptions?: number[];
  customRowsPerPage?: number;
  styleToolBar?: 1 | 2 | 3 | 4;
  hover?: boolean;
  reportStyle?: boolean;
  setSelectedItems?: (items: any) => void;
  selected?: readonly string[];
  onCheckClick?: (items: any) => void;
  hideMainCheck?: boolean;
  darkBg?: boolean;
  stickyHeader?: boolean;
  tableContainerHeight?: string | number;
  hideSelectedCount?: boolean;
  disableRowKey?: string;
  dateTimePickers?: boolean;
}> = ({
  headCells,
  rows,
  onRowClick,
  id,
  actionBtn,
  secondActionBtn,
  forceDisplayBtn,
  actionBtnTitle,
  secondActionBtnTitle,
  actionBtnDisabled,
  secondActionBtnLabel,
  searchbarPlaceholder,
  onChangeSearchbar,
  searchBarValue,
  secondSearchBarPlaceholder,
  onChangeSecondSearchbar,
  secondSearchBarValue,
  actions = false,
  selectable = false,
  onActionClick,
  onSwitchChange,
  customActions,
  hideCustomActions,
  deleteSelected,
  filterRadioValue,
  filterRadioOptions,
  filterRadioOnChange,
  filterRadioName,
  filterRadio2Value,
  filterRadio2Options,
  filterRadio2OnChange,
  filterRadio2Name,
  filterCheckBoxOptions,
  filterCheckBoxValues,
  filterCheckBoxOnChange,
  filterFildSetCheckBoxName,
  removeToolBar,
  filterSelectValue,
  filterSelectOptions,
  filterSelectOnChange,
  hideAddIcon,
  secondFilterSelectValue,
  secondFilterSelectOptions,
  secondFilterSelectOnChange,
  thirdFilterSelectValue,
  thirdFilterSelectOptions,
  thirdFilterSelectOnChange,
  fourthFilterSelectValue,
  fourthFilterSelectOptions,
  fourthFilterSelectOnChange,
  firstSelectPlaceholder,
  secondSelectPlaceholder,
  thirdSelectPlaceholder,
  fourthSelectPlaceholder,
  clearBtn,
  onClearBtn,
  hiddenBtnIcon,
  datePickers,
  fromDatePickerOnChange,
  toDatePickerOnChange,
  fromDate,
  toDate,
  individualActions,
  addCardAction,
  assignUserAction,
  disableActionBtn,
  refreshBtnIcon,
  dropDownButton,
  dropDownButtonTitle,
  dropDownActions,
  dropDownActionClick,
  rowsPerPageOptions,
  customRowsPerPage,
  fifthFilterSelectOnChange,
  fifthFilterSelectOptions,
  fifthFilterSelectValue,
  thirdActionBtn,
  thirdActionBtnTitle,
  styleToolBar = 1,
  hover = true,
  reportStyle,
  setSelectedItems,
  selected,
  onCheckClick,
  hideMainCheck,
  darkBg,
  stickyHeader = false,
  tableContainerHeight = 'initial',
  hideSelectedCount = false,
  disableRowKey,
  dateTimePickers
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [order, setOrder] = useState<EnhancedTableProps['order']>('asc');
    const [orderBy, setOrderBy] = useState<string>('calories');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(customRowsPerPage ?? 10);

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: string
    ) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n[id]);
        setSelectedItems && setSelectedItems(newSelecteds);
        return;
      }
      setSelectedItems && setSelectedItems([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
      event.stopPropagation();
      if (selected) {
        const selectedIndex = selected?.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
          );
        }
        setSelectedItems && setSelectedItems(newSelected);
      }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const isSelected = (name: string) =>
      selected ? selected.indexOf(name) !== -1 : false;

    const isRowDisabled = (row: any) => {
      return disableRowKey ? Boolean(row[disableRowKey]) : false;
    }

    const checkType = (type: HeadCell<any>['type'], { cell, row, index }: any) => {
      const rowDisabled = isRowDisabled(row);

      switch (type) {
        case 'any':
          return row[cell.id];

        case 'conditional':
          return row[cell.conditional[0]]
            ? t(row[cell.conditional[1]])
            : row[cell.conditional[2]]
              ? row[cell.conditional[2]]
              : cell.conditional[2];

        case 'elements':
          // Used to display 2 dates on the same column
          const activationDate = checkDateFormatted(row[cell.elements[0]]);
          const expirationDate = checkDateFormatted(row[cell.elements[1]]);
          return `${activationDate} | ${expirationDate ? expirationDate : '---'}`;
        // return `${cell.elements[0][0]} ${cell.elements[0][1]}`;

        case 'text':
          const arr: Array<string> = cell.elements.map((ele: any) => row[ele]);
          return arr.toString().replaceAll(',', ' ');

        case 'array_of_objects':
          return row[cell.id]?.map((ele: any) => {
            return ele[cell.joinProperty];
          }).join(', ');

        case 'avatar':
          const temp: Array<string> = cell.elements.map((ele: any) =>
            String(row[ele]).charAt(0)
          );
          return temp.toString().replaceAll(',', '');

        case 'boolean':
          return row[cell.id] ? t(cell.elements[0]) : t(cell.elements[1]);
        // case "numeric":
        //   return(
        //   );
        case 'date':
          return checkDate(row[cell.id] as string);

        case 'date_formatted':
          return checkDateFormatted(row[cell.id] as string);

        case 'actions':
          return (
            <TableOptionsBtn
              customLabel={
                cell.actions.find(
                  (action: any) =>
                    action.value === row[cell.id] && row[cell.id] !== undefined
                )?.title || undefined
              }
              customActions={cell.actions}
              onActionClick={
                onActionClick
                  ? (action: string) => onActionClick(action, row)
                  : null
              }
              row={row}
              id={`${cell.id}-${index}`}
              disabled={rowDisabled}
            />
          );

        case 'icon':
          const current = cell.iconList.find(
            (ele: any) => ele.value === row[cell.id]
          );

          return (
            <Tooltip title={current ? t(current.tooltip) : ''}>
              <div>
                <TableIcons
                  tag={current?.icon || 'helpIcon'}
                  color={current?.color}
                  colorIntensity={current?.colorIntensity}
                  state={current?.state}
                  id={`table-icon-${idFormat(cell.id)}-${index}`} />
              </div>
            </Tooltip>
          );

        case 'iconOptions':
          const currentIcon = cell.iconList.find(
            (ele: any) => ele.value === row[cell.id]
          );
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                height: '44px',
                pr: '4px'
              }}
            >
              <Stack direction="row" gap="4px" alignItems="center">
                <Tooltip title={currentIcon ? t(currentIcon?.tooltip) : ''}>
                  <div>
                    <TableIcons
                      tag={currentIcon?.icon || 'helpIcon'}
                      color={currentIcon?.color}
                      colorIntensity={currentIcon?.colorIntensity}
                      state={currentIcon?.state}
                    />
                  </div>
                </Tooltip>

                {row[currentIcon?.text] && (
                  <Typography>
                    {row[currentIcon?.text] || t(currentIcon?.text)}
                  </Typography>
                )}
              </Stack>

              {cell.actions && (
                <TableOptionsBtn
                  customLabel={
                    cell.actions.find(
                      (action: any) =>
                        action.value === row[cell.id] &&
                        row[cell.id] !== undefined
                    )?.title || undefined
                  }
                  customActions={cell.actions}
                  onActionClick={
                    onActionClick
                      ? (action: string) => onActionClick(action, row)
                      : null
                  }
                  row={row}
                  id={`${cell.id}-${index}`}
                  disabled={rowDisabled}
                />
              )}
            </Box>
          );

        case 'array':
          if (!row[cell.id] || !row[cell.id]?.length) return;
          return (
            <Typography>
              {row[cell.id][0][`${cell.arrayElement}`]}{' '}
              {row[cell.id].length > 1 && (
                <span>
                  <Tooltip
                    title={`${row[cell.id]
                      .map((ele: any, index: number) => {
                        if (index !== -0) return ele[`${cell.arrayElement}`];
                        return null;
                      })
                      .filter((ele: any) => ele)
                      .join(', ')}.`}
                  >
                    <AddCircleOutlineOutlinedIcon
                      sx={{ mt: -0.5, fontSize: 16 }}
                    />
                  </Tooltip>
                </span>
              )}
            </Typography>
          );
        // return row[cell.id]?.map((ele: any) => (
        //   <Typography>{ele[`${cell.arrayElement}`]}</Typography>
        // ));

        case 'checkbox':
          return <Checkbox disabled checked={row[cell.id]} onChange={() => { }} id={`table-checkbox-${cell.id}-${index}`} />;

        case 'switch':
          return (
            <Switch
              checked={row[cell.id]}
              onClick={(e) => {
                e.stopPropagation();
                onSwitchChange && onSwitchChange(row, e);
              }}
              id={`table-switch-${cell.id}-${index}`}
              disabled={rowDisabled || !onSwitchChange}
            // onChange={(e) => {
            //   onSwitchChange && onSwitchChange(row, e);
            // }}
            />
          );

        case 'image':
          return (
            <>
              <Stack overflow={'auto'} direction="row" gap={1}>
                <Box sx={{
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  borderRadius: 100
                }}>
                  {row[cell.id] && (
                    <img
                      width="20px"
                      height="20px"
                      style={{ objectFit: 'cover' }}
                      src={row[cell.id]}
                      alt=""
                    />
                  )}
                </Box>
              </Stack>
            </>
          );

        case 'custom':
          return cell.customElement ? cell.customElement(row[cell.id]) : null;
      }
    };

    const checkDate = (value: string): string => {
      return value
        ? moment(value.replace('Z', '')).format('MM/DD/YYYY hh:mm:ss a')
        : value;
    };

    const checkDateFormatted = (value: string): string => {
      return value ? moment(value.replace('Z', '')).format('MM/DD/YYYY') : value;
    };

    const customActionsFunction = (row: any) => {
      // customActions?.filter((action:any) => action.action !== hideCustomAction)
      let customActionArr = customActions;
      // console.log("customActionArr", customActionArr);
      hideCustomActions?.forEach((action: any) => {
        switch (action) {
          case 'resend':
            if (row.last_login !== null) {
              customActionArr = customActionArr?.filter(
                (ele: any) => ele.action !== action
              );
            }
            break;
          case 'activate':
            if (row.dealer_u_active) {
              customActionArr = customActionArr?.filter(
                (ele: any) => ele.action !== action
              );
            }
            break;
          case 'deactivate':
            if (!row.dealer_u_active) {
              customActionArr = customActionArr?.filter(
                (ele: any) => ele.action !== action
              );
            }
            break;

          // For keypad code on users
          case 'set_keypad':
            if (row.has_keypad) {
              customActionArr = customActionArr?.filter(
                (ele: any) => ele.action !== action
              );
            }
            break;

          case 'remove_keypad':
            if (!row.has_keypad) {
              customActionArr = customActionArr?.filter(
                (ele: any) => ele.action !== action
              );
            }
            break;
        }
      });
      return customActionArr;
    };
    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const returnContainer = () => {
      return (
        <>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 4,
              mt: reportStyle ? -1.5 : '',
              background: darkBg ? `${grey[100]} !important` : '',
              maxHeight: tableContainerHeight
            }}
          >
            <Table
              id={id}
              sx={{ minWidth: 300 }}
              aria-labelledby="tableTitle"
              size="small"
              stickyHeader={stickyHeader}
            >
              <EnhancedTableHead
                numSelected={Number(selected?.length)}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
                selectable={selectable}
                actions={actions}
                hideMainCheck={hideMainCheck}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(
                    page * (rowsPerPage),
                    page * (rowsPerPage) +
                      (rowsPerPage)
                  )
                  .map((row, index) => {
                    const rowDisabled = isRowDisabled(row);
                    const isItemSelected = isSelected(String(row[id]));
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover={hover}
                        sx={{
                          cursor: (hover && !rowDisabled) ? 'pointer' : 'default',
                          background: (darkBg || rowDisabled) ? `${grey[100]} !important` : ''
                        }}
                        onClick={() => (!rowDisabled && onRowClick) && onRowClick(row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row[id]}
                        selected={isItemSelected}
                        id={`table-row-${index}`}
                      >
                        {selectable && (
                          <TableCell width={50} sx={{ minWidth: 50, padding: 0 }}>
                            <Checkbox
                              onClick={(event: any) => {
                                onCheckClick && onCheckClick(row[id]);
                                handleClick(event, String(row[id]));
                              }}
                              checked={isItemSelected}
                              label={labelId}
                            />
                          </TableCell>
                        )}

                        {headCells.map((cell, j: number) => (
                          <TableCell
                            key={`${labelId}_${j}`}
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{
                              padding: styleToolBar === 4 ? '3.5px' : ''
                            }}
                          >
                            <Stack direction={'row'}>
                              {cell.avatar && (
                                <>
                                  <UserAvatarStatus
                                    imgUrl={String(row[String(cell?.url)] || '')}
                                    text={checkType('avatar', { cell, row, index })}
                                    status={Boolean(row['status'])}
                                    dimmension={50}
                                  />
                                  {/* <Avatar
                                  url={String(row[String(cell?.url)])}
                                  src="/static/images/avatar/1.jpg"
                                  sx={{ mr: 1, width: 50, height: 50, mb: 0 }}
                                >
                                  {checkType("avatar", { cell, row, j })}
                                </Avatar> */}
                                  {/* <Typography
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {
                                    rows[
                                      index +
                                        (customRowsPerPage || rowsPerPage) *
                                          page
                                    ].first_name
                                  }{" "}
                                  {
                                    rows[
                                      index +
                                        (customRowsPerPage || rowsPerPage) *
                                          page
                                    ].last_name
                                  }
                                </Typography> */}
                                </>
                              )}
                              <Typography fontSize={styleToolBar === 4 ? 12 : 14} noWrap alignSelf={'center'} sx={{ whiteSpace: 'pre' }}>
                                {checkType(cell.type, { cell, row, index })}
                              </Typography>
                            </Stack>
                          </TableCell>
                        ))}
                        {actions && (
                          <TableCell align="right">
                            <TableOptionsBtn
                              customActions={
                                hideCustomActions !== null
                                  ? customActionsFunction(row)
                                  : customActions
                              }
                              onActionClick={
                                onActionClick
                                  ? (action: string) => onActionClick(action, row)
                                  : null
                              }
                              row={row}
                              id={`actions-${index}`}
                              disabled={rowDisabled}
                            />
                          </TableCell>
                        )}
                        {individualActions && (
                          <TableCell align="right">
                            {/* enable_cloud_action -> 0 - NA; 1 - Add; 2 - Assign */}
                            {row.enable_cloud_action === 1 && addCardAction && (
                              <Button
                                variant="contained"
                                sx={{ height: '25px' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  addCardAction(row);
                                }}
                                id={'enable-cloud-action-1'}
                                disabled={rowDisabled}
                              >{`${t('events.general.add')}`}</Button>
                            )}
                            {row.enable_cloud_action === 2 && assignUserAction && (
                              <Button
                                variant="contained"
                                sx={{ height: '25px' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  assignUserAction(row)
                                }}
                                id={'enable-cloud-action-2'}
                                disabled={rowDisabled}
                              >{`${t('events.general.assign')}`}</Button>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
              <TableRow>
              <TableCell colSpan={6} />
              </TableRow>
            )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              '& .MuiSvgIcon-root': {
                color: theme.palette.text.secondary
              }
            }}
            rowsPerPageOptions={rowsPerPageOptions || [10, 20, 30]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      );
    };

    const returnHeader = () => {
      return (
        <>
          <EnhancedTableToolbar
            numSelected={Number(selected?.length)}
            actionBtn={() => {
              actionBtn!();
              setPage(0);
            }} // reset page to 0 when actionBtn is clicked
            secondActionBtn={secondActionBtn}
            actionBtnTitle={actionBtnTitle}
            secondActionBtnTitle={secondActionBtnTitle}
            forceDisplayBtn={forceDisplayBtn}
            actionBtnDisabled={actionBtnDisabled}
            secondActionBtnLabel={secondActionBtnLabel}
            searchbarPlaceholder={searchbarPlaceholder}
            onChangeSearchbar={onChangeSearchbar}
            searchBarValue={searchBarValue}
            secondSearchBarPlaceholder={secondSearchBarPlaceholder}
            onChangeSecondSearchbar={onChangeSecondSearchbar}
            secondSearchBarValue={secondSearchBarValue}
            deleteSelected={() => deleteSelected && deleteSelected(selected)}
            filterRadioValue={filterRadioValue}
            filterRadioOptions={filterRadioOptions}
            filterRadioOnChange={filterRadioOnChange}
            filterRadioName={filterRadioName}
            filterSelectValue={filterSelectValue}
            filterSelectOptions={filterSelectOptions}
            filterSelectOnChange={filterSelectOnChange}
            hideAddIcon={hideAddIcon}
            secondFilterSelectValue={secondFilterSelectValue}
            secondFilterSelectOptions={secondFilterSelectOptions}
            secondFilterSelectOnChange={secondFilterSelectOnChange}
            thirdFilterSelectValue={thirdFilterSelectValue}
            thirdFilterSelectOptions={thirdFilterSelectOptions}
            thirdFilterSelectOnChange={thirdFilterSelectOnChange}
            fourthFilterSelectValue={fourthFilterSelectValue}
            fourthFilterSelectOptions={fourthFilterSelectOptions}
            fourthFilterSelectOnChange={fourthFilterSelectOnChange}
            thirdSelectPlaceholder={thirdSelectPlaceholder}
            onClearBtn={onClearBtn}
            clearBtn={clearBtn}
            hiddenBtnIcon={hiddenBtnIcon}
            datePickers={datePickers}
            fromDate={fromDate}
            toDate={toDate}
            fromDatePickerOnChange={fromDatePickerOnChange}
            toDatePickerOnChange={toDatePickerOnChange}
            disableActionBtn={disableActionBtn}
            refreshBtnIcon={refreshBtnIcon}
            dropDownButton={dropDownButton}
            dropDownButtonTitle={dropDownButtonTitle}
            dropDownActions={dropDownActions}
            dropDownActionClick={dropDownActionClick}
            fifthFilterSelectOnChange={fifthFilterSelectOnChange}
            fifthFilterSelectOptions={fifthFilterSelectOptions}
            fifthFilterSelectValue={fifthFilterSelectValue}
            thirdActionBtn={thirdActionBtn}
            thirdActionBtnTitle={thirdActionBtnTitle}
            styleToolBar={styleToolBar}
            filterRadio2Value={filterRadio2Value}
            filterRadio2Options={filterRadio2Options}
            filterRadio2OnChange={filterRadio2OnChange}
            filterRadio2Name={filterRadio2Name}
            filterFildSetCheckBoxName={filterFildSetCheckBoxName}
            filterCheckBoxOptions={filterCheckBoxOptions}
            filterCheckBoxValues={filterCheckBoxValues}
            filterCheckBoxOnChange={filterCheckBoxOnChange}
            reportStyle={reportStyle}
            hideSelectedCount={hideSelectedCount}
            dateTimePickers={dateTimePickers}
          />
        </>
      );
    };

    return (
      <Box sx={{ width: '100%', borderRadius: '0.5em' }}>
        {!removeToolBar &&
          (reportStyle ? (
            <Box mb={2}>
              <SectionPanel title={t('general.filters')} sxContainer={{ p: 0 }} withBorder>
                {returnHeader()}
              </SectionPanel>
            </Box>
          ) : (
            returnHeader()
          ))}

        {reportStyle ? (
          <SectionPanel title={t('general.results')} withBorder>
            {returnContainer()}
          </SectionPanel>
        ) : (
          returnContainer()
        )}
      </Box>
    );
  };

export default MaterialTable;
