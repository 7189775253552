import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/media/logos/CDVI_CLOUD4.png'
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Stack
} from '@mui/material';
// Api
import { postOtp, postOtpChangePassword, postOtpVerify } from '../../api/api';

import { grey, blue } from '@mui/material/colors';
import Button from '../../components/Button/Button';
import { ResponsiveStyleValue } from '@mui/system';
import validateEmail from '../../utils/email-validation';
import { useNavigate } from 'react-router-dom';
import FormField from '../../components/FormField/FormField';
import PsswdRequirementsList from '../../components/PsswdRequirementsList/PsswdRequirementsList';
import Countdown from '../../components/Countdown/Countdown';
import useAlert from '../../utils/hooks/useAlert';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { errorAlert, successAlert} = useAlert();
  const expireTime = 300;


  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [disabledResend, setDisabledResend] = useState(true);
  const [timeLeft, setTimeLeft] = useState(expireTime);

  const onlyNumbers = /^[0-9]*$/;

  const navigate = useNavigate();

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        if (!loading && !disableSubmit) handleEmail();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });

  // useEffect for disabling Submit button
  useEffect(() => {
    switch (step) {
    case 1:
      setDisableSubmit(!Boolean(email) || !validateEmail(String(email)));
      break;

    case 2:
      setDisableSubmit(!Boolean(otp));
      break;

    case 3:
      break;
    }
  }, [email, otp, newPassword, confirmPassword, step]);

  useEffect(() => {
    if (timeLeft > 0) {
      const countdownInterval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);

      return () => clearInterval(countdownInterval);
    } else if (timeLeft === 0 && step === 2) {
      setDisabledResend(false);
    }
  }, [timeLeft]);

  const handleEmail = async () => {
    setLoading(true);
    try {
      const { message, status } = await postOtp(String(email));
      setLoading(false);
      if (status === 200) {
        successAlert(message);
        setTimeLeft(expireTime);
        setDisabledResend(true);
        setOtp('');
        setStep(2);
      }
    } catch (error: any) {
      setLoading(false);
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  const handleOTP = async () => {
    setLoading(true);
    try {
      const { message, status } = await postOtpVerify(
        String(email),
        +String(otp)
      );
      setLoading(false);
      if (status === 200) {
        successAlert(message);
        setStep(3);
      }
    } catch (error: any) {
      setLoading(false);
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  const handlePasswords = async () => {
    setLoading(true);
    try {
      const { message, status } = await postOtpChangePassword(
        String(email),
        +String(otp),
        String(newPassword)
      );
      setLoading(false);
      if (status === 200) {
        successAlert(message);
        navigate('/login');
      }
    } catch (error: any) {
      setLoading(false);
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  const handleResendOTP = async () => {
    setLoading(true);
    await handleEmail();
    setTimeLeft(expireTime)
    setLoading(false);
  };

  const getTypography = (
    text: string,
    mt?: number,
    mb?: number,
    textAlign?: ResponsiveStyleValue<any>,
    color?: string
  ) => {
    return (
      <Typography
        color={color ? color : grey[400]}
        textAlign={textAlign ? textAlign : 'left'}
        mt={mt ? mt : 0}
        mb={mb ? mb : 5}
      >
        {text}
      </Typography>
    );
  };

  const emailBody = () => {
    return (
      <>
        <Typography
          variant="h1"
          fontWeight={30}
          fontSize={20}
          textAlign="center"
          mt={2}
          mb={2}
        >
          {t('forgotPassword.forgot_password')}
        </Typography>
        {getTypography(t('forgotPassword.enter_email'), 0, 2, 'center')}
        <FormField
          required
          sx={{backgroundColor: '#E3E6E9'}}
          label={t('users.add.email')}
          error={Boolean(email) && !validateEmail(String(email))}
          errorText={
            email && !validateEmail(String(email))
              ? t('forgotPassword.error.email_format')
              : ''
          }
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
        {/* <TextField
          id="email"
          fullWidth
          error={Boolean(email) && !validateEmail(String(email))}
          helperText={
            email && !validateEmail(String(email))
              ? t("forgotPassword.error.email_format")
              : ""
          }
          label={t("forgotPassword.email")}
          variant="outlined"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        /> */}
      </>
    );
  };

  const forgotPassword = () => {
    return (
      <>
        <Typography variant="h1" align="center" fontWeight={30} fontSize={20} mt={2} mb={2}>
          {t('forgotPassword.forgot_password')}
        </Typography>
        {getTypography(
          `${t('forgotPassword.to_continue')} ${email}. ${t(
            'forgotPassword.enter_it'
          )}`,
          0,
          1,
          'center',
          'inherit'
        )}
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
        </Box>
        <FormField
          required
          label={'OTP'}
          maxLength={6}
          error={otp === undefined || otp === ''}
          errorText={
            otp === undefined || otp === ''
              ? t('forgotPassword.error.required')
              : ''
          }
          value={otp}
          onChange={(event: any) => {
            if (
              (onlyNumbers.test(event.target.value))
            ) {
              setOtp(event.target.value)
            }
          }}        />
        {/* <TextField
          id="otp"
          fullWidth
          label={"OTP"}
          variant="outlined"
          error={otp === undefined || otp === ""}
          helperText={
            otp === undefined || otp === ""
              ? t("forgotPassword.error.required")
              : ""
          }
          value={otp}
          onChange={(e: any) => setOtp(e.target.value)}
        /> */}
      </>
    );
  };

  const newPasswordBody = () => {
    return (
      <>
        <Typography variant="h1" fontWeight={50} fontSize={40} mt={2} mb={2}>
          {t('forgotPassword.new_password')}
        </Typography>
        {getTypography(
          `${t('forgotPassword.hello')}, ${email} ${t(
            'forgotPassword.finish_msg'
          )}`,
          0,
          5,
          'inherit'
        )}
        <FormField
          required
          label={t('forgotPassword.new_password')}
          type="password"
          value={newPassword}
          onChange={(e: any) => setNewPassword(e.target.value)}
        />
        <FormField
          required
          label={t('forgotPassword.confirm_password')}
          error={newPassword !== confirmPassword}
          type="password"
          value={confirmPassword}
          onChange={(e: any) => setConfirmPassword(e.target.value)}
        />
        {/* <Box marginBottom={2}>
          <TextField
            fullWidth
            label={t("forgotPassword.new_password")}
            variant="outlined"
            error={newPassword !== undefined}
            helperText={
              newPassword !== undefined
                ? t("forgotPassword.error.required")
                : ""
            }
            type="password"
            value={newPassword}
            onChange={(e: any) => setNewPassword(e.target.value)}
          />
        </Box>
        <TextField
          fullWidth
          label={t("forgotPassword.confirm_password")}
          variant="outlined"
          error={confirmPassword === undefined || confirmPassword === ""}
          helperText={
            confirmPassword === undefined || confirmPassword === ""
              ? t("forgotPassword.error.required")
              : confirmPassword !== newPassword
              ? t("forgotPassword.error.password_match")
              : ""
          }
          type="password"
          value={confirmPassword}
          onChange={(e: any) => setConfirmPassword(e.target.value)}
        /> */}
        <PsswdRequirementsList
          password={newPassword}
          confirmPassword={String(confirmPassword)}
          setDisable={setDisableSubmit}
        />
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: 4,
          height: '100% '
        }}
      >
        <Card
          variant="outlined"
          sx={{
            background: '#eff2f5',
            width: { xs: '100%', md: '640px' },
            borderRadius: '0.75em',
            margin: '0.25em',
            border: 'none'
          }}
        >
          <Stack direction="row" justifyContent="center">
            <img src={logo} width={200} alt="CDVI Logo" style={{ marginLeft: -80 }}/>
          </Stack>
          <Typography
            component="h3"
            variant="h3"
            fontWeight={1000}
            fontSize={28}
            color={blue[900]}
            textAlign="center"
          >
              Cloud-Based Access Control System
          </Typography>
          <CardContent
            sx={{paddingY: 0}}>
            {step === 1
              ? emailBody()
              : step === 2
                ? forgotPassword()
                : newPasswordBody()}
          </CardContent>
          <CardActions
            sx={{paddingY: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: step === 2 ? 'space-between' : 'flex-end'}}>
            {step === 2 && (
              <Countdown timeLeft={timeLeft}></Countdown>
            )}
            <Box sx={{display: 'flex', gap: 1}}>

              {/* Login/Resend Button */}
              {disabledResend === false ? (
                <Button 
                  color="darkBlue"
                  loading={loading}
                  disabled={loading}
                  onClick={handleResendOTP}>
                  {t('general.btn.resend')}
                </Button>
              ) : (
                <Button
                  color="darkBlue"
                  disabled={loading || disableSubmit}
                  loading={loading}
                  onClick={
                    step === 1
                      ? handleEmail
                      : step === 2
                        ? handleOTP
                        : handlePasswords
                  }
                >
                  {t('forgotPassword.submit')}
                </Button>
              )}
              <Button
                color="gray"
                loading={loading}
                onClick={() => navigate('/login')}
              >
                {t('forgotPassword.cancel')}
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

export default ForgotPassword;
