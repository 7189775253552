import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../../../../../components/MaterialTable/MaterialTable';
import accountsHeadCell from '../accountsHeadCell';
import tableActions from '../../../../../interfaces/tableActions.interface';
import { Stack } from '@mui/material';
import Button from '../../../../../components/Button/Button';
import {
  deleteDealerUserAssignAccount,
  postDealerUserAssignAccount
} from '../../../../../api/api';
import EditSites from '../EditSites/EditSites';
import { putDealerUser } from '../../../../../api/api';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../routes/routes';
import useAlert from '../../../../../utils/hooks/useAlert';

interface props {
  user: any;
  setUser: (user: any) => void;
  clearList: () => void;
  // open: boolean;
  accountList: any[];
  selectedAccounts: any[];
  siteList: any[];
  _employee: string;
  onClose: () => void;
  onSuccess: (obj: any) => void;
}

const AssignAccount = ({
  user,
  setUser,
  clearList,
  // open,
  accountList,
  selectedAccounts,
  siteList,
  _employee,
  onClose,
  onSuccess
}: props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { errorAlert, successAlert } = useAlert();

  const [list, setList] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [account, setAccount] = useState<any>(null);
  const [showEditSites, setShowEditSites] = useState(false);

  const returnSiteList = useMemo(() => {
    return (
      siteList.find(
        (s) => s[0]?.organization.organization === account?.organization
      ) || []
    );
  }, [account]);

  const customActions: tableActions[] = [
    {
      action: 'edit',
      title: `${t('general.btn.edit')} ${t('sites.sites').toLowerCase()}`,
      onDisplay: ['multi_site', true]
      // onHide: ["selected", false],
    }
  ];

  useEffect(() => {
    selectedAccounts &&
      setSelected(selectedAccounts.map((a) => a.organization));
  }, [selectedAccounts]);

  useEffect(() => {
    accountList &&
      setList(
        accountList.map((a: any) => {
          const accountTemp = user.dealer_u_organizations?.find(
            (da: any) => da.organization === a.organization
          );
          return {
            ...a,
            // selected: selected.includes(a.organization),
            site_title: !a.multi_site
              ? t('organization.single_site')
              : !accountTemp || accountTemp?.all_sites
                ? t('general.all_sites')
                : siteList
                  ?.find((s: any) =>
                    s.find((site: any) =>
                      accountTemp?.sites.includes(site.site)
                    )
                  )
                  .filter((s: any) => accountTemp?.sites.includes(s.site))
                  .map((s: any) => s.name)
                  .join(','),
            all_sites: accountTemp ? accountTemp?.all_sites : true,
            sites:
              accountTemp?.sites ||
              siteList
                .find(
                  (s) => s[0]?.organization.organization === a?.organization
                )
                .map((s: any) => s.site)
          };
        })
      );
  }, [accountList]);

  const assignAccounts = async (account_org:string, sites?: any) => {
    try {
      const tempAccount = list.find(
        (l) => l.organization === account_org
      );
      const body = {
        organization: [
          {
            organization: account_org,
            sites: sites ?? tempAccount.sites,
            all_sites: tempAccount.all_sites
          }
        ]
      };
      const { status, message } = await postDealerUserAssignAccount(
        _employee,
        body
      );
      if (status === 201) {
        const assigned = [...selected];
        assigned.push(account_org);
        setSelected(assigned);
        successAlert(message)
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setAccount(null);
      // setShowConfirmation(false);
    }
  };

  const removeAccess = async (account_org: string) => {
    try {
      const { status, message } = await deleteDealerUserAssignAccount(
        String(_employee),
        account_org
      );
      if (status === 201) {
        setSelected((s) => s.filter((a) => a !== account_org));
        successAlert(message)
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setAccount(null);
      // setShowConfirmation(false);
    }
  };

  const editUser = async ({
    new_status,
    dealer_role,
    dealer_u_allorg
  }: {
    new_status?: boolean;
    dealer_role?: string;
    dealer_u_allorg?: boolean;
  }) => {
    try {
      const { status, message } = await putDealerUser(
        String(user.dealer_user),
        String(user.first_name),
        String(user.last_name),
        String(user.email),
        dealer_role || String(user.dealer_role),
        new_status !== undefined ? new_status : user.dealer_u_active,
        dealer_u_allorg !== undefined ? dealer_u_allorg : user.dealer_u_allorg
      );
      if (status === 201) {
        setTimeout(() => {
          setUser({
            ...user,
            dealer_role: dealer_role || String(user.dealer_role),
            dealer_u_active:
              new_status !== undefined ? new_status : user.dealer_u_active,
            dealer_u_allorg:
              dealer_u_allorg !== undefined
                ? dealer_u_allorg
                : user.dealer_u_allorg
            // ...(dealer_u_allorg !== undefined && {
            //   dealer_u_organizations: dealer_u_allorg
            //     ? accountList.map((a: any) => {
            //         return {
            //           ...a,
            //           organization: a.organization,
            //           all_sites: true,
            //           sites: [
            //             ...siteList.find(
            //               (s) =>
            //                 s[0]?.organization.organization === a?.organization
            //             ).sites,
            //           ],
            //           site_title: t("general.all_sites"),
            //         };
            //       })
            //     : null,
            // }),
          });
        }, 500);
        successAlert(message)
        if (dealer_u_allorg === false) {
          clearList();
          // setList([]);
        }
      }
    } catch (error: any) {
      errorAlert(error.response?.data?.message || error.message);
    }
  };

  const handleActionClick = (action: string, row: any) => {
    if (user.dealer_u_allorg) return;
    switch (action) {
    case 'edit':
      const acc = list.find((a) => a.organization === row.organization);
      setAccount(acc);
      setShowEditSites(true);
      // selected.includes(acc.organization)
      //   ? setShowConfirmation(true)
      //   : setShowEditSites(true);
      break;
    }
  };

  const successEditSites = (selectedSites: any[]) => {
    const newAccount = {
      // selected: selected.includes(account.organization),
      organization: account.organization,
      all_sites: returnSiteList?.length === selectedSites?.length || selectedSites?.length === 0,
      sites: [...selectedSites],
      site_title:
        returnSiteList?.length === selectedSites?.length || selectedSites?.length === 0
          ? t('general.all_sites')
          : returnSiteList
            .filter((s: any) => selectedSites.includes(s.site))
            .map((s: any) => s.name)
            .join(',')
    };
    setList(
      list.map((l: any) => 
        l.organization === newAccount.organization
          ? { ...l, ...newAccount }
          : { ...l }
      )
    );
    
    setShowEditSites(false);
    
    selectedSites?.length === 0 
      ? removeAccess(newAccount?.organization)
      : assignAccounts(newAccount?.organization, newAccount.sites);
  };

  const setAllSites = () => {
    setList((list: any) =>
      list.map((l: any) => {
        return {
          ...l,
          all_sites: true,
          site_title: t('general.all_sites'),
          sites: siteList
            .find((s) => s[0]?.organization.organization === l?.organization)
            .map((s: any) => s.site)
        };
      })
    );
  };

  const handleAllAccess = () => {
    setSelected(
      !user.dealer_u_allorg === true ? list.map((a) => a.organization) : []
    );
    !user.dealer_u_allorg === true && setAllSites();
    editUser({ dealer_u_allorg: !user.dealer_u_allorg });
  };

  const handleCheckClick = (id: string) => {
    if (user.dealer_u_allorg) return;
    const acc = list.find((a) => a.organization === id);
    setAccount(acc);
    !acc.multi_site
      ? selected.includes(acc.organization)
        ? removeAccess(acc.organization)
        : assignAccounts(acc.organization)
      : setShowEditSites(true)
      // selected.includes(acc.organization)
      //   ? removeAccess(acc.organization)
      //   : setShowEditSites(true);
  };

  // const search = (event: any) => {
  //   setList(
  //     allList.filter(
  //       (ele) =>
  //         String(ele.name)
  //           .toLocaleLowerCase()
  //           .indexOf(event.currentTarget.value.toLocaleLowerCase()) > -1 ||
  //         String(ele.site_title)
  //           .toLocaleLowerCase()
  //           .indexOf(event.currentTarget.value.toLocaleLowerCase()) > -1
  //     )
  //   );
  // };

  return (
    // <Modal
    //   open={open}
    //   width="md"
    //   onClose={onClose}
    //   title={`${t("PERMISSION_OPTION")} - ${t("users.card.assign")} ${t(
    //     "organization.organizations"
    //   )}`}
    // >
    <>
      <Stack
        mt={2}
        mb={2}
        width="100%"
        direction="row"
        justifyContent="end"
        flexWrap="wrap"
        gap={1}
      >
        {/* <MaterialSearchBar
          placeholder={t("organization.search_organization")}
          onChange={(e) => search(e)}
        /> */}
        <Button onClick={() => handleAllAccess()}>
          {user?.dealer_u_allorg
            ? t('dealers.remove_access_all')
            : t('dealers.access_all_accounts')}
        </Button>
        <Button
          onClick={() =>
            navigate(`${routes.dealer_info_view}?add_employee=true`)
          }
        >
          {t('dealers.back_to_list')}
        </Button>
      </Stack>
      <MaterialTable
        removeToolBar
        hideMainCheck
        actions={!user?.dealer_u_allorg}
        selectable
        darkBg={user?.dealer_u_allorg}
        hover={!user?.dealer_u_allorg}
        rows={list}
        id={'organization'}
        selected={selected}
        headCells={accountsHeadCell}
        customActions={customActions}
        onActionClick={handleActionClick}
        onCheckClick={handleCheckClick}
        onRowClick={(row) => row.multi_site && handleActionClick('edit', row)}
      />
      {/* <Stack direction="row" justifyContent="end">
        <Button disabled={selected.length === 0} onClick={assignAccounts}>
          {`${t("users.card.assign")} ${
            selected.length === 0 ? "" : `(${selected.length})`
          } ${t("organization.organizations")}`}
        </Button>
      </Stack> */}
      {/* Edit sites modal */}
      <EditSites
        open={showEditSites}
        account={account}
        siteList={returnSiteList}
        onClose={() => setShowEditSites(false)}
        onSuccess={successEditSites}
      />

      {/* Set permissions */}
      {/* <ConfirmationModal
        customIcon={
          <img
            width={100}
            src={selected.includes(account?.organization) ? remove : grant}
          />
        }
        message={
          selected.includes(account?.organization)
            ? t("dealers.remove_access_msg", { name: account?.name })
            : t("dealers.set_access_msg", { name: account?.name })
        }
        cancelBtnTxt={`${t("general.btn.cancel")}`}
        submitBtnTxt={`${t("general.btn.confirm")}`}
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={
          selected.includes(account?.organization)
            ? removeAccess
            : assignAccounts
        }
      /> */}

      {/* Set all acess */}
      {/* <ConfirmationModal
        customIcon={
          <img width={100} src={user.dealer_u_allorg ? remove : grant} />
        }
        message={
          user?.dealer_u_allorg
            ? t("dealers.remove_all_access")
            : t("dealers.grant_all_access")
        }
        cancelBtnTxt={`${t("general.btn.cancel")}`}
        submitBtnTxt={`${t("general.btn.confirm")}`}
        open={showAllAccess}
        onClose={() => (false)}
        onConfirm={handleAllAccess}
      /> */}
    </>
    // </Modal>
  );
};

export default AssignAccount;
