import { useSelector } from './store';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
// routing
import AppRoutes from './routes/AppRoutes';
// defaultTheme
import { lightTheme, darkTheme } from './themes';
// project imports
import NavigationScroll from './layout/NavigationScroll';
// Api
import { postOauthToken } from './api/api';
import { useEffect, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import Snack from './components/Snack/Snack';
import useDarkmode from './utils/hooks/useDarkmode';

// const stripePromise = loadStripe("pk_test_2zeECGWMDvJcw7USm8CGS9mM00yZxRiVqO");

function App() {
  const stripePromise = useSelector((store: any) => store.stripePromise.stripePromise);
  const { darkmode } = useDarkmode();

  // const customization = useSelector((state: any) => state.customization);

  const customTheme = useMemo(() => darkmode ? darkTheme : lightTheme, [darkmode]);

  const getOauthToken = async () => {
    try {
      await postOauthToken();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOauthToken();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={customTheme}>
          <Elements stripe={stripePromise}>
            <CssBaseline />
            <NavigationScroll>
              <AppRoutes />
              {/* Alerts */}
              <Snack />
            </NavigationScroll>
          </Elements>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
