/*
 * Description: customHook for handling darkmode global state
 * Created by: Ricardo Muchacho
 * Created at: 08/28/2024
*/
import { useDispatch, useSelector } from '../../store';
import { toggleMode } from '../../store/actions/darkmode';
import { useCallback } from 'react';

const useDarkmode = () => {
  const dispatch = useDispatch();
  const darkmode = useSelector((store: any) => store.darkmode);

  const toggleDarkmode = useCallback(async () => {
    dispatch(toggleMode());
  }, [dispatch]);

  return { darkmode, toggleDarkmode };
}

export default useDarkmode;
