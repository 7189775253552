/*
 * Description: typography styling for darkMode theme
 * Remarkable changes: refactoring for cleaner code
*/

export default function darkTypography(theme: any) {
  return {
    fontFamily: theme?.customization?.fontFamily,
    h6: {
      color: '#FFFFFF',
      fontSize: '0.75rem',
      fontWeight: 500
    },
    h5: {
      fontSize: '0.875rem',
      color: '#FFFFFF',
      fontWeight: 500
    },
    h4: {
      fontSize: '1rem',
      color: '#FFFFFF',
      fontWeight: 600
    },
    h3: {
      fontSize: '1.25rem',
      color: '#FFFFFF',
      fontWeight: 600
    },
    h2: {
      fontSize: '1.5rem',
      color: '#FFFFFF',
      fontWeight: 700
    },
    h1: {
      fontSize: '2.125rem',
      color: '#FFFFFF',
      fontWeight: 700
    },
    subtitle1: {
      fontSize: '0.875rem',
      color: '#FFFFFF',
      fontWeight: 500
    },
    subtitle2: {
      fontSize: '0.75rem',
      color: '#FFFFFF',
      fontWeight: 400
    },
    caption: {
      fontSize: '0.75rem',
      color: '#FFFFFF',
      fontWeight: 400
    },
    body1: {
      fontSize: '0.875rem',
      color: '#FFFFFF',
      fontWeight: 400,
      lineHeight: '1.334em'
    },
    body2: {
      letterSpacing: '0em',
      color: '#FFFFFF',      
      fontWeight: 400,
      lineHeight: '1.5em'
    },
    button: {
      textTransform: 'capitalize'
    }
  };
}
