const testingTool = {
  header: 'Outil de test de pilote',
  serial_number: 'Numéro de série',
  site_id: 'Identification des sites',
  site_name: 'Nom du site',
  validation_code: 'Code de validation',
  assign_to_site: 'Attribuer au site',
  xml_command: 'Commande XML',
  send_command: 'Envoyer la commande',
  remove_site: 'Supprimer le site',
  panel_alive: 'panneau en direct',
  last_signal: 'Dernier signe',
  received: 'Il a reçu',
  sent: 'Envoyer',
  msg_in_memory: 'Messages en mémoire',
  log: 'Inscription',
  copy_clip_message: 'Le texte a été copié avec succès dans le presse-papiers',
  details: 'Détails',
  copy_clip: 'Copier dans le Presse-papiers',
  reset_msg: 'Appuyez sur le bouton Confirmer si vous souhaitez réinitialiser le contrôleur en usine. \n Appuyez sur Annuler si vous souhaitez uniquement désaffecter le contrôleur du site.',
  btn_factory_reset: 'Réinitialiser et supprimer',
  btn_unassign: 'Seulement supprimer'
};

export default testingTool;
