import { Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';

// Project Imports
import Dashboard from '../Dashboard/Dashboard';
import user from '../../interfaces/user.interface';
import { useSelector } from '../../store';
import DashboardDealer from '../DashboardDealer/DashboardDealer';
import { getStripeConfig } from '../../api/api';

const Home = () => {
  const user: user = useSelector((state) => state.user.user);
  const [stripeId, setStripeId] = useState<any>('');

  useEffect(() => {
    getStripe()
  }, []);

  // useEffect(() => {
  //   let stripePromise = loadStripe(stripeId);
  //   dispatch(setStripePromise(stripePromise));
  // }, [stripeId]);

  const getStripe = async () => {
    try {
      const { key } = await getStripeConfig();
      setStripeId(key)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* <PageHeader header={t("HOME")} /> */}
      <Stack paddingX={4} paddingY={1} direction="row" justifyContent="center">
        {user.profile_type === 'dealer' ? <DashboardDealer /> : <Dashboard />}
      </Stack>
    </>
  );
};

export default Home;
