import { ReactNode, useState } from 'react';
// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

// Icons
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  open: boolean;
  onClose: any;
  title?: any;
  scroll?: DialogProps['scroll'];
  children: ReactNode;
  actions?: ReactNode;
  disableBackdropClose?: boolean;
  disableEscapeKeyClose?: boolean;
  closeButton?: boolean;
  disableCloseButton?: boolean;
  width?: DialogProps['maxWidth'] | string;
  modalWidth?: string;
  backButton?: boolean;
  showLeaveConfirmation?: boolean;
  onBackButtonClick?: () => void;
  overflowX?: string;
  height?: string;
  blur? : boolean;
}

const Modal = ({
  open,
  onClose,
  title,
  scroll = 'paper',
  children,
  actions,
  disableBackdropClose = false,
  disableEscapeKeyClose = false,
  closeButton = true,
  disableCloseButton = false,
  width = 'xs',
  backButton = false,
  modalWidth,
  height,
  showLeaveConfirmation = false,
  onBackButtonClick,
  overflowX = 'visible',
  blur = false
}: ModalProps) => {

  const {t} = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // Modal close handler
  const handleClose = (event: object, reason: string) => {
    if (reason === 'backdropClick' && !disableBackdropClose) {
      if (showLeaveConfirmation) {
        setConfirmationOpen(true);
      } else {
        onClose();
      }
    }
    if (reason === 'escapeKeyDown' && !disableEscapeKeyClose) {
      if (showLeaveConfirmation) {
        setConfirmationOpen(true);
      } else {
        onClose();
      }
    }
  };

  return (
    <>
      {showLeaveConfirmation && (
        <ConfirmationModal
          open={confirmationOpen}
          onClose={() => setConfirmationOpen(false)}
          onConfirm={() => {
            setConfirmationOpen(false);
            onClose();
          }}
          cancelBtnTxt={t('general.btn.cancel')}
          submitBtnTxt={t('general.btn.confirm')}
          icon
          message={t('general.leave_message')}
        />
      )}
      <Dialog
        PaperProps={{
          style: { borderRadius: '0.75em', width: modalWidth, height: height ?? 'auto' }
        }}
        maxWidth={modalWidth ? modalWidth as DialogProps['maxWidth'] : width as DialogProps['maxWidth']}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        sx={{
          backdropFilter: blur ? 'blur(5px)' : ''
        }}
      >
        {/* Modal header */}
        <DialogTitle
          sx={{ padding: '1em 2em', borderBottom: '1px solid #e3e3e3', mb: 3 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              {backButton && (
                <IconButton
                  disableTouchRipple
                  disabled={disableCloseButton}
                  onClick={onBackButtonClick}
                >
                  <ChevronLeftRoundedIcon
                    fontSize="medium"
                    sx={{
                      marginLeft: 'auto',
                      color: disableCloseButton ? 'lightgray' : null
                    }}
                  />
                </IconButton>
              )}
              <Typography
                variant="h3"
                fontWeight={400}
                sx={{ marginLeft: '0.5em' }}
              >
                {title ? title : ' '}
              </Typography>
            </Box>
            {closeButton && (
              <IconButton
                disableTouchRipple
                disabled={disableCloseButton}
                onClick={onClose}
                id="modal-close-button"
              >
                <CloseRoundedIcon
                  fontSize="medium"
                  sx={{
                    marginLeft: 'auto',
                    color: disableCloseButton ? 'lightgray' : null
                  }}
                />
              </IconButton>
            )}
          </Box>
        </DialogTitle>
        {/* Content */}
        <DialogContent sx={{ paddingLeft: 4, paddingRight: 4, overflowX: overflowX }}>
          {children}
        </DialogContent>
        {/* Actions */}
        {actions && (
          <DialogActions
            sx={{
              paddingLeft: 4,
              paddingRight: 4,
              paddingBottom: 4
            }}
          >
            {actions}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default Modal;
