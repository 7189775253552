/**
 * Formats the given string to be id compatible by replacing incompatible characters for '-'
 *
 * @param {string} str - The input string to be formatted.
 * @returns {string} - The formatted string.
 */

export const idFormat = (str: string) => {
  return `${str?.replace(/[._ ]/g, '-').toLowerCase()}` || '';
}
