import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface props {
  children: ReactNode;
  gap?: string;
}

const FormRow = ({ children, gap }: props) => {
  return (
    <Box 
      sx={{
        gap: gap || '0.5em',
        display: 'flex',
        flex: 1,
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%'
      }}
    >
      {children}
    </Box>
  );
};

export default FormRow;
