import { StripePromiseActionTypes, SET_STRIPE_PROMISE } from '../actions/stripe-promise';

interface StripePromiseState {
  stripePromise: any | null; // Define the type of stripePromise in state
}

const initialState: StripePromiseState = {
  stripePromise: null
};

const stripePromiseReducer = (
  state = initialState,
  action: StripePromiseActionTypes
): StripePromiseState => {
  switch (action.type) {
  case SET_STRIPE_PROMISE:
    return {
      ...state,
      stripePromise: action.payload
    };
  default:
    return state;
  }
};

export default stripePromiseReducer;
