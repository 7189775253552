import HeadCell from '../../../../components/MaterialTable/interfaces/HeadCell.interface';

const accountsHeadCell: HeadCell<any>[] = [
  {
    id: 'name',
    type: 'any',
    label: 'organization.organization'
  },
  {
    id: 'site_title',
    type: 'any',
    label: 'sites.sites'
  }
];

export default accountsHeadCell;
