import { AnyAction } from '@reduxjs/toolkit';
import { TOGGLE_DARKMODE } from '../actions/darkmode';

// Initial state
const initialState = false

// Reducer
const darkmodeReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
  case TOGGLE_DARKMODE:
    return !state;
  default:
    return state;
  }
};

export default darkmodeReducer;
