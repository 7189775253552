const forgot_password = {
  log_into_account: 'Connectez-vous à votre compte',
  log_msg: 'Entrez votre nom d\'utilisateur et votre mot de passe',
  username: 'Nom d\'utilisateur',
  password: 'Mot de passe',
  current_password: 'Mot de passe actuel',
  new_password: 'Nouveau mot de passe',
  confirm_password: 'Confirmez le mot de passe',
  remember_me: 'Souviens-toi de moi',
  login: 'Connexion',
  sign_in: 'S\'identifier',
  click_msg: 'En cliquant sur Se connecter, vous acceptez nos',
  terms: 'Termes et conditions',
  and: 'et',
  privacy: 'Politique de confidentialité',
  account_msg: 'Vous n\'avez pas de compte?',
  register_here: 'Inscrivez-vous ici',
  forgot_password: 'Mot de passe oublié?',
  enter_email: 'Entrez votre e-mail pour réinitialiser votre mot de passe',
  email: 'E-mail',
  cancel: 'Annuler',
  submit: 'Nous faire parvenir',
  to_continue:
    'Pour continuer, terminez cette étape de vérification. Nous avons envoyé un code à',
  enter_it: 'Entrez-le ci-dessous',
  hello: 'Bonjour',
  finish_msg: 'pour finir de définir un nouveau mot de passe',
  error: {
    required: 'Champs requis',
    email_format: 'Format d\'email invalide',
    password_match: 'Les mots de passe ne correspondent pas'
  }
};

export default forgot_password;
