const validateEmail = (email: string): boolean => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};

export const validateMultipleEmail = (emails: string[]): boolean => {
  let valid = true;
  emails.forEach((email: any) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
      valid = false;
    }
  });
  return valid;
};

export default validateEmail;
