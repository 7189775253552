// Interfaces
import Alert from '../../interfaces/alert.interface';

// Actions
export const SET_ALERT = 'SET_ALERT';
export const RESET_ALERT = 'RESET_ALERT';

// Action creators
export const setSnackAlert = (alert: Alert) => ({
  type: SET_ALERT,
  alert: alert
});

export const closeSnackAlert = () => ({
  type: RESET_ALERT
});