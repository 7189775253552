import { Alarm } from './interfaces';
import Modal from '../Modal/Modal';
import { Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { statusList } from './alertsTableHeaders';
import { useEffect, useMemo, useState } from 'react';
import { red, yellow } from '@mui/material/colors';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import {
  postRestoreControllerTamperedAlarm,
  postRestoreReaderAlarm
} from '../../api/api';
import { titleCase } from '../../utils/capitalize';

const colors: any = {
  red: red,
  yellow: yellow
};

interface DescriptionModalProps {
  open: boolean;
  onClose: () => void;
  alarm: Alarm;
}

interface StatusProps {
  statusText: string;
}

const DescriptionModal = ({ open, onClose, alarm }: DescriptionModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(false);
  }, [alarm]);

  const handleRestore = async () => {
    if (alarm.object_type === 'Reader') {
      await handleRestoreReader(alarm.entity_associated_uuid);
    } else {
      await handleRestoreController(alarm.entity_associated_uuid);
    }
  };

  const handleRestoreReader = async (reader: string): Promise<void> => {
    try {
      setLoading(true);
      await postRestoreReaderAlarm(reader);
    } catch (error: any) {
      console.log('🚀 ~ handleRestoreReader ~ error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRestoreController = async (controller: string): Promise<void> => {
    try {
      setLoading(true);
      await postRestoreControllerTamperedAlarm(controller);
    } catch (error: any) {
      console.log('🚀 ~ handleRestoreController ~ error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`${t('general.alerts')} - ${titleCase(
        t('doors.table.description')
      )}`}
      modalWidth="600px"
    >
      {alarm && (
        <Stack gap={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
          >
            <Stack gap={1}>
              {/* Fields */}
              <Typography fontSize={16}>
                {`${titleCase(t('organization.table.organization'))}: ${
                  alarm.organization_name
                }`}
              </Typography>
              <Typography fontSize={16}>{`${titleCase(
                t('organization.table.site')
              )}: ${alarm.site_name}`}</Typography>
              <Typography fontSize={16}>
                {`${titleCase(t('firmware.table.controller'))}: ${
                  alarm.controller_serial_number
                }`}
              </Typography>
              <Typography fontSize={16}>
                {`${titleCase(t('reports.events.table.object_type'))}: ${
                  alarm.object_type
                }`}
              </Typography>
              <Typography fontSize={16}>
                {`${titleCase(t('roles.table.name'))}: ${
                  alarm.entity_associated_name
                }`}
              </Typography>
            </Stack>

            {/* Status, date and restore btn */}
            <Stack alignItems="end" gap={0.5}>
              <Status statusText={alarm.status_text} />
              <Typography>
                {/* {moment(alarm.alarmed_at.replace("Z", "")).format(
                  "MM/DD/YYYY hh:mm:ss a"
                )} */}
                {alarm.alarmed_at}
              </Typography>
              {alarm.status_text === 'Tamper Alarm' && (
                <Button
                  onClick={() => {
                    setDisabled(true);
                    handleRestore();
                  }}
                  loading={loading}
                  disabled={disabled}
                  sx={{ mt: 1 }}
                >
                  {t('general.btn.restore')}
                </Button>
              )}
            </Stack>
          </Stack>

          {/* Description */}
          <Stack>
            <Typography fontSize={20}>{t('tasks.modal.details')}</Typography>
            <Typography fontSize={16}>{alarm.description}</Typography>
          </Stack>

          <Stack alignItems="center" mt={2}>
            <Button onClick={onClose}>{t('general.btn.done')}</Button>
          </Stack>
        </Stack>
      )}
    </Modal>
  );
};

const Status = ({ statusText }: StatusProps) => {
  const status = useMemo(() => {
    return (
      statusList.find((status) => status.value === statusText) || statusList[0]
    );
  }, [statusText]);

  return (
    <Stack direction="row" alignItems="center" gap="4px">
      <CircleIcon
        htmlColor={colors[status.color][status.colorIntensity || 500]}
        sx={{ fontSize: 24 }}
      />
      <Typography fontSize={20}>{status.value}</Typography>
    </Stack>
  );
};

export default DescriptionModal;
