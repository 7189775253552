import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// MUI
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Imports
import { Plans } from '../../interfaces/plans.interface';
import Button from '../../../../components/Button/Button';
import FormField from '../../../../components/FormField/FormField';
import proPlan from './proPlan';
import { numberWithCommas } from '../../../../utils/numberWithCommas';

interface props {
  plans: Plans[];
  selectedPlan: Plans;
  setSelectedPlan: (p: Plans | undefined) => void;
  type_payment: 'month' | 'year';
  setType_payment: (a: 'month' | 'year') => void;
  next: () => void;
  previous: () => void;
}

const PlansList = ({
  plans,
  selectedPlan,
  setSelectedPlan,
  type_payment,
  setType_payment,
  next,
  previous
}: props) => {
  const { t } = useTranslation();
  const [doors, setDoors] = useState(1);

  const handleSetSelectedPlan = (plan: Plans | undefined) => {
    setSelectedPlan && setSelectedPlan(plan);
  };

  const returnPlan = (plan: any, isPro = false) => {
    return (
      <Box
        key={`${plan.cdvi_plan}_btn`}
        sx={{ borderRadius: 5, border: '1px solid lightgray' }}
        padding={2}
        width={{ xs: '100%', md: '380px' }}
      >
        <Typography component="h3" variant="h3" mb={2}>
          {plan.name}
        </Typography>
        <Box sx={{ filter: isPro ? 'blur(4px)' : '' }} height={100}>
          <Typography component="div">{plan.description}</Typography>
        </Box>
        <Typography
          component="h4"
          variant="h4"
          gutterBottom
          sx={{ filter: isPro ? 'blur(4px)' : '' }}
        >
          $
          {`${numberWithCommas(
            Number(
              (type_payment === 'year' ? +plan.year_price : +plan.month_price) *
                (doors || 1)
            ).toFixed(2)
          )}`}
        </Typography>
        <Typography
          mb={2}
          component="div"
          sx={{ filter: isPro ? 'blur(4px)' : '' }}
        >
          {`/${
            type_payment === 'year'
              ? t('organization.yearly').toLocaleLowerCase()
              : t('organization.monthly').toLocaleLowerCase()
          } ${t('dealers.for_doors').toLocaleLowerCase()}`}
        </Typography>
        {/* Btn */}
        <Box sx={{ position: 'relative' }}>
          {isPro && (
            <CheckCircleIcon
              color="success"
              sx={{ position: 'absolute', top: 6, left: 5, zIndex: 100 }}
            />
          )}
          <Button
            width="100%"
            disabled={isPro}
            color={
              selectedPlan?.cdvi_plan === plan.cdvi_plan ? 'primary' : 'gray'
            }
            onClick={() =>
              handleSetSelectedPlan(
                selectedPlan?.cdvi_plan === plan.cdvi_plan ? undefined : plan
              )
            }
          >
            {isPro
              ? t('general.coming_soon')
              : selectedPlan?.cdvi_plan === plan.cdvi_plan
                ? t('general.btn.selected')
                : t('general.btn.select')}
          </Button>
        </Box>

        <Typography
          mt={2}
          mb={2}
          component="h4"
          variant="h4"
          sx={{ filter: isPro ? 'blur(4px)' : '' }}
        >
          {t('general.features')}:
        </Typography>

        {plan.feature.map(
          (ft: any) =>
            ft.included && (
              <Stack
                gap={2}
                direction="row"
                sx={{ filter: isPro ? 'blur(4px)' : '' }}
              >
                <CheckIcon color="success" />
                <Typography component="span">{ft.description}</Typography>
              </Stack>
            )
        )}
      </Box>
    );
  };

  const featureRow = () => {
    return (
      <>
        {/* Selectors */}
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="start"
          alignItems="center"
          gap={1}
          mb={4}
        >
          <Typography mt={1} component="span">
            {t('dealers.calculate_for_account')}:
          </Typography>
          <Box>
            <FormField
              marginBottom={0.1}
              value={doors}
              label={`${t('dealers.how_many_doors')}`}
              onChange={(e: any) => {
                const input = e.currentTarget.value;
                if (
                  (/^[0-9]+$/.test(input) && +input <= 200 && +input >= 1) ||
                  input === ''
                ) {
                  setDoors(e.target.value);
                }
              }}
            />
          </Box>
          <Stack gap={1} direction="row" alignSelf="end">
            <Box>
              <Typography
                fontWeight="bold"
                mb={0.4}
                sx={{
                  marginLeft: '0.25em',
                  fontSize: '0.75em'
                }}
              >
                {t('dealers.billing_cycle')}
              </Typography>
              <Button
                onClick={() => setType_payment('month')}
                color={type_payment === 'month' ? 'primary' : 'gray'}
              >
                {t('organization.monthly')}
              </Button>
            </Box>
            {/* <Button
              onClick={() => setType_payment("year")}
              color={type_payment === "year" ? "primary" : "gray"}
            >
              {t("organization.yearly")}
            </Button> */}
          </Stack>
        </Stack>

        <Stack direction="row" flexWrap="wrap" gap={2} justifyContent="center">
          {plans.map((plan) => (
            <span key={`${plan.cdvi_plan}_btn`}>{returnPlan(plan)}</span>
          ))}
          {returnPlan(proPlan, true)}
        </Stack>

        <Stack
          mt={4}
          gap={1}
          flexWrap="wrap"
          direction="row"
          justifyContent="end"
        >
          <Button color="gray" onClick={previous}>
            {t('general.btn.previous')}
          </Button>
          <Button onClick={next} disabled={!selectedPlan}>
            {t('general.btn.next')}
          </Button>
        </Stack>
      </>
    );
  };

  return <>{featureRow()}</>;
};

export default PlansList;
