/*
 * Description: defines custom style overrides for Material-UI components
 * Remarkable changes: reduced the overrides for cleaner code
*/

export default function componentStyleOverrides(theme: any) {  
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: '4px',
          background: theme.palette.button.background,
          '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'all !important'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: theme.palette.button.icon
          }       
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: `${theme.palette.text.primary} !important`     
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: theme.palette.button.radio
          }       
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: theme.palette.button.radio
          }       
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        },
        rounded: {
          borderRadius: `${theme.customization?.borderRadius}px`
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: '1.125rem'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem'
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          background: theme.palette.background.paper
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryMain,
          background: theme.colors?.primary200
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'underline',
          color: '#2196f3'
        }
      }
    }
  };
}
