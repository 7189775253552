const events = {
  general: {
    search: 'Rechercher un événement',
    refresh: 'Rafraîchir',
    add: 'Ajouter',
    assign: 'Assigner',
    details: 'Détails de l\'événement',
    fetchError: 'Impossible de récupérer les données'
  },
  selectors: {
    selector1: {
      any: 'N\'importe quel',
      today: 'Aujourd\'hui',
      yesterday: 'Hier',
      last7Days: '7 derniers jours',
      custom: 'Personnalisé',
      month: 'Ce mois-ci',
      last60: 'Les 60 derniers jours',
      last180: 'Les 180 derniers jours'
    },
    selector2: {
      all: 'Tous',
      access: 'Accès',
      security: 'Sécurité',
      alarms: 'Alarmes',
      troubles: 'Problèmes',
      lockdown: 'Verrouillage',
      others: 'Autres',
      cloud: 'Cloud'
    },
    selector3: {
      all: 'Tous',
      organization: 'Compte',
      site: 'Site',
      controller: 'Contrôleur',
      door: 'Porte',
      accessLevel: 'Niveau d\'accès',
      card: 'Carte',
      user: 'Utilisateur',
      schedule: 'Horaire',
      holiday: 'Vacances',
      reader: 'Lecteur'
    }
  },
  table: {
    date: 'Date',
    description: 'Description',
    object: 'Objet',
    detail: 'Détail',
    info: 'Info',
    extraInfo: 'Informations supplémentaires',
    instigator: 'Instigateur',
    event: 'Événement',
    type: 'Type',
    id: 'ID',
    displayName: 'Nom d\'affichage',
    module: 'Module',
    sequenceNumber: 'Numéro de séquence'
  },
  cards: {
    mobilepass: 'Mobile Pass n\'est pas encore pris en charge'
  }
};

export default events;
