// MUI
import {
  Paper,
  Stack,
  Typography
} from '@mui/material';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/media/logos/CDVI_CLOUD4.png';

const DealerRequestReceived = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 4
        }}
      >
        <Stack direction="row" justifyContent="center">
          <img
            src={logo}
            width={200}
            alt="CDVI Logo"
            style={{ marginLeft: -85 }}
          />
        </Stack>
        <Typography
          variant="h3"
          align="center"
          fontWeight={'normal'}
          paddingBottom={5}
        >
          Thank you {props.company}!
        </Typography>
        <Typography
          variant="h3"
          align="center"
          fontWeight={'normal'}
          paddingBottom={5}
        >
          We received your application to become a dealer.
        </Typography>
        <Typography
          variant="h3"
          align="center"
          fontWeight={'normal'}
          paddingBottom={5}
        >
          We will contact you by email soon!
        </Typography>

        <Button color="primary" onClick={() => navigate('/login')}>
          Back Home
        </Button>
      </Paper>
    </>
  );
};

export default DealerRequestReceived;
