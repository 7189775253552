import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Fade, Paper, Stack, Typography } from '@mui/material';
import { blue, green } from '@mui/material/colors';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { postDealerPreRegister } from '../../api/api';
import logo from '../../assets/images/media/logos/CDVI_CLOUD4.png';
import Button from '../../components/Button/Button';
import FormField from '../../components/FormField/FormField';
import FormLabel from '../../components/FormLabel/FormLabel';
import FormRow from '../../components/Util/FormRow/FormRow';
import validateEmail from '../../utils/email-validation';
import { useNavigate } from 'react-router-dom';
import useAlert from '../../utils/hooks/useAlert'; 

type Props = {};

const PreRegister = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { errorAlert } = useAlert();

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [captchaValidated, setCaptchaValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (email: string) => {
    setEmail(email);
    setValidEmail(validateEmail(email));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { status } = await postDealerPreRegister({ email });
      if (status === 200) setFormSubmitted(true);
    } catch (error: any) {
      errorAlert(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ padding: 4, width: { sm: '600px' } }}>
      <Stack direction="row" justifyContent="center">
        <img
          src={logo}
          width={200}
          alt="CDVI Logo"
          style={{ marginLeft: -80 }}
        />
      </Stack>

      <Typography
        component="h3"
        variant="h3"
        fontWeight={1000}
        fontSize={32}
        color={blue[900]}
        textAlign="center"
        mb={4}
      >
        {t('dealers.dealer_registration')}
      </Typography>

      {formSubmitted ? (
        <Fade in={formSubmitted}>
          <Box>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 50, color: green[400], width: '100%' }}
            />
            <Typography textAlign="center" mb={4} mt={2} component="p">
              {t('dealers.preregistration_success')}
            </Typography>
            <Button
              sx={{ margin: '0 auto', display: 'block' }}
              width="200px"
              color="darkBlue"
              onClick={() => navigate('/login')}
            >
              {t('dealers.done')}
            </Button>
          </Box>
        </Fade>
      ) : (
        <>
          <FormRow>
            <FormLabel required sx={{ flex: { sm: '0.2' } }}>
              {t('dealers.form.email')}
            </FormLabel>
            <FormField
              sx={{backgroundColor: '#F2F2F2'}}
              maxLength={100}
              error={!validEmail}
              errorText="Invalid email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEmailChange(e.target.value)
              }
            />
          </FormRow>

          <FormRow>
            <FormLabel sx={{ flex: { sm: '0.65' } }} />
            <Box
              maxWidth={{ sm: '100%' }}
              sx={{ overflow: 'hidden' }}
              mb={2}
              mt={1}
            >
              <ReCAPTCHA
                hl={i18n.language}
                sitekey="6LcjMZ0iAAAAALh7DhdWgFtamCP530K9YZK7eX7F"
                onChange={(e: any) => {
                  setCaptchaValidated(Boolean(e));
                }}
              />
            </Box>
          </FormRow>

          <FormRow>
            <FormLabel sx={{ flex: { sm: '0.2' } }} />
            <Box flex={1}>
              <Button
                bigBtn
                width="100%"
                color="darkBlue"
                disabled={!email || !validEmail || !captchaValidated}
                onClick={() => handleSubmit()}
                loading={loading}
              >
                {t('dealers.preregistration.submit')}
              </Button>
            </Box>
          </FormRow>
        </>
      )}
    </Paper>
  );
};

export default PreRegister;
